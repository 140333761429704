import React, { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";
import axios from "../../util/helpers/axios";

const Redirects = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const dataFetchedRef = useRef(false);

  useEffect(() => {
    const verifyUser = async () => {
      try {
        const response = await axios.get(`/SignUpVerify/${token}`);
        console.log(response);
        if (response.statusText === "OK") {
          return navigate("/login");
        }
      } catch (err) {
        console.log(err);
      }
    };

    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    verifyUser();
  }, [token, navigate]);

  return (
    <div className="h-screen max-w-screen flex items-center justify-center">
      <p className="text-lg lg:text-4xl text-secondary-blue">
        Taking you there...
      </p>
      <span className="mt-1 lg:mt-2 ml-2 lg:ml-3">
        <Spinner
          fill="text-primary-blue"
          className="animate-spin h-4 w-4 lg:h-7 lg:w-7"
        />
      </span>
    </div>
  );
};

export default Redirects;

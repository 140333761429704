import React, { useCallback, useEffect, useState } from "react";
import Checkbox from "../../../components/Checkbox/Checkbox";
import PencilSquare from "../../../assets/SVG/pencil-square";
import RulesModal from "../../../components/RulesModal/RulesModal";
import Dropdown from "../../../components/Dropdown/Dropdown";
import { useNavigate } from "react-router-dom";
import axios from "../../../util/helpers/axios";
//import { useAuth } from "../../../CustomHook/auth-hook";
import Button from "../../../components/Button/Button";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import ImageSlideModal from "../../../components/ImageSlideModal/ImageSlideModal";
import cogoToast from "cogo-toast";

const CommunityRules = ({ token, user }) => {
  const navigate = useNavigate();
  //const { token } = useAuth();

  const [isEditModalOpen, setIsEditModalOpen] = useState({
    isOpen: false,
    value: "",
    ruleId: "",
  });
  const [openImageModal, setOpenImageModalOpen] = useState(false);
  const [isCustomModalOpen, setIsCustomModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState({ type: "page", loading: true });
  //const [notApplicableCheckbox, setNotApplicableCheckbox] = useState(false);
  const [subCategories, setSubCategories] = useState([]);
  const [issue, setIssue] = useState({
    issueId: subCategories[0]?.id,
    isSaved: subCategories[0]?.is_completed === 1 ? true : false,
    title: "",
  });
  const [image, setImage] = useState({
    imageId: "",
    imageUrl: "",
    imageDescription: "",
  });
  const [categoryRules, setCategoryRules] = useState([]);

  console.log(categoryRules, "categoryRules");
  console.log(subCategories, "subCategories");
  console.log(issue, "issue");

  // const CURRENT_PLAN = JSON.parse(localStorage.getItem("userData"))?.user?.plan;
  // const token = JSON.parse(localStorage.getItem("userData"))?.token;

  const getSubCategoryRulesAPI = useCallback(async (issueId, token) => {
    //setIsLoading({ type: "page", loading: true });
    if (!issueId || !token) {
      return;
    }

    try {
      const response = await axios.get(
        `rules/?issue_id=${issueId}&hoarTemplatetoken=${token}`
      );
      console.log(response, "Sub Category Rules Response");
      if (response?.statusText === "OK") {
        setCategoryRules([...response?.data?.ruleList]);
        setImage({
          imageId: response?.data?.image?.id,
          imageUrl: response?.data?.image?.image,
          imageDescription: response?.data?.image?.title,
        });
        setIsLoading({ type: "", loading: false });
      }
    } catch (err) {
      console.log(err);
      setIsLoading({ type: "", loading: false });
    }
  }, []);

  const getSubCategoriesAPI = useCallback(
    async (issueId = null, title = null, callback = true) => {
      //setIsLoading({ type: "page", loading: true });
      if (!token) {
        return;
      }

      try {
        const response = await axios.get(
          `issues_list/?category=RR&hoarTemplatetoken=${token}&plan=${user?.plan}`
        );
        console.log(response, "Response Sub Category");
        if (response?.statusText === "OK") {
          console.log(response.data.issueList, "issue list response api");

          setSubCategories([...response?.data?.issueList]);
          if (callback) {
            getSubCategoryRulesAPI(response?.data?.issueList[0]?.id, token);
            setIssue({
              issueId: issueId ? issueId : response?.data?.issueList[0]?.id,
              isSaved:
                response?.data?.issueList[0]?.is_completed === 1 ? true : false,
              title: title ? title : response?.data?.issueList[0]?.title,
            });
          }
        }
        setIsLoading({ type: "", loading: false });
      } catch (err) {
        console.log(err);
        setIsLoading({ type: "", loading: false });
      }
    },
    [token, user?.plan, getSubCategoryRulesAPI]
  );

  useEffect(() => {
    getSubCategoriesAPI();
  }, [getSubCategoriesAPI]);

  const editRuleAPI = async (ruleId, token, updatedData) => {
    if (!token) {
      return;
    }

    setIsLoading({ type: "edit", loading: true });
    try {
      const response = await axios.post("editRuleByUser/", {
        ruleId: `rule-${ruleId}`,
        hoarTemplatetoken: token,
        updatedData: updatedData,
      });
      console.log(response, "edit rule response");
      if (response?.status === 201) {
        console.log(response, "edit rule response");
        setIsLoading({ type: "", loading: false });
        setIsEditModalOpen({ isOpen: false, value: "", ruleId: "" });
        getSubCategoryRulesAPI(issue.issueId, token);
      }
    } catch (err) {
      console.log(err);
      setIsLoading({ type: "", loading: false });
    }
  };

  const saveRuleAPI = async (issueId, title, token, type) => {
    if (!token) {
      return;
    }

    if (type !== "AUTO_SAVE") {
      setIsLoading({ type: "save", loading: true });
    }
    try {
      const response = await axios.post("user_completed/", {
        issue_id: issueId,
        hoarTemplatetoken: token,
      });
      if (response.status === 201) {
        console.log(response, "Save Rule Response");
        if (type !== "AUTO_SAVE") {
          setIssue({ title: title, issueId: issueId, isSaved: !issue.isSaved });
          getSubCategoriesAPI(issueId, title, false);
        }
        setIsLoading({ type: "", loading: false });
        //window.location.reload();
      }
    } catch (err) {
      console.log(err);
      setIsLoading({ type: "", loading: false });
    }
  };

  const selectRuleAPI = async (
    ruleId,
    token,
    notApplicableCheckbox = false
  ) => {
    //setIsLoading({ type: "page", loading: true });
    try {
      const response = await axios.post("user_selected/", {
        ruleId: `rule-${ruleId}`,
        hoarTemplatetoken: token,
      });
      if (response.status === 201) {
        console.log(response, "rule select response");
        setIsLoading({ type: "", loading: false });
        getSubCategoryRulesAPI(issue.issueId, token);
      }
    } catch (err) {
      console.log(err);
      setIsLoading({ type: "", loading: false });
    }
  };

  // const handleNotApplicableCheckbox = () => {
  //   setNotApplicableCheckbox((prev) => !prev);
  //   const allCategoryRules = [...categoryRules];
  //   console.log(allCategoryRules, "allCategoryRules");

  //   if (notApplicableCheckbox) {
  //     console.log("API--not---called");
  //   } else {
  //     for (let i = 0; i < allCategoryRules.length; i++) {
  //       if (allCategoryRules[i].is_selected) {
  //         console.log(allCategoryRules[i]);
  //         selectRuleAPI(allCategoryRules[i].id, token, true);
  //       }
  //     }
  //   }
  // };

  const addCustomRuleAPI = async (issueId, token, addedRule) => {
    if (!token) {
      return;
    }

    setIsLoading({ type: "custom-rule", loading: true });
    const formData = new FormData();
    formData.append("issue_id", issueId);
    formData.append("hoarTemplatetoken", token);
    formData.append("rule", addedRule);
    formData.append("state", "Georgia");
    try {
      const response = await axios.post("rules/", formData);

      if (response) {
        console.log(response, "add custom rule api response");
        setIsLoading({ type: "", loading: false });
        setIsCustomModalOpen(false);
        getSubCategoryRulesAPI(issueId, token);
      }
    } catch (err) {
      console.log(err);
      setIsLoading({ type: "", loading: false });
      setIsCustomModalOpen(false);
    }
  };

  const submittalRequiredAPI = async (ruleId, submittalRequired, token) => {
    if (!token) {
      return;
    }
    //setIsLoading({ type: "page", loading: true });
    try {
      const response = await axios.post("user_submittal_required/", {
        ruleId: `rule-${ruleId}`,
        submittal_required: submittalRequired,
        hoarTemplatetoken: token,
      });
      if (response.status === 201) {
        setIsLoading({ type: "", loading: false });
        getSubCategoryRulesAPI(issue.issueId, token);
      }

      console.log(response);
    } catch (err) {
      console.log(err.response);
    }
  };

  return (
    <div className="max-w-screen p-10 bg-white">
      {isLoading.loading && isLoading.type === "page" && <LoadingSpinner />}

      {isEditModalOpen.isOpen && (
        <RulesModal
          defaultRule={isEditModalOpen.value}
          ruleId={isEditModalOpen.ruleId}
          handleCloseEditModal={() =>
            setIsEditModalOpen({ isOpen: false, value: "", ruleId: "" })
          }
          onUpdateCallback={editRuleAPI}
          isLoading={isLoading.type === "edit" ? isLoading.loading : false}
          title="Edit Rule"
          buttonText="Update"
        />
      )}
      {isCustomModalOpen && (
        <RulesModal
          ruleId={issue?.issueId}
          handleCloseEditModal={() => setIsCustomModalOpen(false)}
          onUpdateCallback={addCustomRuleAPI}
          isLoading={
            isLoading.type === "custom-rule" ? isLoading.loading : false
          }
          title="Add Custom Rule"
          buttonText="Save"
        />
      )}

      {openImageModal && (
        <ImageSlideModal
          handleCloseImageModal={() => setOpenImageModalOpen(false)}
          issueId={issue?.issueId}
        />
      )}
      {(!isLoading.loading || isLoading.type !== "page") && (
        <div>
          <div className="flex items-center justify-between mb-10">
            {subCategories.length !== 0 && (
              <h1 className="text-lg sm:text-3xl lg:text-4xl text-secondary-blue font-medium">
                Community Rules & Regulations
              </h1>
            )}
            {subCategories.length !== 0 && (
              <div className="flex items-center space-x-5">
                <button
                  onClick={() => {
                    if (issue?.isSaved) {
                      cogoToast.error(
                        "Open the rule in order to add custom rule",
                        { hideAfter: 10 }
                      );
                    } else {
                      setIsCustomModalOpen(true);
                    }
                  }}
                  className="bg-primary-green text-white font-medium text-sm px-2 py-1 rounded shadow-md "
                >
                  Add custom rule
                </button>
                <button
                  onClick={() => {
                    if (issue?.isSaved) {
                      cogoToast.error(
                        "Open the rule in order to select image",
                        { hideAfter: 10 }
                      );
                    } else {
                      setOpenImageModalOpen(true);
                    }
                  }}
                  className="bg-secondary-blue text-white font-medium text-sm px-2 py-1 rounded shadow-md "
                >
                  Select Image
                </button>
              </div>
            )}
          </div>
          <div className="flex flex-col items-center justify-center">
            {image?.imageUrl && (
              <div className="my-10 bg-gray-100 border border-gray-100 shadow-md p-2 rounded w-[300px] h-[300px]">
                <img
                  src={image?.imageUrl}
                  alt=""
                  className="w-full h-[250px]"
                />
                {image?.imageDescription && (
                  <p className="text-gray-700 font-semibold my-2 text-center">
                    {image?.imageDescription}
                  </p>
                )}
              </div>
            )}
          </div>
          <div className="p-5 sm:hidden">
            <div>
              <h3 className="text-secondary-blue text-lg xl:text-2xl font-semibold mb-3">
                Rule Topics
              </h3>
              <Dropdown
                dropdownList={subCategories}
                placeholder="Rule Topics"
                defaultValue={
                  issue?.title ? issue.title : subCategories[0]?.title
                }
                dropdownCallback={(id, isSaved, title) => {
                  const prevIssue = issue;
                  console.log(prevIssue, "prevIssue");
                  setIssue({ issueId: id, isSaved: !!isSaved, title: title });
                  getSubCategoryRulesAPI(id, token);
                  if (!prevIssue.isSaved) {
                    saveRuleAPI(
                      prevIssue.issueId,
                      prevIssue.title,
                      token,
                      "AUTO_SAVE"
                    );
                  }
                }}
              />
            </div>
            <div className="my-10">
              <div className="flex justify-between items-center mb-5">
                <h3 className="w-[70%] text-secondary-blue text-sm font-semibold">
                  Select The Rules That Work For You
                </h3>
                <h3 className="text-secondary-blue text-sm font-semibold">
                  Edit Rule
                </h3>
              </div>
              {/*categoryRules?.length > 0 && (
                <div className="flex space-x-7 items-start relative mb-5">
                  <div className="mt-1 absolute">
                    <Checkbox
                      extraClass={{
                        checked: "h-3 w-3 bg-secondary-blue",
                        unchecked: "h-3 w-3 bg-transparent",
                      }}
                      checked={notApplicableCheckbox}
                      onHandleChangeCheckbox={handleNotApplicableCheckbox}
                    />
                  </div>
                  <p className="text-xs lg:text-base font-medium text-gray-500">
                    Not Applicable
                  </p>
                </div>
                    )*/}
              {categoryRules?.length > 0 &&
                categoryRules.map((rule) => (
                  <div key={rule?.id} className="flex space-x-5 mb-5 ">
                    <div className=" w-[90%] flex items-start space-x-7 relative col-span-3">
                      <div className="mt-1 absolute">
                        <Checkbox
                          extraClass={{
                            checked:
                              "h-3 w-3 border border-secondary-blue bg-secondary-blue",
                            unchecked:
                              "h-3 w-3 border border-secondary-blue bg-transparent",
                          }}
                          checked={!!rule.is_selected}
                          onHandleChangeCheckbox={() => {
                            if (issue.isSaved) {
                              return;
                            }
                            selectRuleAPI(rule.id, token);
                          }}
                        />
                      </div>
                      <p className="text-xs lg:text-base font-medium text-gray-500">
                        {rule?.rule}
                      </p>
                    </div>
                    <div
                      onClick={() =>
                        setIsEditModalOpen({
                          isOpen: true,
                          value: rule.rule,
                          ruleId: rule.id,
                        })
                      }
                    >
                      <PencilSquare className=" text-gray-500 hover:text-secondary-blue w-4 h-4 cursor-pointer" />
                    </div>
                  </div>
                ))}
            </div>
          </div>
          {subCategories.length === 0 && (
            <div className="h-[50vh] text-center">
              <p className="text-3xl text-primary-green font-medium">
                No Community Rules & Regulations Present
              </p>
            </div>
          )}
          {subCategories?.length !== 0 && (
            <div className="hidden h-[52vh] overflow-hidden w-full px-10 py-5 sm:grid sm:grid-cols-5 sm:gap-x-10">
              <div>
                <h3
                  className={`text-secondary-blue text-lg xl:text-2xl font-semibold ${
                    issue?.title === "General Conditions" ? "mb-7" : "mb-14"
                  }`}
                >
                  Rule Topics
                </h3>
                <div className="h-[40vh] scrollbar overflow-y-auto">
                  {subCategories?.length > 0 &&
                    subCategories?.map((category) => (
                      <p
                        className={`rounded-md w-full py-2 pl-3 pr-5 text-left text-xs xl:text-xl font-medium my-2 hover:text-white cursor-pointer hover:bg-secondary-blue
                      ${
                        issue.issueId === category.id
                          ? "bg-secondary-blue text-white"
                          : category?.is_completed === 0
                          ? " text-gray-500"
                          : "text-primary-green"
                      }`}
                        key={category.id}
                        onClick={() => {
                          setIssue({
                            issueId: category.id,
                            isSaved: category.is_completed,
                            title: category.title,
                          });

                          getSubCategoryRulesAPI(category.id, token);
                        }}
                      >
                        {category.title}
                      </p>
                    ))}
                </div>
              </div>
              <div className="col-span-4 ">
                <div className="grid grid-cols-5 gap-x-10">
                  <h3
                    className={`${
                      issue?.title !== "General Conditions"
                        ? "col-span-3"
                        : "col-span-4"
                    } text-secondary-blue text-lg xl:text-2xl font-semibold mb-7`}
                  >
                    Select The Rules That Work For You
                  </h3>
                  {issue?.title !== "General Conditions" && (
                    <h3 className="text-center text-secondary-blue text-lg xl:text-2xl font-semibold mb-7">
                      No Application Required
                    </h3>
                  )}
                  <h3 className="text-secondary-blue text-lg xl:text-2xl font-semibold mb-7">
                    Edit Rule
                  </h3>
                </div>
                <div className="h-[52vh] overflow-hidden">
                  {/*categoryRules?.length > 0 && (
                  <div className="col-span-3 flex space-x-10 items-start relative mb-5">
                    <div className="mt-1.5 absolute">
                      <Checkbox
                        extraClass={{
                          checked: "h-3 w-3 sm:h-4 sm:w-4 bg-secondary-blue",
                          unchecked: "h-3 w-3 sm:h-4 sm:w-4 bg-transparent",
                        }}
                        checked={notApplicableCheckbox}
                        onHandleChangeCheckbox={handleNotApplicableCheckbox}
                      />
                    </div>
                    <p className="text-xs lg:text-base font-medium text-gray-500">
                      Not Applicable
                    </p>
                  </div>
                      )*/}
                  <div className="h-[37vh] scrollbar overflow-y-auto">
                    {categoryRules?.length > 0 &&
                      categoryRules?.map((rule) => (
                        <div
                          key={rule?.id}
                          className="grid grid-cols-5 gap-x-10 mb-5"
                        >
                          <div
                            className={`cursor-pointer flex items-start space-x-10 ${
                              issue?.title !== "General Conditions"
                                ? "col-span-3"
                                : "col-span-4"
                            }`}
                            onClick={() => {
                              if (issue?.isSaved) {
                                return;
                              }
                              selectRuleAPI(rule?.id, token);
                            }}
                          >
                            <div className="">
                              <Checkbox
                                extraClass={{
                                  checked:
                                    "mt-1 h-3 w-3 sm:h-4 sm:w-4 border border-secondary-blue bg-secondary-blue",
                                  unchecked:
                                    "mt-1 h-3 w-3 sm:h-4 sm:w-4 border border-secondary-blue bg-transparent",
                                }}
                                checked={!!rule?.is_selected}
                              />
                            </div>
                            <p className="text-xs lg:text-base font-medium text-gray-500">
                              {rule?.rule}
                            </p>
                          </div>
                          {issue?.title !== "General Conditions" && (
                            <div className="justify-self-center">
                              <Checkbox
                                extraClass={{
                                  checked:
                                    "h-3 w-3 sm:h-4 sm:w-4 border border-secondary-blue bg-secondary-blue",
                                  unchecked:
                                    "h-3 w-3 sm:h-4 sm:w-4 border border-secondary-blue bg-transparent",
                                }}
                                checked={!rule?.submittal_required}
                                onHandleChangeCheckbox={() => {
                                  if (issue?.isSaved || !rule?.is_selected) {
                                    return;
                                  }
                                  submittalRequiredAPI(
                                    rule?.id,
                                    !rule?.submittal_required,
                                    token
                                  );
                                }}
                              />
                            </div>
                          )}
                          <div>
                            <PencilSquare
                              onClick={() => {
                                if (issue?.isSaved) {
                                  return;
                                }
                                setIsEditModalOpen({
                                  isOpen: true,
                                  value: rule?.rule,
                                  ruleId: rule?.id,
                                });
                              }}
                              className="ml-5 text-gray-500 hover:text-secondary-blue w-5 h-5 cursor-pointer"
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          )}
          {subCategories?.length !== 0 && (
            <div className="mt-5 flex flex-col space-y-5 sm:space-y-0 sm:flex-row items-center sm:space-x-10 justify-center">
              <button
                onClick={() => navigate("/preview-selection")}
                className="w-[50%] h-12 sm:max-w-fit border-2 border-secondary-blue text-secondary-blue text-sm xl:text-lg font-medium hover:bg-secondary-blue hover:text-white rounded-md px-5 py-2"
              >
                Preview Selection
              </button>

              <Button
                onClick={() => {
                  saveRuleAPI(issue.issueId, issue.title, token);
                }}
                innerText={
                  !issue.isSaved
                    ? "Rule Completed – Save This Rule"
                    : "Open Rule"
                }
                innerTextStyle="text-sm xl:text-lg font-medium text-white"
                extraClass="w-[50%] h-12 sm:max-w-fit border-2 border-secondary-blue bg-secondary-blue rounded-md py-2"
                isLoading={
                  isLoading.type === "save" ? isLoading.loading : false
                }
                disabled={isLoading.type === "save" ? isLoading.loading : false}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CommunityRules;

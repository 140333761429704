import React, { useState } from "react";
import Checkbox from "../Checkbox/Checkbox";
import axios from "../../util/helpers/axios";
import Spinner from "../Spinner/Spinner";
import cogoToast from "cogo-toast";

const SubscribeNow = () => {
  const [subscribeEmail, setSubscribeEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  // Function subscribeNowAPI
  // The function takes email as the payload and creates subscription of the monthly user article for the user
  // The user can subscribe only once with a particular email

  const subscribeNowAPI = async () => {
    if (!isChecked) {
      cogoToast.error(
        "You must accept the terms and conditions in order to subscribe",
        { hideAfter: 10 }
      );
      return;
    }
    try {
      setIsLoading(true);
      const response = await axios.post("/subscriptions/add_subscription", {
        email: subscribeEmail,
      });

      console.log(response, "response");
      if (response.statusText === "Created") {
        setIsLoading(false);
        setSubscribeEmail("");
        setIsChecked(false);
        cogoToast.success(
          response?.data?.message
            ? response.data.message
            : "You have successfully subscribed to our monthly article",
          { hideAfter: 10 }
        );
      }
    } catch (err) {
      setIsLoading(true);
      console.log(err.response);
      cogoToast.error(
        err?.response?.data?.message
          ? err.response.data.message
          : "Something went wrong. Please try again.",
        { hideAfter: 10 }
      );
      setSubscribeEmail("");
      setIsChecked(false);
      setIsLoading(false);
    }
  };

  return (
    <section className="bg-primary-blue px-3 py-10 lg:p-16 xl:px-18 xl:py-40 ">
      <p className="text-center text-white text-lg lg:text-2xl xl:text-[40px] lg:leading-[2.5rem] xl:leading-[4.3rem]">
        Sign Up To Receive{" "}
        <span className="text-primary-green">Our Monthly Article</span> With
        Advice, Insights And Other <br /> Interesting Tidbits On{" "}
        <span className="text-primary-green">
          {" "}
          Community Associations And Their Management.
        </span>{" "}
        We <br /> Don't Spam. We Simply Share Quality{" "}
        <span className="text-primary-green">Advice For Free</span>
      </p>

      {/******************SUBSCRIBE INPUT BOX*******************************************************************/}

      <div className="flex flex-col sm:flex-row items-center justify-between bg-white rounded-full px-2 py-2.5 sm:py-1.5 mx-auto sm:w-[80%] lg:w-[60%] xl:w-1/2 mt-16 mb-6">
        <input
          onChange={(e) => setSubscribeEmail(e.target.value)}
          value={subscribeEmail}
          type="email"
          name="email"
          placeholder="Please enter your email address"
          className="grow w-full text-center sm:text-left sm:ml-10 focus:outline-none bg-transparent placeholder:text-gray-400 text-sm md:text-lg placeholder:font-medium text-primary-blue font-medium"
        />

        {/***************Button component for all the screens except the mobile************/}

        <button
          onClick={subscribeNowAPI}
          className="hidden sm:inline-flex bg-[#3dbc93] text-white rounded-full sm:px-10 min-w-max py-2.5 font-medium"
        >
          SUBSCRIBE NOW
          {isLoading && (
            <span className="self-center ml-4">
              <Spinner />
            </span>
          )}
        </button>
      </div>

      {/******************Button component the mobile screens only**************************/}

      <button
        onClick={subscribeNowAPI}
        className="sm:hidden bg-[#3dbc93] text-white rounded-full py-2 w-full font-medium mb-5 sm:mb-10"
      >
        SUBSCRIBE NOW
        {isLoading && (
          <span className="self-center ml-4">
            <Spinner />
          </span>
        )}
      </button>

      <div className="flex xs:items-center justify-center space-x-3 xs:space-x-1.5 sm:space-x-3 w-full">
        <div>
          <Checkbox
            extraClass={{
              unchecked:
                "mt-[0.7px] border border-primary-green bg-transparent",
              checked: "mt-[0.7px] border-primary-green bg-primary-green",
            }}
            checked={isChecked}
            onHandleChangeCheckbox={() => setIsChecked((prev) => !prev)}
          />
        </div>
        <p className="sm:pl-2 text-white text-xs sm:text-sm lg:text-base">
          I have read the{" "}
          <span className=" text-primary-green  hover:underline hover:cursor-pointer">
            Privacy Policy{" "}
          </span>
          and agree to it's{" "}
          <span className="text-primary-green  hover:underline hover:cursor-pointer">
            Terms And Conditions{" "}
          </span>
          .
        </p>
      </div>
    </section>
  );
};

export default SubscribeNow;

import React from "react";
import CarouselComponent from "../../../../components/CarouselComponent/CarouselComponent";
import featureBannerImage from "../../../../assets/images/homepage/features-background.png";
const TechnicalQuestions = () => {
  return (
    <section
      style={{
        background: `url(${featureBannerImage})`,
        backgroundSize: `cover`,
        backgroundPosition: `center`,
      }}
      className="relative w-full overflow-x-hidden flex flex-col items-center"
    >
      <div className="w-full mx-auto mt-10 lg:mt-14 flex items-center justify-center space-x-3 text-secondary-blue text-xl text-center sm:text-left sm:text-3xl lg:text-5xl font-medium">
        <div className="bg-primary-blue w-10 sm:w-20 h-[1.6px]"></div>
        <h1>Technical Questions</h1>
        <div className="bg-primary-blue w-10 sm:w-20 h-[1.6px]"></div>
      </div>
      <div className="w-full h-full p-5 sm:p-16">
        <CarouselComponent />
      </div>
    </section>
  );
};

export default TechnicalQuestions;

import React, { useState } from "react";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import LoginBackground from "../../assets/images/login-bg.jpg";
import EyeSlash from "../../assets/SVG/eye-slash";
import Eye from "../../assets/SVG/eye";
import PhoneNumberInput from "../../components/PhoneNumberInput/PhoneNumberInput";
import Dropdown from "../../components/Dropdown/Dropdown";
import axios from "../../util/helpers/axios";
import cogoToast from "cogo-toast";

const SignUp = () => {
  const [showIndividualError, setShowIndividualError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [checkValidation, setCheckValidation] = useState([]); //used to handle the validation of phone number, state and home
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [state, setState] = useState("");
  const [home, setHome] = useState("");
  const [errorMessage, setErrorMessage] = useState();

  const navigate = useNavigate();
  let errorLength;

  function checkErrors() {
    if (errorLength > 0) {
      setShowIndividualError(true);
    }
    let otherErrors = [];
    if (!state) {
      setShowIndividualError(true);
      otherErrors.push("state");
      setErrorMessage("State is required");
    }
    if (phoneNumber === "") {
      setShowIndividualError(true);
      otherErrors.push("phone");
      setErrorMessage("Phone number is required");
    }
    if (phoneNumber.length < 7 || phoneNumber.length > 15) {
      setShowIndividualError(true);
      otherErrors.push("phone");
      setErrorMessage(
        "Phone number should be greater than 6 less than 16 character"
      );
    }
    if (!home) {
      setShowIndividualError(true);
      otherErrors.push("home");
      setErrorMessage("Home is required");
      setErrorMessage();
    }
    setCheckValidation([...otherErrors]);
  }

  // Function: resetCheckValidation
  // This function resets the validation state of phone number input, state and home field
  // With any change in the input of the above states, the validation is managed

  const resetCheckValidation = (type) => {
    let resetValidation = checkValidation.filter((value) => value !== type);
    setCheckValidation([...resetValidation]);
  };

  // Function: signUpAPI
  // This function takes name, association_name, email, password1, password2, contact_no, state and family_status in payload
  // All the above fields are required and creates a new user after all the input fields satisfy all the validations.

  const signUpAPI = async (userData) => {
    //setError({ isError: false, message: "" });
    console.log(checkValidation, "checkValidation", errorLength, "errorLength");
    if (errorLength || checkValidation.length > 0) {
      return;
    }
    try {
      setIsLoading(true);
      const response = await axios.post("/SignUp/", { ...userData });
      console.log(response, "signup");
      if (response) {
        cogoToast.success(
          response?.data?.message
            ? response?.data?.message
            : "Congratulations, You have registered successfully. Please verify your account using the link send on your email",
          { hideAfter: 10 }
        );
      }
      setIsLoading(false);
      setState("");
      setHome("");
    } catch (err) {
      setIsLoading(true);
      console.log(err.response.data.message);
      cogoToast.error(
        err?.response?.data?.message
          ? err?.response?.data?.message
          : "Something went wrong. Please try again.",
        { hideAfter: 10 }
      );
      setIsLoading(false);
      setState("");
      setHome("");
    }
  };

  return (
    <div className="max-w-screen min-h-screen mb-10 sm:px-5">
      <div className="w-full flex flex-col lg:flex-row">
        <div className="order-2 lg:order-none rounded-xl hidden xl:inline-flex flex-col items-center justify-center w-full xl:w-[50%]">
          <img className="-mt-20 mr-20" src={LoginBackground} alt="" />
        </div>

        <div className="relative w-full xl:w-[55%] flex flex-col items-center justify-center">
          <h1 className="mt-10 text-2xl xl:text-4xl font-extrabold text-primary-green xl:leading-[62px]">
            Let's get you there!
          </h1>
          {/* {error.isError && (
            <p className="text-red-600 text-sm font-medium mt-1">
              {error.message}
            </p>
          )} */}
          <Formik
            initialValues={{
              name: "",
              associationName: "",
              email: "",
              password: "",
              passwordConfirmation: "",
            }}
            validationSchema={Yup.object({
              name: Yup.string().required("Name is Required"),
              associationName: Yup.string().required(
                "Association Name is Required"
              ),
              email: Yup.string()
                .email("Invalid email address")
                .required("Email is Required"),
              password: Yup.string()
                .required("Password is required")
                .min(8, "Password must contain 8 characters")
                .matches(
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
                  "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
                ),
              passwordConfirmation: Yup.string()
                .required("Confirm password is required")
                .test(
                  "password-match",
                  "Passwords must match",
                  function (value) {
                    return this.parent.password === value;
                  }
                ),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              const {
                name,
                associationName,
                email,
                password,
                passwordConfirmation,
              } = values;
              // console.log(
              //   email,
              //   "email",
              //   password,
              //   "password",
              //   name,
              //   "name",
              //   associationName,
              //   "associationName"
              // );
              setSubmitting(false);

              if (checkValidation.length === 0 && !errorLength) {
                signUpAPI({
                  name: name,
                  association_name: associationName,
                  email: email,
                  password1: password,
                  password2: passwordConfirmation,
                  contact_no: phoneNumber,
                  state: state,
                  family_status: home,
                });

                setPhoneNumber("");
                setState("");
                setHome("");

                resetForm({
                  name: "",
                  associationName: "",
                  email: "",
                  password: "",
                  passwordConfirmation: "",
                });
              }
            }}
          >
            {(formik) => {
              errorLength = Object.keys(formik.errors).length;
              return (
                <form
                  className="mt-10 w-[85%] lg:w-[68%] xl:w-[90%] 3xl:w-[60%] grid grid-cols-2 sm:gap-x-5 xl:gap-x-5 gap-y-3"
                  onSubmit={formik.handleSubmit}
                >
                  <div className="col-span-2 sm:col-span-1">
                    <p className="mb-1 sm:mb-3 text-sm sm:text-lg text-primary-blue sm:leading-[27px] font-normal">
                      Full Name{" "}
                      <span className="text-red-500 font-medium">*</span>
                    </p>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Enter your name"
                      error={formik.errors.name}
                      showIndividualError={showIndividualError}
                      extraClass={
                        formik.touched.name && formik.errors.name
                          ? "text-primary-blue  border border-red-400"
                          : "text-primary-blue  border border-ternary-blue"
                      }
                      {...formik.getFieldProps("name")}
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <p className="mb-1 sm:mb-3 text-sm sm:text-lg text-primary-blue sm:leading-[27px] font-normal">
                      Association Full Name{" "}
                      <span className="text-red-500 font-medium">*</span>
                    </p>
                    <Input
                      type="text"
                      name="associationName"
                      id="associationName"
                      placeholder="Enter your association name"
                      error={formik.errors.associationName}
                      showIndividualError={showIndividualError}
                      extraClass={
                        formik.touched.associationName &&
                        formik.errors.associationName
                          ? "text-primary-blue  border border-red-400"
                          : "text-primary-blue  border border-ternary-blue"
                      }
                      {...formik.getFieldProps("associationName")}
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <p className="mb-1 sm:mb-3 text-sm sm:text-lg text-primary-blue leading-[27px] font-normal">
                      Email address{" "}
                      <span className="text-red-500 font-medium">*</span>
                    </p>
                    <Input
                      type="text"
                      name="email"
                      id="email"
                      placeholder="Enter your email address"
                      autoComplete="off"
                      error={formik.errors.email}
                      inputValue={formik.values.email}
                      showIndividualError={showIndividualError}
                      extraClass={
                        formik.touched.email && formik.errors.email
                          ? "text-primary-blue  border border-red-400"
                          : "text-primary-blue  border border-ternary-blue"
                      }
                      {...formik.getFieldProps("email")}
                    />
                  </div>

                  <div className="mr-2 sm:mr-0">
                    <p className="mb-1 sm:mb-3 text-sm sm:text-lg text-primary-blue  leading-[27px] font-normal">
                      Password{" "}
                      <span className="text-red-500 font-medium">*</span>
                    </p>
                    <Input
                      type={showPassword.password ? "text" : "password"}
                      name="password"
                      id="password"
                      placeholder="Enter your password"
                      autoComplete="new-password"
                      error={formik.errors.password}
                      inputValue={formik.values.password}
                      Icon={showPassword.password ? EyeSlash : Eye}
                      handleIconClick={() =>
                        setShowPassword({
                          ...showPassword,
                          password: !showPassword.password,
                        })
                      }
                      showIndividualError={showIndividualError}
                      extraClass={
                        formik.touched.password && formik.errors.password
                          ? "text-primary-blue  border border-red-400"
                          : "text-primary-blue  border border-ternary-blue"
                      }
                      {...formik.getFieldProps("password")}
                    />
                  </div>
                  <div>
                    <p className="mb-1 sm:mb-3 text-sm sm:text-lg text-primary-blue  leading-[27px] font-normal">
                      Confirm Password{" "}
                      <span className="text-red-500 font-medium">*</span>
                    </p>
                    <Input
                      id="passwordConfirmation"
                      name="passwordConfirmation"
                      type={showPassword.confirmPassword ? "text" : "password"}
                      autoComplete="passwordConfirmation"
                      placeholder="Confirm Password"
                      inputValue={formik.values.passwordConfirmation}
                      Icon={showPassword.confirmPassword ? EyeSlash : Eye}
                      handleIconClick={() =>
                        setShowPassword({
                          ...showPassword,
                          confirmPassword: !showPassword.confirmPassword,
                        })
                      }
                      error={formik.errors.passwordConfirmation}
                      showIndividualError={showIndividualError}
                      extraClass={
                        formik.touched.passwordConfirmation &&
                        formik.errors.passwordConfirmation
                          ? "text-primary-blue  border border-red-400"
                          : "text-primary-blue  border border-ternary-blue"
                      }
                      {...formik.getFieldProps("passwordConfirmation")}
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <p className="mb-1 sm:mb-3 text-base sm:text-lg text-primary-blue  leading-[27px] font-normal">
                      Phone Number{" "}
                      <span className="text-red-500 font-medium">*</span>
                    </p>
                    <PhoneNumberInput
                      value={phoneNumber}
                      setValue={(value) => {
                        setPhoneNumber(value);
                        if (value) {
                          resetCheckValidation("phone");
                        }
                      }}
                    />
                    {checkValidation.includes("phone") && (
                      <span className="pl-1 text-xs sm:text-sm font-normal text-red-400">
                        {errorMessage}
                      </span>
                    )}
                  </div>
                  <div className="mr-2 sm:mr-0">
                    <p className="mb-1 sm:mb-3 text-base sm:text-lg text-primary-blue leading-[27px] font-normal">
                      State <span className="text-red-500 font-medium">*</span>
                    </p>
                    <Dropdown
                      dropdownList={[
                        { title: "All", id: "All" },
                        { title: "Florida", id: "Florida" },
                        { title: "Georgia", id: "Georgia" },
                        { title: "Texas", id: "Texas" },
                        // {
                        //   title: "All Except Florida",
                        //   id: "All except Florida",
                        // },
                      ]}
                      placeholder="State"
                      dropdownCallback={(value) => {
                        setState(value);
                        if (value) {
                          resetCheckValidation("state");
                        }
                      }}
                      defaultValue={state}
                    />
                    {checkValidation.includes("state") && (
                      <span className="pl-1 text-xs sm:text-sm font-normal text-red-400">
                        {errorMessage}
                      </span>
                    )}
                  </div>

                  <div>
                    <p className="mb-1 sm:mb-3 text-base sm:text-lg text-primary-blue  leading-[27px] font-normal">
                      Home Type(s){" "}
                      <span className="text-red-500 font-medium">*</span>
                    </p>
                    <Dropdown
                      dropdownList={[
                        { title: "Single Family", id: "SF" },
                        { title: "Town House", id: "TH" },
                        { title: "Both", id: "B" },
                      ]}
                      placeholder="Home type"
                      dropdownCallback={(value) => {
                        setHome(value);
                        if (value) {
                          resetCheckValidation("home");
                        }
                      }}
                      defaultValue={
                        home === "TH"
                          ? "Town House"
                          : home === "SF"
                          ? "Single Family"
                          : home === "B"
                          ? "Both"
                          : ""
                      }
                    />
                    {checkValidation.includes("home") && (
                      <span className="pl-1 text-xs sm:text-sm font-normal text-red-400">
                        {errorMessage}
                      </span>
                    )}
                  </div>

                  <div className="mt-5 col-span-2 w-full flex flex-col space-y-7">
                    <Button
                      onClick={() => checkErrors()}
                      innerText="Sign Up"
                      type="submit"
                      extraClass={`w-full sm:w-[60%] mx-auto text-center h-[45px] text-sm sm:text-base xl:text-lg text-white font-medium rounded-full bg-primary-green ${
                        isLoading && "opacity-20"
                      }`}
                      isLoading={isLoading}
                      disabled={isLoading}
                    />
                    {/* <div className="sm:flex items-center space-y-5 sm:space-y-0 sm:space-x-5 col-span-2 w-full">
                      <GoogleOAuth
                        extraClass="w-full sm:w-1/2 text-center h-[45px] text-sm sm:text-base xl:text-lg text-primary-green font-normal rounded-full bg-white border border-primary-green"
                        innerText="Sign up with Google"
                      />
                      <FacebookOAuth
                        extraClass="w-full sm:w-1/2 text-center h-[45px] text-sm sm:text-base xl:text-lg text-primary-green font-normal rounded-full bg-white border border-primary-green"
                        innerText="Sign up with Facebook"
                      />
                    </div> */}
                  </div>
                </form>
              );
            }}
          </Formik>
          <div>
            <p className="mt-3 mb-5 sm:mb-16 text-sm sm:text-lg leading-[28px] text-gray-600 font-light">
              Already have an account?&nbsp;
              <span
                onClick={() => navigate("/login")}
                className="text-primary-green font-medium cursor-pointer hover:underline"
              >
                Log in
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;

import React, { useState } from "react";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import ForgotPasswordBackground from "../../assets/images/forgot-password-bg.jpg";
import axios from "../../util/helpers/axios";
import cogoToast from "cogo-toast";

const ForgotPassword = () => {
  const [showIndividualError, setShowIndividualError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  //const [error, setError] = useState({ isError: false, message: "" });

  const navigate = useNavigate();
  let errorLength;

  function checkErrors() {
    if (errorLength > 0) {
      setShowIndividualError(true);
    }
  }

  // Function: sendOtpAPT
  // The function takes the registered email address of the user and sends the OTP to the specified email address

  const sendOtpAPI = async (email) => {
    if (!email) {
      cogoToast.error("Please enter the email address.", {
        hideAfter: 10,
      });
      return;
    }
    try {
      setIsLoading(true);
      const response = await axios.post("/sendPasswordOTP", { email: email });
      console.log(response.data);
      if (response.statusText === "OK") {
        localStorage.setItem("emailId", email);
        navigate("/reset-password");
        cogoToast.success(
          response?.data?.message
            ? response.data.message
            : "Verification code has been sent successfully to your email address.",
          { hideAfter: 10 }
        );
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(true);
      console.log(err.response);
      cogoToast.error(
        err?.response?.data?.message
          ? err.response.data.message
          : "Something went wrong. Please try again.",
        { hideAfter: 10 }
      );
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-screen py-10">
      <div className="mt-0 xl:mt-10 w-full flex flex-col lg:flex-row">
        <div className="order-2 lg:order-none rounded-xl hidden lg:inline-flex flex-col items-center justify-center w-full xl:w-1/2">
          <img
            className="xl:-mt-10 xl:h-[95%] lg:w-[80%] xl:w-[70%]"
            src={ForgotPasswordBackground}
            alt=""
          />
        </div>

        <div className="relative w-full xl:w-1/2 flex flex-col items-center justify-center">
          <h1 className="mt-10 text-2xl xl:text-4xl font-extrabold text-primary-green xl:leading-[62px]">
            Forgot Password
          </h1>

          <div className="mt-1 text-center text-sm text-gray-400">
            <p>
              Enter your registered email address to <br></br>
              change your account password.
            </p>
          </div>

          {/* {error.isError && (
            <p className="text-red-600 text-sm font-medium">{error.message}</p>
          )} */}
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email("Invalid email address")
                .required("Email is Required"),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              const { email } = values;
              console.log(email, "email");
              sendOtpAPI(email);
              setSubmitting(false);
              resetForm({ email: "" });
            }}
          >
            {(formik) => {
              errorLength = Object.keys(formik.errors).length;
              return (
                <form
                  className="w-[85%] sm:w-[50%] lg:w-[68%] xl:w-[100%] 3xl:w-[60%]"
                  onSubmit={formik.handleSubmit}
                >
                  <div className="mx-auto lg:max-w-fit">
                    <div className="mt-10 mb-5">
                      <p className="mb-1 sm:mb-3 text-base sm:text-xl text-primary-blue leading-[27px] font-normal">
                        Email address{" "}
                        <span className="text-red-500 font-medium">*</span>
                      </p>
                      <Input
                        type="text"
                        name="email"
                        id="email"
                        placeholder="Enter your registered email address"
                        autoComplete="email"
                        error={formik.errors.email}
                        value={formik.values.email}
                        showIndividualError={showIndividualError}
                        extraClass={
                          formik.touched.email && formik.errors.email
                            ? "text-primary-blue placeholder:text-secondary-blue-ltr-ltr border border-primary-red"
                            : "text-primary-blue placeholder:text-secondary-blue-ltr-ltr border border-ternary-blue"
                        }
                        {...formik.getFieldProps("email")}
                      />
                    </div>

                    <div className="mt-10 w-full lg:w-[350px] flex flex-col space-y-7">
                      <Button
                        onClick={() => checkErrors()}
                        innerText="Send Verification Code"
                        type="submit"
                        extraClass={`text-center h-[45px] text-sm sm:text-base xl:text-lg text-white font-medium rounded-full bg-primary-green ${
                          isLoading && "opacity-20"
                        }`}
                        isLoading={isLoading}
                        disabled={isLoading}
                      />
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
          <div>
            <p className="mt-3 mb-5 sm:mb-16 text-sm sm:text-lg leading-[28px] text-gray-600 font-light">
              Sign in instead?&nbsp;
              <span
                onClick={() => navigate("/login")}
                className="text-primary-green font-medium cursor-pointer hover:underline"
              >
                Sign In
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;

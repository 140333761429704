import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = ({ token, redirectPath, children }) => {
  //console.log(token);

  if (!token) return <Navigate to={redirectPath} replace />;

  return children ? children : <Outlet />;
};

export default ProtectedRoute;

import React, { useRef } from "react";
import CircleStack from "../../../assets/SVG/circle-stack";
import CursorArrow from "../../../assets/SVG/cursor-arrow";
import ShieldCheck from "../../../assets/SVG/shield-check";
import LightBulb from "../../../assets/SVG/light-bulb";
import HousePaint from "../../../assets/images/house-paint.png";
import RuleBook from "../../../assets/images/rule-book.png";
import Document from "../../../assets/images/document.jpg";
import RulesOptionsCard from "../../../components/RulesOptionsCard/RulesOptionsCard";
import { useNavigate } from "react-router-dom";
//import axios from "../../../util/helpers/axios";
//import { useAuth } from "../../../CustomHook/auth-hook";
import ScrollDownButton from "../../../assets/SVG/scroll-down-button";

const RulesDashboard = () => {
  const navigate = useNavigate();
  //const [isLoading, setIsLoading] = useState(false);
  //const { token } = useAuth();
  //const CURRENT_PLAN = JSON.parse(localStorage.getItem("userData"))?.user?.plan;

  // const getSubCategoriesAPI = async (category, token, plan) => {
  //   setIsLoading(true);
  //   try {
  //     const response = await axios.get(
  //       `issues_list/?category=${category}&hoarTemplatetoken=${token}&plan=${plan}`
  //     );
  //     console.log(response, "Response Sub Category");
  //     if (response.statusText === "OK") {
  //       console.log(response.data.issueList, "issue list response api");
  //       if (category === "AR") {
  //         navigate("/dashboard/architectural-guidelines", {
  //           state: { subCategories: response.data.issueList },
  //         });
  //       } else {
  //         navigate("/dashboard/community-rules", {
  //           state: { subCategories: response.data.issueList },
  //         });
  //       }
  //     }
  //     setIsLoading(false);
  //   } catch (err) {
  //     console.log(err);
  //     setIsLoading(false);
  //   }
  // };

  const bottom = useRef(null);

  const scrollToBottom = () => {
    bottom.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="relative max-w-screen overflow-x-hidden min-h-screen">
      <section
        style={{ backgroundImage: "linear-gradient(#0a285e, #3dbc93)" }}
        className="bg-primary-blue flex flex-col items-center pt-10 xl:pt-16"
      >
        <div>
          <h1 className="font-medium text-2xl text-center sm:text-3xl lg:text-4xl xl:text-5xl text-white">
            Master Rule Library
          </h1>
          <p className="px-5 sm:px-0 text-center text-base xl:text-xl max-w-2xl mx-auto mt-3 text-blue-100">
            We have over a{" "}
            <span className="text-primary-green font-medium">
              Hundred Rule Topics
            </span>{" "}
            and{" "}
            <span className="text-primary-green font-medium">
              Thousands of Specific Rules{" "}
            </span>
            for your consideration in our{" "}
            <span className="text-primary-green font-medium">
              Master Rule Library
            </span>
          </p>
        </div>

        <section>
          <div>
            <p className="text-center mt-10 text-lg lg:text-2xl text-white font-light">
              Here Is How It Works In 4 Easy Steps
            </p>
            <div className="bg-white mt-1 w-[25%] mx-auto h-[1px]"></div>
          </div>
          <div className="px-5 sm:px-0 w-screen grid grid-cols-1 lg:grid-cols-2 place-items-center place-content-between mt-10 gap-y-6 lg:mt-16 lg:gap-y-10">
            <RulesOptionsCard
              Icon={CircleStack}
              description="Select Architectural Guidelines & Standards or Community Rules & Regulations to begin."
              ruleNo={1}
            />
            <RulesOptionsCard
              Icon={CursorArrow}
              description="Choose a Rule Topic."
              ruleNo={2}
            />
            <RulesOptionsCard
              Icon={LightBulb}
              description="Select the Rules that work for you."
              ruleNo={3}
            />
            <RulesOptionsCard
              Icon={ShieldCheck}
              description="Click this Rule Completed and move to next Rule Topic."
              ruleNo={4}
            />
          </div>
        </section>
        <button className="lg:transform lg:transition lg:duration-500 lg:hover:scale-110 border-2 border-secondary-blue my-10 sm:m-16 bg-secondary-blue text-white py-5 px-7 text-sm sm:text-lg font-medium rounded-lg shadow-xl">
          Watch Quick Instruction Video
        </button>

        <section className="bg-white py-10 lg:py-16 px-7 lg:px-10 xl:px-24 w-full">
          <h1 className="font-medium text-2xl text-center sm:text-3xl lg:text-3xl xl:text-4xl text-secondary-blue mb-10">
            Let's Create Your Document
          </h1>
          <div className="w-full flex items-center justify-center lg:justify-between">
            <div className="hidden lg:inline-flex w-[50%] xl:w-[45%]">
              <img src={Document} alt="" />
            </div>
            <div className="flex flex-col items-center space-y-10 w-full lg:w-[50%] xl:w-[55%]">
              <div
                onClick={() => navigate("/dashboard/architectural-guidelines")}
                className="lg:self-end w-full sm:w-[65%] lg:w-[85%] xl:w-[70%] cursor-pointer border-2 border-ternary-blue shadow-xl bg-white p-5 xl:px-16 xl:py-10 rounded-lg flex flex-col items-center space-y-3"
              >
                <div className="p-4 bg-gray-200 border border-gray-200 shadow-sm rounded-full max-w-fit">
                  <img
                    src={HousePaint}
                    alt=""
                    className="h-12 w-12 object-cover"
                  />
                </div>
                <p className="text-center sm:text-left xl:text-xl font-semibold text-primary-blue pb-2">
                  Architectural Guidelines & Standards
                </p>
                <button
                  //disabled={isLoading}
                  className="border border-secondary-blue bg-secondary-blue p-2 rounded-md text-sm font-medium text-white"
                >
                  Create Your Document
                </button>
              </div>

              <div
                onClick={() => navigate("/dashboard/community-rules")}
                className="lg:self-end w-full sm:w-[65%] lg:w-[85%] xl:w-[70%] cursor-pointer border-2 border-ternary-blue shadow-xl bg-white p-5 xl:px-16 xl:py-10 rounded-lg flex flex-col items-center space-y-3"
              >
                <div className="p-4 bg-gray-200 border border-gray-200 shadow-sm rounded-full max-w-fit">
                  <img
                    src={RuleBook}
                    alt=""
                    className="h-12 w-12 object-cover"
                  />
                </div>
                <p className="text-center sm:text-left sm:text-xl font-semibold text-primary-blue pb-2">
                  Community Rules & Regulations
                </p>
                <button
                  //disabled={isLoading}
                  className="border border-secondary-blue bg-secondary-blue p-2 rounded-md text-sm font-medium text-white"
                >
                  Create Your Document
                </button>
              </div>
            </div>
          </div>
        </section>
        <div onClick={scrollToBottom} className="fixed right-5 bottom-5">
          <ScrollDownButton />
        </div>
      </section>
      <div ref={bottom}></div>
    </div>
  );
};

export default RulesDashboard;

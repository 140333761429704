import React, { useCallback, useEffect, useState } from "react";
import axios from "../../util/helpers/axios";
import Account from "../../assets/SVG/account";
import Button from "../../components/Button/Button";
import Banknotes from "../../assets/SVG/banknotes";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import UpdateUserInfoModal from "../../components/UpdateUserInfoModal/UpdateUserInfoModal";
import { stringTruncateFromCenter } from "../../util/functions/string-truncate";
import cogoToast from "cogo-toast";
import Team from "../../assets/images/team.jpg";
import Trash from "../../assets/SVG/trash";
import ChangeAouModal from "../../components/ChangeAouModal/ChangeAouModal";
import Spinner from "../../components/Spinner/Spinner";
import PaperAirplane from "../../assets/SVG/paper-airplane";

const MyAccount = ({ token }) => {
  const [userDetails, setUserDetails] = useState();
  const [paymentDetails, setPaymentDetails] = useState();
  const [teamDetails, setTeamDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSpinner, setIsLoadingSpinner] = useState(false);
  const [openUpdateUserInfoModal, setOpenUpdateUserInfoModal] = useState(false);
  const [openChangeAouModal, setOpenChangeAouModal] = useState(false);

  const navigate = useNavigate();
  console.log(teamDetails, "teamDetails");

  const resendInviteUserAPI = async (teamId) => {
    if (!teamId) {
      cogoToast.error("Something went wrong. Please try again.", {
        hideAfter: 10,
      });
      return;
    }
    setIsLoadingSpinner(teamId);
    const formData = new FormData();
    formData.append("teamID", teamId);
    formData.append("hoarTemplatetoken", token);

    try {
      const response = await axios.post("/resendInvite/", formData);
      console.log(response);
      if (response) {
        setIsLoadingSpinner(null);
      }
    } catch (err) {
      console.log(err);
      cogoToast.error(
        err?.response?.data?.message
          ? err.response.data.message
          : "Something went wrong. Please try again.",
        { hideAfter: 10 }
      );
      setIsLoadingSpinner(null);
    }
  };

  const deleteCommunityMemberAPI = async (userId) => {
    if (!token || !userId) {
      cogoToast.error("Something went wrong. Please try again.", {
        hideAfter: 10,
      });
      return;
    }

    setIsLoadingSpinner(userId);
    try {
      const response = await axios.get(
        `deleteCustomUser?hoarTemplatetoken=${token}&userId=${userId}`
      );
      if (response) {
        setIsLoadingSpinner(null);
        getTeamDetailsAPI(false);
      }
    } catch (err) {
      cogoToast.error(
        err?.response?.data?.message
          ? err.response.data.message
          : "Something went wrong. Please try again.",
        { hideAfter: 10 }
      );
      setIsLoadingSpinner(null);
    }
  };

  const getUserInfoAPI = useCallback(async () => {
    if (!token) {
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.get(`userDetails/${token}`);
      console.log(response.data, "Response user info");
      setIsLoading(false);
      if (response?.data) {
        setUserDetails(response?.data);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  }, [token]);

  const getTeamDetailsAPI = useCallback(
    async (pageReload = true) => {
      if (pageReload) {
        setIsLoading(true);
      }
      if (!userDetails?.is_aou || !token) {
        return;
      }
      try {
        const response = await axios.get(`team/?hoarTemplatetoken=${token}`);
        if (response?.data?.status === 1) {
          setTeamDetails([...response?.data?.data]);
        } else {
          cogoToast.error(
            response?.data?.message
              ? response.data.message
              : "Something went wrong. Please try again.",
            { hideAfter: 10 }
          );
        }
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    },
    [token, userDetails?.is_aou]
  );

  const getPaymentDetailsAPI = useCallback(async () => {
    if (!token) {
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.get(
        `payment/getPaymentDetails/?hoarTemplatetoken=${token}`
      );
      console.log(response.data, "Response user info");
      setIsLoading(false);
      if (response?.data) {
        setPaymentDetails(response?.data?.data);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  }, [token]);

  useEffect(() => {
    getUserInfoAPI();
    getTeamDetailsAPI();
    getPaymentDetailsAPI();
  }, [getUserInfoAPI, getPaymentDetailsAPI, getTeamDetailsAPI]);

  return (
    <section className="max-w-screen min-h-screen relative">
      {openChangeAouModal && (
        <ChangeAouModal
          teamMembers={
            teamDetails &&
            teamDetails.length > 0 &&
            teamDetails.map((member) => {
              return {
                id: member?.id,
                title: member?.name,
                secondaryTitle: member?.email,
              };
            })
          }
          setOpen={setOpenChangeAouModal}
        />
      )}
      {/* {openUpgradePlanModal && (
        <UpgradePlanModal
          extraPrice={userDetails?.availedRenewal ? 50 : 30}
          upgradeToPlan="Community User"
          setOpen={setOpenUpgradePlanModal}
        />
      )} */}
      {openUpdateUserInfoModal && (
        <UpdateUserInfoModal
          userDetails={userDetails}
          setOpen={setOpenUpdateUserInfoModal}
        />
      )}
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <div
          style={{ backgroundImage: "linear-gradient(#0a285e, #3dbc93)" }}
          className="flex flex-col items-center pt-16 pb-20"
        >
          <h1 className="text-xl text-center lg:text-left sm:text-3xl lg:text-4xl xl:text-5xl text-white">
            My Account
          </h1>
          <div className="flex items-center justify-center space-x-28 w-full mt-20">
            <div className="flex flex-col items-center relative w-1/3 h-[580px] bg-white p-10 rounded border border-gray-200 shadow-xl">
              <div className="flex flex-col items-center">
                <Account className="h-20 w-20 text-secondary-blue" />
                <h3 className="text-center mb-10 text-secondary-blue text-3xl font-bold">
                  My Profile
                </h3>
              </div>
              <div className="flex flex-col space-y-5 w-full">
                <div className="flex items-center justify-between">
                  <p className="font-semibold text-secondary-blue text-lg">
                    Name
                  </p>
                  <p>
                    {userDetails?.name
                      ? stringTruncateFromCenter(userDetails?.name, 25)
                      : "-"}
                  </p>
                </div>
                <div className="flex items-center justify-between">
                  <p className="font-semibold text-secondary-blue text-lg">
                    Email Address
                  </p>
                  <p>
                    {userDetails?.email
                      ? stringTruncateFromCenter(userDetails?.email, 25)
                      : "-"}
                  </p>
                </div>
                <div className="flex items-center justify-between">
                  <p className="font-semibold text-secondary-blue text-lg">
                    Phone Number
                  </p>
                  <p>
                    {userDetails?.contact_no
                      ? `+${userDetails?.contact_no}`
                      : "-"}
                  </p>
                </div>
                <div className="flex items-center justify-between">
                  <p className="font-semibold text-secondary-blue text-lg">
                    Legal Name Of Association
                  </p>
                  <p>
                    {userDetails?.association_name
                      ? stringTruncateFromCenter(
                          userDetails?.association_name,
                          25
                        )
                      : "-"}
                  </p>
                </div>
                <div className="flex items-center justify-between">
                  <p className="font-semibold text-secondary-blue text-lg">
                    State Association Located In
                  </p>
                  <p>{userDetails?.state ? userDetails.state : "-"}</p>
                </div>
                <div className="flex items-center justify-between">
                  <p className="font-semibold text-secondary-blue text-lg">
                    Home Type
                  </p>
                  <p>
                    {userDetails?.family
                      ? userDetails.family === "SF"
                        ? "Single Family"
                        : userDetails?.family === "TH"
                        ? "Town House"
                        : userDetails.family === "B"
                        ? "Both"
                        : "-"
                      : "-"}
                  </p>
                </div>
              </div>
              <div className="absolute bottom-7 w-[90%] flex items-center space-x-5">
                {userDetails?.is_aou && (
                  <Button
                    onClick={() => setOpenChangeAouModal(true)}
                    innerText="Change AOU"
                    type="submit"
                    extraClass="w-full text-center h-[45px] text-sm sm:text-base xl:text-lg text-white font-medium rounded-full bg-secondary-blue"
                  />
                )}
                <Button
                  onClick={() => setOpenUpdateUserInfoModal(true)}
                  innerText="Update Account"
                  type="submit"
                  extraClass="w-full text-center h-[45px] text-sm sm:text-base xl:text-lg text-white font-medium rounded-full bg-secondary-blue"
                />
              </div>
            </div>

            <div className="relative flex flex-col items-center w-1/3 h-[580px] bg-white p-10 rounded border border-gray-200 shadow-xl">
              <div className="flex flex-col items-center">
                <Banknotes className="h-20 w-20 text-secondary-blue" />
                <h3 className="text-center mb-1 text-secondary-blue text-3xl font-bold">
                  Plan Details
                </h3>
              </div>
              <div className="flex flex-col items-center justify-center mb-5">
                <div className="flex items-center space-x-2 mb-1">
                  <p className="text-2xl text-primary-green font-semibold">
                    {userDetails?.plan === "SU"
                      ? "Single User"
                      : userDetails?.plan === "CU"
                      ? userDetails?.is_aou
                        ? "Community Administrator"
                        : "Community User"
                      : paymentDetails?.plan === "NP"
                      ? "No Plan"
                      : "Trial User"}
                  </p>
                  {/* {paymentDetails?.plan === "Community User" && (
                    <button
                      onClick={() => setOpenUpgradePlanModal(true)}
                      className="text-white text-xs bg-secondary-blue px-3 py-1.5 rounded-md"
                    >
                      Upgrade Plan
                    </button>
                  )} */}
                </div>
                {paymentDetails?.date && (
                  <div className="flex items-center space-x-3">
                    <p className="text-sm text-gray-400 font-semibold">
                      Date Of Purchase -{" "}
                    </p>
                    <p className="text-sm text-primary-blue font-medium">
                      {paymentDetails?.date}
                    </p>
                  </div>
                )}
                {/* {paymentDetails?.plan && (
                  <div className="flex items-center space-x-3">
                    <p className="text-sm text-gray-400 font-semibold">
                      Valid For -{" "}
                    </p>
                    <p className="text-sm text-primary-blue font-medium">
                      365 Days
                    </p>
                  </div>
                )} */}
              </div>
              <div className="flex flex-col space-y-5 w-full">
                <div className="flex items-center justify-between">
                  <p className="font-semibold text-secondary-blue text-lg">
                    Status
                  </p>
                  <p>{paymentDetails?.status ? paymentDetails?.status : "-"}</p>
                </div>
                <div className="flex items-center justify-between">
                  <p className="font-semibold text-secondary-blue text-lg">
                    Payment Mode
                  </p>
                  <p>
                    {paymentDetails?.plan === "Trial"
                      ? "Free"
                      : paymentDetails?.paymentMode
                      ? paymentDetails?.paymentMode
                      : "-"}
                  </p>
                </div>
                <div className="flex items-center justify-between">
                  <p className="font-semibold text-secondary-blue text-lg">
                    Days Left
                  </p>
                  <p>
                    {paymentDetails?.daysLeft
                      ? `${paymentDetails?.daysLeft} ${
                          paymentDetails?.daysLeft > 1 ? "days" : "day"
                        }`
                      : "-"}
                  </p>
                </div>
                <div className="flex items-center justify-between">
                  <p className="font-semibold text-secondary-blue text-lg">
                    Expiry
                  </p>
                  <p>
                    {paymentDetails?.expiry_date
                      ? paymentDetails?.expiry_date
                      : "-"}
                  </p>
                </div>
              </div>
              <Button
                onClick={() => navigate("/pricing")}
                innerText="Browse Pricing Plans"
                type="submit"
                extraClass="absolute bottom-7 w-2/3 text-center h-[45px] text-sm sm:text-base xl:text-lg text-white font-medium rounded-full bg-secondary-blue"
              />
            </div>
          </div>
        </div>
      )}

      {!isLoading && teamDetails && teamDetails.length > 0 && (
        <div className="pt-16 pb-20 px-16 w-full flex flex-col items-center">
          <h1 className="text-xl text-center lg:text-left sm:text-3xl lg:text-4xl xl:text-5xl text-secondary-blue">
            Team Members
          </h1>
          <div className="flex justify-between mt-20">
            <div className="w-1/3">
              <img src={Team} alt="" />
            </div>
            <div className="w-[60%]">
              <div className="w-full rounded-md border-t border-r border-l border-primary-blue max-h-fit">
                <div className="grid grid-cols-7 text-primary-green font-semibold text-base">
                  <p className="col-span-2 px-5 py-3 border-r border-b border-primary-blue">
                    Name
                  </p>
                  <p className="col-span-2 px-5 py-3 border-r border-b border-primary-blue">
                    Email
                  </p>
                  <p className="px-5 py-3 border-r border-b border-primary-blue">
                    Subscribed
                  </p>
                  <p className="px-5 py-3 border-b border-r border-primary-blue">
                    Remove
                  </p>
                  <p className="text-center px-5 py-3 border-b border-primary-blue">
                    Resend Invite
                  </p>
                </div>
                <div>
                  {teamDetails.map((member) => (
                    <div
                      className="grid grid-cols-7 text-sm text-primary-blue font-medium"
                      key={member?.id}
                    >
                      <p className="col-span-2 px-5 py-3 border-r border-b border-primary-blue">
                        {stringTruncateFromCenter(member?.name, 35)}
                      </p>
                      <p className="col-span-2 px-5 py-3 border-r border-b border-primary-blue">
                        {stringTruncateFromCenter(member?.email, 35)}
                      </p>
                      <p className="px-5 py-3 border-r border-b border-primary-blue">
                        {member?.is_active ? "Yes" : "No"}
                      </p>
                      <button
                        onClick={() => deleteCommunityMemberAPI(member?.userId)}
                        className="w-full flex flex-col items-center border-r justify-between cursor-pointer px-5 py-3 border-b border-primary-blue"
                      >
                        {isLoadingSpinner !== member?.userId && (
                          <Trash className="text-black h-5 w-5 hover:text-red-500" />
                        )}
                        {isLoadingSpinner === member?.userId && (
                          <Spinner fill="#000000" />
                        )}
                      </button>
                      <button
                        onClick={() => resendInviteUserAPI(member?.id)}
                        className="w-full flex flex-col items-center cursor-pointer px-5 py-3 border-b border-primary-blue"
                      >
                        {isLoadingSpinner !== member?.id && (
                          <PaperAirplane className="text-black h-5 w-5 hover:text-blue-500" />
                        )}
                        {isLoadingSpinner === member?.id && (
                          <Spinner fill="#000000" />
                        )}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <Button
                onClick={() => navigate("/compare-user-rule")}
                innerText="Compare User Rule"
                type="submit"
                extraClass="max-w-fit mt-10 mx-auto text-center h-[45px] text-sm sm:text-base xl:text-lg text-white font-medium rounded-full bg-secondary-blue"
              />
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default MyAccount;

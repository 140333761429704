import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import Cross from "../../assets/SVG/cross";
import DropdownArrow from "../../assets/SVG/dropdown-arrow";
import Hamburger from "../../assets/SVG/hamburger";
import RightArrow from "../../assets/SVG/right-arrow";
import Save from "../../assets/SVG/save";
import User from "../../assets/images/user.png";
import Account from "../../assets/SVG/account";
import Logout from "../../assets/SVG/logout";
import { useClickOutside } from "../../CustomHook/use-click-outside";
import { AuthContext } from "../../Context/auth-context";
import HeaderPopupTag from "../HeaderPopupTag/HeaderPopupTag";
import { stringTruncateFromCenter } from "../../util/functions/string-truncate";
import SubAouController from "../SubAouController/SubAouController";
import { api } from "../../util/helpers/URL";

const MyAccount = ({ open, setOpen, user, token }) => {
  let domNode = useClickOutside(() => {
    setOpen(false);
  });

  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  return (
    <>
      {open && (
        <div
          ref={domNode}
          onMouseOver={() => setOpen(true)}
          onMouseOut={() => setOpen(false)}
          className="z-[1000] flex flex-col space-y-5 bg-white absolute top-[75px] right-[50px] shadow-md rounded-md"
        >
          <div className="flex space-x-5 items-center cursor-pointer hover:bg-gray-100 px-7 pt-7 pb-3 rounded-t-md">
            <img src={User} alt="" className="h-8 w-8" />
            <div>
              <p className="hover:font-medium text-lg">
                Welcome! {user?.name && stringTruncateFromCenter(user?.name, 6)}
              </p>
              <p className="text-sm -mt-1">
                {user?.plan === "TR"
                  ? "Trial User"
                  : user?.plan === "SU"
                  ? "Single User"
                  : user?.plan === "CU"
                  ? "Committee User"
                  : ""}
              </p>
            </div>
          </div>

          {token && user?.plan !== "NP" && (
            <div
              onClick={() => {
                navigate("/my-account");
                setOpen(false);
              }}
              className="flex space-x-5 cursor-pointer hover:bg-gray-100 px-7 py-3"
            >
              <Account className="h-7 w-7" />
              <p className="hover:font-medium text-lg">My Account</p>
            </div>
          )}

          {token && user?.plan !== "NP" && (
            <div
              onClick={() => {
                navigate("/preview-selection");
                setOpen(false);
              }}
              className="flex space-x-5 cursor-pointer hover:bg-gray-100 px-7 py-3"
            >
              <Save className="h-6 w-6" />
              <p className="hover:font-medium text-lg">My Saved Rules</p>
            </div>
          )}

          <div
            onClick={() => {
              setOpen(false);
              auth.logout();
              window.location.reload();
              navigate("/");
            }}
            className="flex space-x-5 cursor-pointer hover:bg-gray-100 px-7 pb-7 pt-3"
          >
            <Logout className="h-7 w-7" />
            <p className="hover:font-medium text-lg">Logout</p>
          </div>
        </div>
      )}
    </>
  );
};

const Header = ({ extraClass, user = null, token = null }) => {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openMyAccount, setOpenMyAccount] = useState(false);
  const [openAddCommunityUser, setOpenAddCommunityUser] = useState(false);
  const [banner, setBanner] = useState({
    bannerMessage: "",
    link: "",
    linkAlias: "",
    showBanner: false,
  });

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let bannerMessage = "";
    let link = "";
    let linkAlias = "";
    let showBanner = false;

    if (
      !token ||
      location.pathname === "/purchase/success" ||
      location.pathname === "/purchase/failure"
    ) {
      return;
    } else if (user?.plan === "NP" || user?.plan === "") {
      bannerMessage =
        "Welcome to HOARulesTemplate, please select a plan to get started.";
      link = "/pricing";
      showBanner = true;
      linkAlias = "Click here to Purchase Plan";
    } else if (user?.plan === "TR") {
      if (user?.daysLeft <= 0) {
        bannerMessage =
          "You Trial Plan has expired please purchase Single User or Committee Plan to unlock all features.";
      } else {
        bannerMessage =
          "You are on a trial plan, please purchase Single User or Committee Plan to unlock all features.";
      }
      link = "/pricing";
      showBanner = true;
      linkAlias = "Click here to Purchase Plan";
    } else if (user?.onAutoRenewal) {
      console.log("all good, auto renewal");
    } else if (user?.plan === "SU" || user?.plan === "CU") {
      if (user?.daysLeft <= 0) {
        bannerMessage =
          "Your Plan has expired, please renew your plan to continue the services.";
        showBanner = true;
        link = api + `payment/renewPlan?authToken=${token}&redirect=yes`;
        linkAlias = "Click here to Renew Now";
      } else if (user?.daysLeft <= 10) {
        bannerMessage = `Your plan is about to expire in ${user?.daysLeft} days, please renew your plan to use the services without interuption.`;
        showBanner = true;
        link = api + `payment/renewPlan?authToken=${token}&redirect=yes`;
        linkAlias = "Click here to Renew Now";
      }
    }

    setBanner({ bannerMessage, link, linkAlias, showBanner });
  }, [
    token,
    user?.plan,
    user?.daysLeft,
    user?.onAutoRenewal,
    location?.pathname,
  ]);

  return (
    <>
      {banner?.showBanner && <HeaderPopupTag banner={banner} />}
      {openAddCommunityUser && (
        <SubAouController setOpen={setOpenAddCommunityUser} />
      )}

      <div
        className={`shadow-md w-full relative flex items-center justify-between px-4 xl:px-16 py-5 ${extraClass}`}
      >
        {openMyAccount && (
          <MyAccount
            open={openMyAccount}
            setOpen={setOpenMyAccount}
            user={user}
            token={token}
          />
        )}
        <div onClick={() => navigate("/")}>
          <img
            className="cursor-pointer w-44 xl:w-56 xl:-ml-3"
            src={logo}
            alt="logo"
          />
        </div>
        <div
          onClick={() => setHamburgerOpen((prev) => !prev)}
          className="sm:hidden border border-gray-500 rounded-md px-1"
        >
          <Hamburger className="text-gray-200 w-8 h-8" />
        </div>
        {hamburgerOpen && (
          <div className="absolute z-20 flex flex-col sm:hidden shadow-lg rounded-bl-md rounded-tl-md bg-white px-5 pt-3 pb-20 w-[65%] h-screen right-0 top-0">
            <Cross
              onClick={() => setHamburgerOpen(false)}
              className="w-7 h-7 text-primary-green  font-bold cursor-pointer"
            />
            <div className="flex flex-col px-5 py-3">
              <div className="flex items-center">
                {!openDropdown && (
                  <RightArrow
                    onClick={() => setOpenDropdown((prev) => !prev)}
                    fill="#30bc93"
                    className="h-3.5 w-3.5 -ml-4"
                  />
                )}
                {openDropdown && (
                  <DropdownArrow
                    onClick={() => setOpenDropdown((prev) => !prev)}
                    fill="#30bc93"
                    className="h-3.5 w-3.5 -ml-4"
                  />
                )}
                <p className="ml-3 my-2 text-xl hover:underline text-primary-green  hover:font-medium">
                  Question
                </p>
              </div>
              {openDropdown && (
                <div className="flex flex-col ml-5">
                  <Link
                    className="my-0.5 hover:underline text-primary-green  hover:font-medium"
                    to="/question/technical"
                  >
                    Technical
                  </Link>
                  <Link
                    className="my-0.5 hover:underline text-primary-green  hover:font-medium"
                    to="/question/get-in-touch"
                  >
                    Get in touch
                  </Link>
                </div>
              )}

              <Link
                className="ml-3 my-2 text-xl hover:underline text-primary-green  hover:font-medium"
                to="/pricing"
              >
                Pricing
              </Link>
              <Link
                className="ml-3 my-2 text-xl hover:underline text-primary-green  hover:font-medium"
                to="/about-us"
              >
                About Us
              </Link>
              {token && user?.plan !== "NP" && (
                <Link
                  className="ml-3 my-2 text-xl hover:underline text-primary-green  hover:font-medium"
                  to="/dashboard"
                >
                  My Rules
                </Link>
              )}
              {token && user?.plan === "CU" && (
                <span className="ml-3 my-2 text-xl hover:underline text-primary-green  hover:font-medium">
                  Add Community User
                </span>
              )}

              {!token && (
                <Link
                  className="ml-3 my-2 text-xl hover:underline text-primary-green  hover:font-medium"
                  to="/login"
                >
                  Login
                </Link>
              )}
            </div>
          </div>
        )}
        <div className="hidden sm:flex items-center space-x-5 xl:space-x-12">
          <div
            onMouseOver={() => setOpenDropdown(true)}
            onMouseOut={() => setOpenDropdown(false)}
          >
            <p className="cursor-pointer text-white text-sm lg:text-base font-normal hover:text-primary-green">
              Question
            </p>
            {openDropdown && (
              <div className="absolute rounded-sm shadow-lg z-10 bg-white flex flex-col w-[120px]">
                <Link
                  className="border-b border-gray-200 px-3 py-2 text-primary-green  hover:font-medium"
                  to="/question/technical"
                >
                  Technical
                </Link>
                <Link
                  className="px-3 py-2 text-primary-green  hover:font-medium"
                  to="/question/get-in-touch"
                >
                  Get in touch
                </Link>
              </div>
            )}
          </div>
          <NavLink
            to="/pricing"
            className={({ isActive }) =>
              isActive
                ? "text-primary-green text-base lg:text-xl font-semibold"
                : "text-white text-base font-normal hover:text-primary-green"
            }
          >
            Pricing
          </NavLink>
          <NavLink
            to="/about-us"
            className={({ isActive }) =>
              isActive
                ? "text-primary-green text-base lg:text-xl font-semibold"
                : "text-white text-base font-normal hover:text-primary-green"
            }
          >
            About Us
          </NavLink>

          {token && (
            <>
              {user?.plan !== "NP" && (
                <NavLink
                  to="/dashboard"
                  className={({ isActive }) =>
                    isActive
                      ? "text-primary-green text-base lg:text-xl font-semibold"
                      : "text-white text-sm lg:text-base font-normal hover:text-primary-green"
                  }
                >
                  My Rules
                </NavLink>
              )}
              {token && user?.plan === "CU" && user?.is_aou && (
                <span
                  onClick={() => setOpenAddCommunityUser(true)}
                  className="cursor-pointer text-white text-sm lg:text-base font-normal hover:text-primary-green"
                >
                  Add Community User
                </span>
              )}

              <div className="flex space-x-3 text-white">
                <div>
                  <p className="capitalize text-right text-sm xl:text-base">
                    {user?.name
                      ? stringTruncateFromCenter(user?.name, 15)
                      : "Hello User!"}
                  </p>
                  <p className="text-xs">{user?.email}</p>
                </div>
                <div
                  onClick={() => setOpenMyAccount(true)}
                  onMouseOver={() => setOpenMyAccount(true)}
                  onMouseOut={() => setOpenMyAccount(false)}
                  className="cursor-pointer border-[2px] border-gray-100 shadow-md bg-[#1c223d] px-2 py-1.5 xl:px-3 xl:py-2.5 rounded-full text-base font-medium text-center"
                >
                  {user?.name?.slice(0, 2).toUpperCase()
                    ? user?.name?.slice(0, 2).toUpperCase()
                    : "DF"}
                </div>
              </div>
            </>
          )}

          {!token && (
            <button
              onClick={() => navigate("/login")}
              className="text-primary-green  hover:text-white lg:text-xl border border-primary-green rounded-md text-center px-7 py-1.5 bg-transparent hover:bg-primary-green "
            >
              Login
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default Header;

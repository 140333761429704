import React, { useState } from "react";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import ForgotPasswordBackground from "../../assets/images/forgot-password-bg.jpg";
import EyeSlash from "../../assets/SVG/eye-slash";
import Eye from "../../assets/SVG/eye";
import axios from "../../util/helpers/axios";
import CountdownTimer from "../../components/CountdownTimer/CountdownTimer";
import cogoToast from "cogo-toast";

const ForgotPassword = () => {
  const [showIndividualError, setShowIndividualError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  //const [error, setError] = useState({ isError: false, message: "" });
  const [countDownMessage, setCountDownMessage] = useState(
    "Resend verification code"
  );
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  const navigate = useNavigate();
  let errorLength;

  function checkErrors() {
    if (errorLength > 0) {
      setShowIndividualError(true);
    }
  }

  // Function: sendOtpAPI
  // The function takes the registered email address of the user and sends the OTP to the specified email address

  const sendOtpAPI = async () => {
    //setError({ isError: false, message: "" });
    const email = localStorage.getItem("emailId"); // emailId stored in the localStorage
    if (!email) {
      cogoToast.error("Please enter the email address", {
        hideAfter: 10,
      });
      return;
    }
    try {
      setIsLoading(true);
      const response = await axios.post("/sendPasswordOTP", { email: email });
      console.log(response.data);
      if (response.statusText === "OK") {
        console.log("Resent otp success");
        setCountDownMessage("Verification code sent successfully.");
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(true);
      console.log(err.response);
      cogoToast.error(
        err?.response?.data?.message
          ? err.response.data.message
          : "Something went wrong. Please try again.",
        { hideAfter: 10 }
      );
      setIsLoading(false);
    }
  };

  // Function: changePasswordAPI
  // The function takes otp, email and the new password from the user and resets the password

  const changePasswordAPI = async (otp, password) => {
    const email = localStorage.getItem("emailId");
    //setError({ isError: false, message: "" });
    if (!otp || !password || !email) {
      cogoToast.error("Please enter all the details.", {
        hideAfter: 10,
      });
      return;
    }

    try {
      setIsLoading(true);
      const response = await axios.post("/changePassword", {
        otp: otp,
        password: password,
        email: email,
      });
      if (response.statusText === "OK") {
        console.log("PASSWORD---CHANGES");
        localStorage.removeItem("emailId");
        navigate("/login");
        cogoToast.success(
          response?.data?.message
            ? response.data.message
            : "Your password has been changed successfully.",
          { hideAfter: 10 }
        );
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(true);
      console.log(err.response);
      cogoToast.error(
        err?.response?.data?.message
          ? err.response.data.message
          : "Something went wrong. Please try again.",
        { hideAfter: 10 }
      );
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-screen py-10">
      <div className="w-full flex flex-col lg:flex-row">
        <div className="order-2 lg:order-none rounded-xl hidden lg:inline-flex flex-col items-center justify-center w-full xl:w-1/2">
          <img
            className="xl:-mt-20 xl:h-[90%] w-[80%] xl:w-[70%]"
            src={ForgotPasswordBackground}
            alt=""
          />
        </div>

        <div className="relative w-full xl:w-1/2 flex flex-col items-center justify-center">
          <h1 className="mt-10 text-2xl xl:text-4xl font-extrabold text-primary-green xl:leading-[62px]">
            Create New Password
          </h1>
          {/* {error.isError && (
            <p className="text-red-600 text-sm font-medium">{error.message}</p>
          )} */}
          <Formik
            initialValues={{
              otp: "",
              password: "",
              passwordConfirmation: "",
            }}
            validationSchema={Yup.object({
              otp: Yup.string().required("OTP is Required"),
              password: Yup.string().required("Password is required"),
              //.min(8, "Password must contain 8 characters"),
              passwordConfirmation: Yup.string()
                .required("Confirm password is required")
                .test(
                  "password-match",
                  "Passwords must match",
                  function (value) {
                    return this.parent.password === value;
                  }
                ),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              const { otp, password } = values;
              changePasswordAPI(otp.trim(), password);
              setSubmitting(false);
              resetForm();
            }}
          >
            {(formik) => {
              errorLength = Object.keys(formik.errors).length;
              return (
                <form
                  className="w-[85%] sm:w-[50%] lg:w-[68%] xl:w-[100%] 3xl:w-[60%]"
                  onSubmit={formik.handleSubmit}
                >
                  <div className="mx-auto lg:max-w-fit">
                    <div className="mt-10">
                      <p className="mb-1 sm:mb-3 text-sm sm:text-lg text-primary-blue sm:leading-[27px] font-normal">
                        Enter Verification Code
                      </p>
                      <Input
                        type="text"
                        name="otp"
                        id="otp"
                        placeholder="Enter the code sent on your email"
                        error={formik.errors.otp}
                        showIndividualError={showIndividualError}
                        extraClass={
                          formik.touched.name && formik.errors.name
                            ? "text-primary-blue  border border-red-400"
                            : "text-primary-blue  border border-ternary-blue"
                        }
                        {...formik.getFieldProps("otp")}
                      />

                      <CountdownTimer
                        hoursMinSecs={{
                          hours: 0,
                          minutes: 0,
                          seconds: 30,
                        }}
                        onClickCallback={sendOtpAPI}
                        countDownMessage={countDownMessage}
                      />
                    </div>
                    <div className="mb-5">
                      <p className="mb-1 sm:mb-3 text-sm sm:text-lg text-primary-blue  leading-[27px] font-normal">
                        Password
                      </p>
                      <Input
                        type={showPassword.password ? "text" : "password"}
                        name="password"
                        id="password"
                        placeholder="Enter your password"
                        autoComplete="new-password"
                        error={formik.errors.password}
                        value={formik.values.password}
                        Icon={showPassword.password ? EyeSlash : Eye}
                        handleIconClick={() =>
                          setShowPassword({
                            ...showPassword,
                            password: !showPassword.password,
                          })
                        }
                        showIndividualError={showIndividualError}
                        extraClass={
                          formik.touched.password && formik.errors.password
                            ? "text-primary-blue  border border-red-400"
                            : "text-primary-blue  border border-ternary-blue"
                        }
                        {...formik.getFieldProps("password")}
                      />
                    </div>
                    <div className="mb-5">
                      <p className="mb-1 sm:mb-3 text-sm sm:text-lg text-primary-blue  leading-[27px] font-normal">
                        Confirm Password
                      </p>
                      <Input
                        id="passwordConfirmation"
                        name="passwordConfirmation"
                        type={
                          showPassword.confirmPassword ? "text" : "password"
                        }
                        autoComplete="passwordConfirmation"
                        placeholder="Confirm Password"
                        value={formik.values.passwordConfirmation}
                        Icon={showPassword.confirmPassword ? EyeSlash : Eye}
                        handleIconClick={() =>
                          setShowPassword({
                            ...showPassword,
                            confirmPassword: !showPassword.confirmPassword,
                          })
                        }
                        error={formik.errors.passwordConfirmation}
                        showIndividualError={showIndividualError}
                        extraClass={
                          formik.touched.passwordConfirmation &&
                          formik.errors.passwordConfirmation
                            ? "text-primary-blue  border border-red-400"
                            : "text-primary-blue  border border-ternary-blue"
                        }
                        {...formik.getFieldProps("passwordConfirmation")}
                      />
                    </div>

                    <div className="mt-10 w-full lg:w-[350px] flex flex-col space-y-7">
                      <Button
                        onClick={() => checkErrors()}
                        innerText="Save Password"
                        type="submit"
                        extraClass={`text-center h-[45px] text-sm sm:text-base xl:text-lg text-white font-medium rounded-full bg-primary-green ${
                          isLoading && "opacity-20"
                        }`}
                        isLoading={isLoading}
                        disabled={isLoading}
                      />
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
          <div>
            <p className="mt-3 mb-5 sm:mb-16 text-sm sm:text-lg leading-[28px] text-gray-600 font-light">
              Remember old password? &nbsp;
              <span
                onClick={() => navigate("/signup")}
                className="text-primary-green font-medium cursor-pointer hover:underline"
              >
                Sign In
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;

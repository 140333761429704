import React, { useEffect, useState } from "react";
import ChevronDown from "../../assets/SVG/chevron-down";
import ChevronUp from "../../assets/SVG/chevron-up";

const Dropdown = ({
  extraClass,
  dropdownList,
  placeholder,
  defaultValue = "",
  dropdownCallback,
}) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  useEffect(() => {
    setSelectedValue(defaultValue);
  }, [defaultValue]);

  return (
    <div className="w-full relative">
      <div
        onClick={() => setOpenDropdown((prev) => !prev)}
        className={`border border-ternary-blue flex items-center justify-between text-xs font-medium sm:text-base w-full h-[35px] xl:w-full lg:h-[38px] xl:h-[42px] px-3 sm:pl-3 sm:pr-5 py-1.5 rounded-lg ${extraClass}`}
      >
        <span
          className={
            selectedValue
              ? "text-primary-blue font-normal"
              : "text-gray-400 font-normal"
          }
        >
          {selectedValue ? selectedValue : placeholder}
        </span>
        {!openDropdown && (
          <ChevronDown className="w-5 h-5 text-ternary-blue cursor-pointer" />
        )}
        {openDropdown && (
          <ChevronUp className="w-5 h-5 text-ternary-blue cursor-pointer" />
        )}
      </div>
      {openDropdown && (
        <div className="w-full absolute z-10 max-h-[200px] overflow-y-auto scrollbar bg-white shadow-lg rounded-md border border-gray-200 list-none">
          {dropdownList.map((item) => (
            <li
              className="text-gray-600 pl-2 sm:px-5 py-2 hover:text-primary-green hover:bg-gray-100 cursor-pointer font-medium text-xs hover:text-base sm:text-base sm:hover:text-lg"
              key={item.id}
              onClick={() => {
                if (dropdownCallback) {
                  if (item.is_completed) {
                    dropdownCallback(item.id, item.is_completed, item.title);
                  } else {
                    dropdownCallback(item.id, true, item.title);
                  }
                  setSelectedValue(item.title);
                  setOpenDropdown(false);
                }
              }}
            >
              {item.title}
              {item?.secondaryTitle && (
                <span className="text-xs">
                  <br></br>
                  {item?.secondaryTitle}
                </span>
              )}
            </li>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;

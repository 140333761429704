import React from "react";

const RightArrow = ({ className, fill, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : "w-5 h-5"}
      version="1"
      viewBox="0 0 512 512"
      {...rest}
      fill={fill}
    >
      <path
        d="M960 5102c-19-9-40-28-47-42-10-20-12-536-10-2513l2-2489 28-23c34-30 89-41 135-26 24 7 568 434 1567 1231 842 672 1541 1232 1555 1246 20 19 25 34 25 74 0 42-5 54-30 79-56 54-3060 2445-3095 2463-43 23-85 23-130 0z"
        transform="matrix(.1 0 0 -.1 0 512)"
      ></path>
    </svg>
  );
};

export default RightArrow;

import React from "react";
import { allRuleTopics } from "../../util/data/all-rule-topics/all-rule-topics";
import Sparkles from "../../assets/SVG/sparkles";

const AboutUs = () => {
  return (
    <div
      style={{ backgroundImage: "linear-gradient(#0a285e, #3dbc93)" }}
      className="max-w-screen min-h-screen relative"
    >
      <div className="flex flex-col items-center py-10 xl:pt-16 xl:pb-20">
        <h1 className="text-xl text-center lg:text-left sm:text-3xl lg:text-4xl xl:text-5xl text-white">
          About Us
        </h1>
        <div className="w-[90%] md:max-w-3xl xl:max-w-6xl flex flex-col space-y-5 mt-5 md:mt-10 text-white text-sm md:text-base xl:text-2xl">
          <p>
            We started managing communities and helping to create architectural
            guidelines and community rules in 1998. In the 25 years since then,
            we have watched laws and court cases change association’s
            responsibilities. From this experience, we understand how difficult
            it is for a committee or Board of Directors to create a
            comprehensive document that can cover every potential issue your
            community may face in the future.
          </p>
          <p>
            In all our years of experience in community association management,
            we have been continuously surprised by how much we continue to learn
            year after year. We have combined all this acquired knowledge with
            published documents from some of the finest communities across the
            country to assist you in building the perfect set of documents for
            your community.
          </p>
          <p>
            HOARulesTemplates.com is a new way to create, update and maintain a
            community association’s architectural guidelines and rules. This
            method has revolutionized what was once a painstakingly long and
            arduous process handled with a word processor and a spreadsheet.
            Discover how we can help you achieve the results you need for your
            community today.
          </p>
        </div>
      </div>
      <div className="bg-ternary-blue px-5 py-10 lg:p-10 xl:p-20 mt-10">
        <h1 className="text-lg text-center sm:text-3xl lg:text-4xl xl:text-5xl text-white mb-10 sm:mb-16">
          Here’s a sample of the rule topics that we cover for you:
        </h1>
        <div className="grid grid-cols-4">
          {allRuleTopics.map((topic, index) => (
            <div key={index} className="flex space-x-2">
              <Sparkles className="h-5 w-5 text-orange-300 mt-1" />
              <p className="text-lg text-white font-medium">{topic}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;

import React, { useState } from "react";
import classes from "./modal.module.css";
import Button from "../Button/Button";
import CurrencyDollar from "../../assets/SVG/currency-dollar";
import ArrowLeftCircle from "../../assets/SVG/arrow-left-circle";
import { useAuth } from "../../CustomHook/auth-hook";
import axios from "../../util/helpers/axios";
import cogoToast from "cogo-toast";

const UpgradePlanModal = ({ extraPrice, upgradeToPlan, setOpen }) => {
  const { token } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const upgradePlanAPI = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post("payment/upgrade", {
        authToken: token,
        upgradeTo: "CU",
      });
      if (response?.data.success) {
        if (response?.data.redirect === "" || !response?.data.redirect) {
          cogoToast.success(response?.data.message, {
            hideAfter: 10,
          });
        } else {
          window.location.replace(response?.data.redirect);
        }
      } else {
        cogoToast.error(response?.data?.message, {
          hideAfter: 10,
        });
      }
      setIsLoading(false);
    } catch (err) {
      cogoToast.error(
        err?.response?.data?.message
          ? err.response.data.message
          : "Something went wrong. Please try again.",
        { hideAfter: 10 }
      );
      setIsLoading(false);
    }
  };

  return (
    <div className={classes.modalWrapper}>
      <div className={classes.modal}>
        <div className="bg-white z-20 flex flex-col items-center justify-center p-10 rounded">
          <h3 className="text-2xl font-semibold text-primary-green">
            Upgrade to Community User Plan
          </h3>
          <div className="mt-7 flex flex-col">
            <p className="text-primary-blue font-semibold text-xl">
              Plan Requested:{" "}
              <span className="text-red-500">{upgradeToPlan}</span>
            </p>
            <p className="text-primary-blue font-semibold text-xl mt-1">
              Amount Payable:{" "}
              <span className="text-red-500">${extraPrice}</span>
            </p>
            <p className="my-5 text-base font-medium text-primary-bold">
              Note: Your plan will be upgraded to Committee Plan for $30, if you
              don't have any active renewal. In case of an active renewal you
              will be charged a total amount of $50. In case of auto renewal we
              will automatically adjust your subscription and add $20 in your
              next invoice, you just need to pay $30
            </p>
            <div className="flex items-center justify-between">
              <Button
                Icon={CurrencyDollar}
                innerText="Upgrade Plan"
                innerTextStyle="text-sm lg:text-lg font-medium text-white"
                extraClass="border-2 border-secondary-blue bg-secondary-blue rounded-md"
                isLoading={isLoading}
                disabled={isLoading}
                onClick={() => upgradePlanAPI()}
              />
              <Button
                onClick={() => setOpen(false)}
                Icon={ArrowLeftCircle}
                innerText="Go Back"
                innerTextStyle="text-sm lg:text-lg font-medium text-white"
                extraClass="border-2 border-red-600 bg-red-600 rounded-md"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradePlanModal;

import React, { useContext, useState } from "react";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import LoginBackground from "../../assets/images/login-bg.jpg";
import EyeSlash from "../../assets/SVG/eye-slash";
import Eye from "../../assets/SVG/eye";
//import Checkbox from "../../components/Checkbox/Checkbox";
import axios from "../../util/helpers/axios";
import { AuthContext } from "../../Context/auth-context";
// import GoogleOAuth from "../../components/GoogleOAuth/GoogleOAuth";
// import FacebookOAuth from "../../components/FacebookOAuth/FacebookOAuth";
import cogoToast from "cogo-toast";

const SignIn = () => {
  const [showIndividualError, setShowIndividualError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  //const [error, setError] = useState({ isError: false, message: "" });
  const [showPassword, setShowPassword] = useState(false);
  //const [rememberMe, setRememberMe] = useState(false);

  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const handleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  let errorLength;

  function checkErrors() {
    if (errorLength > 0) {
      setShowIndividualError(true);
    }
  }

  // Function: getUserInfo
  // The function uses the token of the user to fetch the information of the user

  const getUserInfo = async (token) => {
    if (!token) {
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.get(`userDetails/${token}`);
      console.log(response.data, "Response user info");
      setIsLoading(false);
      if (response?.data) {
        return response.data;
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  // Function: signInAPI
  // The function take the email and password of the user and logins the user successfully
  // auth in AuthContext is used to persist the user by storing the token in the localStorage.
  // It also stores the user's name and email of the user in the localStorage

  const signInAPI = async (email, password) => {
    if (!email || !password) {
      return;
    }
    try {
      setIsLoading(true);
      const response = await axios.post("/loginUser", {
        email: email,
        password: password,
      });
      console.log("Response Sign In", response);
      if (response?.statusText === "OK") {
        let userData = {};
        const token = response?.data?.user?.token;
        if (token) {
          userData = await getUserInfo(token);
          console.log(userData, "userData");
        }

        auth.login(userData, token);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(true);
      console.log(err.response);
      cogoToast.error(
        err?.response?.data?.message
          ? err.response.data.message
          : "Something went wrong. Please try again.",
        { hideAfter: 10 }
      );
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-screen">
      <div className="w-full flex flex-col lg:flex-row">
        <div className="order-2 lg:order-none rounded-xl hidden lg:inline-flex w-full xl:w-1/2">
          <img
            className="lg:mt-10 xl:-mt-10 lg:h-[80%] xl:h-[100vh]"
            src={LoginBackground}
            alt=""
          />
        </div>

        <div className="relative w-full xl:w-1/2 flex flex-col items-center justify-center">
          <h1 className="text-2xl xl:text-4xl font-extrabold text-primary-green xl:leading-[62px]">
            Welcome back!
          </h1>
          {/* {error.isError && (
            <p className="text-red-600 text-sm font-semibold -mt-2">
              {error.message}
            </p>
          )} */}
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email("Invalid email address")
                .required("Email is Required"),
              password: Yup.string().required("Password is required"),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              const { email, password } = values;
              signInAPI(email, password);
              setSubmitting(false);
              resetForm();
            }}
          >
            {(formik) => {
              errorLength = Object.keys(formik.errors).length;
              return (
                <form
                  className="w-[85%] sm:w-[50%] lg:w-[68%] xl:w-[100%] 3xl:w-[60%]"
                  onSubmit={formik.handleSubmit}
                >
                  <div className="mx-auto lg:max-w-fit">
                    <div className="mt-10 mb-5">
                      <p className="mb-1 sm:mb-3 text-base sm:text-xl text-primary-blue leading-[27px] font-normal">
                        Email Address{" "}
                        <span className="text-red-500 font-medium">*</span>
                      </p>
                      <Input
                        type="text"
                        name="email"
                        id="email"
                        placeholder="Enter the registered email address"
                        error={formik.errors.email}
                        value={formik.values.email}
                        showIndividualError={showIndividualError}
                        extraClass={
                          formik.touched.email && formik.errors.email
                            ? "text-primary-blue placeholder:text-secondary-blue-ltr-ltr border border-primary-red"
                            : "text-primary-blue placeholder:text-secondary-blue-ltr-ltr border border-ternary-blue"
                        }
                        {...formik.getFieldProps("email")}
                      />
                    </div>

                    <div>
                      <p className="mb-1 sm:mb-3 text-base sm:text-xl text-primary-blue  leading-[27px] font-normal">
                        Password{" "}
                        <span className="text-red-500 font-medium">*</span>
                      </p>
                      <Input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        id="password"
                        placeholder="Enter your password"
                        autoComplete="new-password"
                        error={formik.errors.password}
                        inputValue={formik.values.password}
                        Icon={showPassword ? EyeSlash : Eye}
                        handleIconClick={handleShowPassword}
                        showIndividualError={showIndividualError}
                        extraClass={
                          formik.touched.password && formik.errors.password
                            ? "text-primary-blue placeholder:text-secondary-blue-ltr-ltr border border-primary-red"
                            : "text-primary-blue placeholder:text-secondary-blue-ltr-ltr border border-ternary-blue"
                        }
                        {...formik.getFieldProps("password")}
                      />
                    </div>

                    {/* <div className="flex items-center space-x-2 mt-2">
                      <Checkbox
                        extraClass={{
                          checked: "h-3 w-3 xl:h-4 xl:w-4 bg-secondary-blue",
                          unchecked: "h-3 w-3 xl:h-4 xl:w-4",
                        }}
                        checked={rememberMe}
                        onHandleChangeCheckbox={() =>
                          setRememberMe((prevState) => !prevState)
                        }
                      />
                      <p className="text-center text-gray-600 text-xs sm:text-sm lg:text-base">
                        Remember me?
                      </p>
                    </div> */}

                    <div className="mt-5 w-full lg:w-[350px] flex flex-col space-y-7">
                      <Button
                        onClick={() => checkErrors()}
                        innerText="Log in"
                        type="submit"
                        extraClass={`border border-primary-green text-center h-[45px] text-sm sm:text-base xl:text-lg text-white font-medium rounded-full bg-primary-green ${
                          isLoading && "opacity-20"
                        }`}
                        isLoading={isLoading}
                        disabled={isLoading}
                      />
                      {/* <GoogleOAuth
                        extraClass="text-center h-[45px] text-sm sm:text-base xl:text-lg text-primary-green font-normal rounded-full bg-white border border-primary-green"
                        innerText="Sign in with Google"
                      />

                      <FacebookOAuth
                        innerText="Sign in with Facebook"
                        extraClass="text-center h-[45px] text-sm sm:text-base xl:text-lg text-primary-green font-normal rounded-full bg-white border border-primary-green"
                      /> */}
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
          <div>
            <div className="mt-5 text-center">
              <p className="text-gray-600 text-sm sm:text-lg font-light">
                Forgot &nbsp;
                <span className="text-primary-green font-medium hover:underline">
                  <Link to="/forgot-password">Password?</Link>
                </span>
              </p>
            </div>
            <p className="mb-5 sm:mb-16 text-sm sm:text-lg leading-[28px] text-gray-600 font-light">
              Don't have an account?&nbsp;
              <span
                onClick={() => navigate("/signup")}
                className="text-primary-green font-medium cursor-pointer hover:underline"
              >
                Create Account
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;

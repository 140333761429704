import React from "react";

const Email = ({ className, fill, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : "h-5 w-5"}
      fill="none"
      viewBox="0 0 25 18"
      {...rest}
    >
      <path
        fill={fill ? fill : "#ffffff"}
        fillOpacity="0.89"
        d="M22.803 0H2.197C.987 0 0 .932 0 2.077v13.846C0 17.064.983 18 2.197 18h20.606C24.01 18 25 17.071 25 15.923V2.077C25 .936 24.017 0 22.803 0zm-.304 1.385l-9.952 9.407-10.04-9.407H22.5zM1.465 15.636V2.357l7.054 6.611-7.054 6.668zm1.036.98L9.56 9.942l2.473 2.318a.763.763 0 001.034-.002l2.412-2.28 7.02 6.636H2.501zm21.034-.98L16.515 9l7.02-6.636v13.272z"
      ></path>
    </svg>
  );
};

export default Email;

import React, { useState } from "react";
import classes from "./modal.module.css";
import Input from "../Input/Input";
import Button from "../Button/Button";
import InviteMember from "../../assets/images/invite-member.png";
import Cross from "../../assets/SVG/cross";
import { stringTruncateFromCenter } from "../../util/functions/string-truncate";
import { isValidEmail } from "../../util/functions/validations";
import axios from "../../util/helpers/axios";
import { useAuth } from "../../CustomHook/auth-hook";
import cogoToast from "cogo-toast";

const SubAouController = ({ setOpen }) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [allEmails, setAllEmails] = useState([]);
  const [allNames, setAllNames] = useState([]);
  const [error, setError] = useState({
    type: "",
    message: "",
  });
  const { token } = useAuth();

  const inviteCommunityUserAPI = async () => {
    if (allEmails.length === 0) {
      cogoToast.error("At least one email is required", {
        hideAfter: 10,
      });
      return;
    }

    if (allNames.length === 0) {
      cogoToast.error("At least one name is required", {
        hideAfter: 10,
      });
      return;
    }
    if (allEmails.length !== allNames.length) {
      cogoToast.error(
        "Please make sure your email and names are corresponding and correct",
        {
          hideAfter: 10,
        }
      );
      return;
    }
    setIsLoading(true);
    const inviteUserList = allEmails.map((email, index) => {
      return {
        email,
        name: allNames[index],
      };
    });
    const formData = new FormData();
    formData.append("subaoulist", JSON.stringify(inviteUserList));
    formData.append("hoarTemplatetoken", token);

    try {
      const response = await axios.post("team/", formData);
      console.log(response, "invite community user response");
      if (response?.data.status === 1) {
        cogoToast.success("Community users added successfully!", {
          hideAfter: 10,
        });
      } else {
        cogoToast.error(response?.data.message, {
          hideAfter: 10,
        });
      }
      setIsLoading(false);
      setAllEmails([]);
      setAllNames([]);
      setMessage("");
    } catch (err) {
      console.log(err?.response);
      cogoToast.error(
        err?.response?.data?.message
          ? err.response.data.message
          : "Something went wrong. Please try again.",
        { hideAfter: 10 }
      );
      setIsLoading(false);
    }
  };

  return (
    <div className={classes.modalWrapper}>
      <div className={classes.modal}>
        <div className="relative">
          <Cross
            onClick={() => setOpen(false)}
            className="h-6 w-6 text-ternary-blue hover:text-red-500 cursor-pointer absolute right-4 top-3"
          />
        </div>
        <div className="flex space-x-10 py-10 px-7 w-full">
          <div className="w-1/2 flex flex-col items-center">
            <img className="w-[75%] -mt-7 mb-3" src={InviteMember} alt="" />
            <div className="flex flex-col space-y-3 text-primary-blue text-base">
              <h2 className="text-secondary-blue text-[22px] font-semibold">
                How Does This Work?
              </h2>
              <p>
                Your community users will receive an email invitation to join
                your team!
              </p>
              <p>
                Once they accept, they will also be able to create their own
                version of a rules document and when finished, you as the
                community administrator can create a joint spreadsheet showing
                each users version side-by-side to discuss and finalize!
              </p>
            </div>
          </div>
          <div className="w-1/2 relative">
            <h2 className="text-secondary-blue text-[22px] font-semibold">
              Add Additional Community Users (5 Maximum)
            </h2>
            <div className="flex flex-wrap mt-5">
              {allEmails &&
                allEmails?.length > 0 &&
                allEmails.map((email, index) => (
                  <div
                    key={email}
                    className="relative flex space-x-2 bg-ternary-blue mr-2 text-white text-xs px-2 py-1.5 rounded-md"
                  >
                    <div>
                      <p>{stringTruncateFromCenter(email, 10)}</p>

                      {allNames?.[index] && (
                        <p>{stringTruncateFromCenter(allNames?.[index], 10)}</p>
                      )}
                    </div>
                    <Cross
                      onClick={() => {
                        const emails = allEmails.filter((e) => e !== email);
                        const names = allNames.filter(
                          (n) => n !== allNames[index]
                        );
                        if (error?.type === "email" || error?.type === "name") {
                          setError({
                            type: "",
                            message: "",
                          });
                        }
                        setAllEmails([...emails]);
                        setAllNames([...names]);
                      }}
                      className="h-3 w-3 text-white cursor-pointer mt-0.5"
                    />
                  </div>
                ))}
            </div>
            <div className="flex flex-col space-y-3 mt-3 w-full">
              <div>
                <p className="mb-1 sm:mb-2 text-base text-primary-blue leading-[27px] font-normal">
                  Email address
                </p>
                <div className="flex items-center space-x-2">
                  <Input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Enter the email address"
                    value={email}
                    onChange={(e) => {
                      setError({ type: "", message: "" });
                      setEmail(e.target.value);
                    }}
                    extraClass="w-full text-primary-blue placeholder:text-secondary-blue-ltr-ltr border border-ternary-blue"
                  />
                  <button
                    onClick={() => {
                      setEmail("");
                      if (!email.length) {
                        return;
                      }
                      if (!isValidEmail(email)) {
                        setError({
                          type: "email",
                          message: "Please enter a valid email address",
                        });
                      } else if (allEmails.includes(email)) {
                        setError({
                          type: "email",
                          message: "This email is already present",
                        });
                      } else if (allEmails?.length < 5) {
                        setAllEmails([...allEmails, email]);
                      } else {
                        setError({
                          type: "email",
                          message: "You can only invite 5 people at once",
                        });
                      }
                    }}
                    className="min-w-max bg-primary-green text-white font-medium text-xs px-3 py-1.5 rounded shadow-md "
                  >
                    + Add
                  </button>
                </div>
                {/* {allEmails.length > 0 && (
                  <div className="flex items-center flex-wrap">
                    {allEmails.map((email, index) => (
                      <div
                        key={index}
                        className="cursor-pointer mr-2 mt-2 bg-ternary-blue px-2 py-1 rounded text-xs font-light text-white flex items-center space-x-2"
                      >
                        <p title={email}>
                          {stringTruncateFromCenter(email, 8)}
                        </p>
                        <Cross
                          onClick={() => {
                            const emails = allEmails.filter((e) => e !== email);
                            if (error?.type === "email") {
                              setError({
                                type: "",
                                message: "",
                              });
                            }
                            setAllEmails([...emails]);
                          }}
                          className="h-3 w-3 text-white"
                        />
                      </div>
                    ))}
                  </div>
                )} */}
                {error?.type === "email" && error.message !== "" && (
                  <span className="text-red-500 text-xs mt-1 font-normal">
                    {error?.message}
                  </span>
                )}
              </div>
              <div>
                <p className="mb-1 sm:mb-2 text-base text-primary-blue leading-[27px] font-normal">
                  Name
                </p>
                <div className="flex items-center space-x-2">
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Enter the names corresponding the email"
                    value={name}
                    onChange={(e) => {
                      setError({ type: "", message: "" });
                      setName(e.target.value);
                    }}
                    extraClass="text-primary-blue placeholder:text-secondary-blue-ltr-ltr border border-ternary-blue"
                  />
                  <button
                    onClick={() => {
                      setName("");
                      if (!name) {
                        return;
                      }
                      if (allNames?.length < 5) {
                        setAllNames([...allNames, name]);
                      } else {
                        setError({
                          type: "name",
                          message: "You can only invite 5 people at once",
                        });
                        return;
                      }
                    }}
                    className="min-w-max bg-primary-green text-white font-medium text-xs px-3 py-1.5 rounded shadow-md "
                  >
                    + Add
                  </button>
                </div>
                {/* {allNames.length > 0 && (
                  <div className="flex items-center flex-wrap mt-2">
                    {allNames.map((name, index) => (
                      <div
                        key={index}
                        className="cursor-pointer mr-2 bg-ternary-blue px-2 py-1 rounded text-xs font-light text-white flex items-center space-x-2"
                      >
                        <p title={name}>{stringTruncateFromCenter(name, 8)}</p>
                        <Cross
                          onClick={() => {
                            const names = allNames.filter((n) => n !== name);
                            if (error?.type === "name") {
                              setError({
                                type: "",
                                message: "",
                              });
                            }
                            setAllNames([...names]);
                          }}
                          className="h-3 w-3 text-white"
                        />
                      </div>
                    ))}
                  </div>
                )} */}
                {error?.type === "name" && error.message !== "" && (
                  <span className="text-red-500 text-xs mt-1 font-normal">
                    {error?.message}
                  </span>
                )}
              </div>
              <div>
                <p className="mb-1 sm:mb-2 text-base text-primary-blue leading-[27px] font-normal">
                  Add Any Personal Message (Optional)
                </p>
                <textarea
                  style={{ resize: "none" }}
                  placeholder="Let your community understand why you are inviting them"
                  className="text-primary-blue placeholder:text-secondary-blue-ltr-ltr border border-ternary-blue w-full placeholder:text-[10px] sm:placeholder:text-base focus:outline-none h-[70px] xl:h-[100px] rounded-lg px-2 sm:px-3 py-1.5"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
              <Button
                onClick={inviteCommunityUserAPI}
                innerText="Invite"
                type="submit"
                extraClass={`absolute bottom-2 w-full border border-primary-green text-center h-[45px] text-sm sm:text-base xl:text-lg text-white font-medium rounded-full bg-primary-green ${
                  isLoading && "opacity-20"
                }`}
                isLoading={isLoading}
                disabled={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubAouController;

export const allRuleTopics = [
  "Additional Structures (Permanent)",
  "Additional Structures (Temporary)",
  "Address Numbers",
  "A/C and Heat Pump Equipment",
  "Animals",
  "Antennas & Satellite Dishes",
  "Arbors",
  "Attic Ventilators and Turbines",
  "Awnings",
  "Bat Houses",
  "Bird Houses",
  "Bird Baths",
  "Bird Feeders",
  "Chimneys and Flues",
  "Clotheslines",
  "Compost Containers & Piles",
  "Decks",
  "Docks",
  "Dog Houses",
  "Dog Runs",
  "Door Hardware",
  "Door Transoms",
  "Doors (Front Pedestrian)",
  "Doors (Garage)",
  "Doors (Other)",
  "Drains/French Drains",
  "Driveways",
  "Easements",
  "Electric Usage",
  "Fans (Exterior)",
  "Fencing",
  "Fire Pits",
  "Fire Places / BBQ Grills",
  "Flagpoles",
  "Flags",
  "Banners",
  "Fountains",
  "Furniture",
  "Garages",
  "Garage Screening",
  "Gazebos",
  "Pergolas & Ramada",
  "General Conditions",
  "Grading & Drainage",
  "Greenhouses",
  "Gutters",
  "Downspouts & Drainage",
  "Handicapped Access Facilities",
  "Handrails",
  "Hose Reels",
  "Hot Tubs & Spas",
  "Hurricane Protection",
  "Irrigation",
  "Landscape Borders & Curbing",
  "Landscape Design",
  "Landscape Mulch",
  "Landscape Planters & Pots",
  "Landscape Plants (Artificial)",
  "Landscape Plants (Live)",
  "Landscape Turf Grass (Synthetic)",
  "Landscape Turf Grass (Live)",
  "Landscaping",
  "Landscaping (Florida-Friendly)",
  "Lighting (Exterior)",
  "Lighting (Garage Carriage)",
  "Lighting (Lamp Post)",
  "Lighting (Landscape)",
  "Lighting (Path)",
  "Lightning Rods & Brushes",
  "Mailboxes & Parcel Delivery Boxes",
  "Mechanical Equipment",
  "Outdoor Fireplaces",
  "Outdoor Kitchens",
  "Paint Policy",
  "Patios & Hard Scaping",
  "Personal Lending Library",
  "Playhouses",
  "Porch",
  "Portable Toilet",
  "Public View Screening",
  "Rain Barrels",
  "Recreational & Play Equipment",
  "Removal of Existing Structures",
  "Renewable Energy Devices",
  "Retaining Walls",
  "Roofing",
  "Sauna & Steam Rooms",
  "Screened Enclosures",
  "Security Cameras & Devices",
  "Security Lighting",
  "Security Shutters",
  "Sewage Disposal Systems",
  "Shade Structures",
  "Sheds",
  "Shutters (Decorative)",
  "Sidewalks & Lead Walks",
  "Siding",
  "Solar Tubes & Skylights",
  "Statuary & Figurines",
  "Stone/Veneer",
  "Sunrooms & Atriums",
  "Swimming Pools",
  "Tanks (Storage)",
  "Temporary Storage Containers/Pods",
  "Tiki Huts & Palapa",
  "Trash/Recycle Enclosures",
  "Tree Houses",
  "Trees",
  "Trellises",
  "Utilities",
  "Vegetable & Flower Gardens",
  "Wall Art & Ornaments",
  "Water Features",
  "Water Gardens & Ponds",
  "Water Softeners",
  "Weather Vanes",
  "Wind Chimes",
  "Wind Turbines",
  "Window Treatments",
  "Windows",
  "Yard Art",
  "Yard Ornaments & Exterior Decorative Objects",
];

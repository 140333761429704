import React, { useContext } from "react";
import Button from "../../components/Button/Button";
import successIcon from "../../assets/gif/success.gif";
import { useNavigate, useParams } from "react-router-dom";
import errorIcon from "../../assets/gif/failure.gif";
import axios from "../../util/helpers/axios";
import { AuthContext } from "../../Context/auth-context";
import { useAuth } from "../../CustomHook/auth-hook";

const PaymentOutcome = () => {
  const { result } = useParams();
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const { token } = useAuth();

  const getUserInfo = async (token) => {
    try {
      const response = await axios.get(`userDetails/${token}`);
      console.log(response.data, "Response user info");
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };

  const handleButtonClick = async () => {
    if (result === "success") {
      const userData = await getUserInfo(token);
      if (token) {
        auth.login(userData, token);
        navigate("/dashboard");
      }
    } else if (result === "failure") {
      navigate("/");
    }
  };

  return (
    <div
      className={`flex flex-col items-center py-20 ${
        result === "failure" ? "bg-primary-gray" : "bg-white"
      }`}
    >
      {result === "success" && (
        <div className="text-2xl xl:text-3xl text-secondary-blue font-medium text-center mb-10">
          <p className="font-bold">Congratulations! </p>
          <p className="text-xl xl:text-2xl">
            You have successfully purchased the plan.
          </p>
        </div>
      )}
      {result === "failure" && (
        <div className="text-2xl xl:text-3xl text-secondary-blue font-medium text-center mb-10">
          <p className="font-bold">Process Failed!</p>
          <p className="text-xl xl:text-2xl">
            Please try again or contact our team in case you face any
            difficulties.
          </p>
        </div>
      )}
      {result === "success" && (
        <img src={successIcon} alt="" className="md:w-[400px] md:h-[300px]" />
      )}
      {result === "failure" && (
        <img
          src={errorIcon}
          alt=""
          className="md:w-[400px] md:h-[300px] bg-white"
        />
      )}
      <Button
        onClick={handleButtonClick}
        innerText={
          result === "success" ? "Go to my Dashboard" : "Go to Home Page"
        }
        type="button"
        extraClass="mt-5 mb-2 py-1 px-3 max-w-max text-lg text-white font-normal  rounded-full bg-primary-green"
        innerTextStyle="text-sm md:text-base xl:text-lg"
      />
    </div>
  );
};

export default PaymentOutcome;

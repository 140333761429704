import React, { useState } from "react";
import Button from "../../../../components/Button/Button";
import Input from "../../../../components/Input/Input";
import { Formik } from "formik";
import * as Yup from "yup";
import EmailBackground from "../../../../assets/images/email-bg.jpg";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import Dropdown from "../../../../components/Dropdown/Dropdown";
import axios from "../../../../util/helpers/axios";
import cogoToast from "cogo-toast";

const GetInTouch = () => {
  const [showIndividualError, setShowIndividualError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ type: "", isError: false, message: "" });
  const [contactUsData, setContactUsData] = useState({
    fullName: "",
    emailAddress: "",
    queryRelatedTo: "",
    clientType: "",
    clientPosition: "",
    message: "",
  });

  let errorLength;

  function checkErrors() {
    if (errorLength > 0) {
      setShowIndividualError(true);
    }
    if (!contactUsData.message) {
      setShowIndividualError(true);
      setError({
        type: "individual",
        isError: true,
        message: "Query is required",
      });
    }
  }

  // Function sendQueryAPI
  // The function takes name, email, relTo, userType, query in its payload
  // The attributes name, email, and query are required for

  const sendQueryAPI = async (name, email, contactData) => {
    if (!contactData.message) {
      return;
    }
    try {
      setIsLoading(true);
      const response = await axios.post("/sendQuery/", {
        name: name,
        email: email,
        relTo: contactData.queryRelatedTo.id,
        userType: contactData.clientType,
        //status: contactData.clientPosition,
        query: contactData.message,
      });

      console.log(response, "response");
      if (response.statusText === "OK") {
        setIsLoading(false);
        cogoToast.success(
          response?.data?.message
            ? response.data.message
            : "Question successfully submitted. Our Agents will contact you soon!",
          { hideAfter: 10 }
        );
      }
    } catch (err) {
      setIsLoading(true);
      console.log(err.response);
      cogoToast.error("Something went wrong. Please try again.", {
        hideAfter: 10,
      });
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-screen max-h-screen py-10">
      <div className="w-full flex flex-col lg:flex-row">
        <div className="order-2 lg:order-none rounded-xl hidden lg:inline-flex flex-col items-center justify-center w-full xl:w-1/2">
          <img src={EmailBackground} alt="" className="w-[70%]" />
        </div>

        <div className="scrollbar overflow-y-auto h-[75vh] w-full xl:w-1/2 flex flex-col items-center">
          <h1 className="mt-5 text-2xl xl:text-4xl font-extrabold text-primary-green xl:leading-[62px]">
            Let's Start A Conversation
          </h1>
          {/* {error.isError && (
            <p className="text-primary-red font-semibold">{error.message}</p>
          )} */}
          <Formik
            initialValues={{
              name: "",
              email: "",
            }}
            validationSchema={Yup.object({
              name: Yup.string().required("Name is required"),
              email: Yup.string()
                .email("Invalid email address")
                .required("Email is required"),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              const { email, name } = values;
              //console.log(email, "email", name, "name");
              setContactUsData({
                ...contactUsData,
                fullName: name,
                emailAddress: email,
              });

              if (!showIndividualError) {
                sendQueryAPI(name, email, contactUsData);
                setSubmitting(false);
                resetForm({ email: "", name: "" });
                setContactUsData({
                  fullName: "",
                  emailAddress: "",
                  queryRelatedTo: "",
                  clientType: "",
                  clientPosition: "",
                  message: "",
                });
              }
            }}
          >
            {(formik) => {
              errorLength = Object.keys(formik.errors).length;
              return (
                <form
                  className="w-[85%] sm:w-[60%] lg:w-[80%] xl:w-[60%] 3xl:w-[60%]"
                  onSubmit={formik.handleSubmit}
                >
                  <div className="mx-auto">
                    <div className="my-5">
                      <p className="mb-1 sm:mb-3 text-sm sm:text-lg text-primary-blue sm:leading-[27px] font-normal">
                        Full Name
                      </p>
                      <Input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Enter your name"
                        error={formik.errors.name}
                        showIndividualError={showIndividualError}
                        extraClass={
                          formik.touched.name && formik.errors.name
                            ? "text-primary-blue  border border-red-400"
                            : "text-primary-blue  border border-ternary-blue"
                        }
                        {...formik.getFieldProps("name")}
                      />
                    </div>

                    <div className="mb-5">
                      <p className="mb-1 sm:mb-3 text-base sm:text-xl text-primary-blue leading-[27px] font-normal">
                        Email address
                      </p>
                      <Input
                        type="text"
                        name="email"
                        id="email"
                        placeholder="Enter your email address"
                        autoComplete="email"
                        error={formik.errors.email}
                        value={formik.values.email}
                        showIndividualError={showIndividualError}
                        extraClass={
                          formik.touched.email && formik.errors.email
                            ? "text-primary-blue  border border-red-400"
                            : "text-primary-blue  border border-ternary-blue"
                        }
                        {...formik.getFieldProps("email")}
                      />
                    </div>
                    <div>
                      <div className="my-5">
                        <p className="mb-1 sm:mb-3 text-base sm:text-lg text-primary-blue leading-[27px] font-normal">
                          Question Related To
                        </p>
                        <div className="relative w-full">
                          <Dropdown
                            dropdownList={[
                              { title: "Sales Question", id: "Sales Question" },
                              {
                                title: "Business Question",
                                id: "Business Question",
                              },
                              {
                                title: "Technical Question",
                                id: "Technical Question",
                              },
                              { title: "Other", id: "Other" },
                            ]}
                            placeholder="Please select your question type"
                            dropdownCallback={(queryTopic) =>
                              setContactUsData({
                                ...contactUsData,
                                queryRelatedTo: queryTopic,
                              })
                            }
                            defaultValue={contactUsData.queryRelatedTo.title}
                          />
                        </div>
                      </div>

                      <div className="flex flex-col space-y-5 md:space-y-0 md:flex-row md:space-x-7 mt-5">
                        <div>
                          <p className="text-base sm:text-lg text-primary-blue sm:leading-[27px] font-normal">
                            I am
                          </p>
                          <div className="flex items-center space-x-2 my-2">
                            <Checkbox
                              extraClass={{
                                unchecked:
                                  "mt-1 sm:mt-0 border border-secondary-blue bg-transparent",
                                checked:
                                  "mt-1 sm:mt-0 border-secondary-blue bg-secondary-blue",
                              }}
                              onHandleChangeCheckbox={() =>
                                setContactUsData({
                                  ...contactUsData,
                                  clientType: "A Current Client",
                                })
                              }
                              checked={
                                contactUsData.clientType === "A Current Client"
                              }
                            />
                            <p className="text-center text-gray-600 text-xs sm:text-sm lg:text-base">
                              A Current Client
                            </p>
                          </div>

                          <div className="flex items-center space-x-2 my-2">
                            <Checkbox
                              extraClass={{
                                unchecked:
                                  "mt-1 sm:mt-0 border border-secondary-blue bg-transparent",
                                checked:
                                  "mt-1 sm:mt-0 border-secondary-blue bg-secondary-blue",
                              }}
                              onHandleChangeCheckbox={() =>
                                setContactUsData({
                                  ...contactUsData,
                                  clientType: "A Potential Client",
                                })
                              }
                              checked={
                                contactUsData.clientType ===
                                "A Potential Client"
                              }
                            />
                            <p className="text-center text-gray-600 text-xs sm:text-sm lg:text-base">
                              A Potential Client
                            </p>
                          </div>
                        </div>

                        <div>
                          <p className="text-base sm:text-lg text-primary-blue sm:leading-[27px] font-normal">
                            I am also a
                          </p>

                          <div className="flex items-center space-x-2 my-2">
                            <Checkbox
                              extraClass={{
                                unchecked:
                                  "mt-1 sm:mt-0 border border-secondary-blue bg-transparent",
                                checked:
                                  "mt-1 sm:mt-0 border-secondary-blue bg-secondary-blue",
                              }}
                              onHandleChangeCheckbox={() =>
                                setContactUsData({
                                  ...contactUsData,
                                  clientPosition: "Association Board Member",
                                })
                              }
                              checked={
                                contactUsData.clientPosition ===
                                "Association Board Member"
                              }
                            />
                            <p className="text-center text-gray-600 text-xs sm:text-sm lg:text-base">
                              Association Board Member
                            </p>
                          </div>

                          <div className="flex items-center space-x-2 my-2">
                            <Checkbox
                              extraClass={{
                                unchecked:
                                  "mt-1 sm:mt-0 border border-secondary-blue bg-transparent",
                                checked:
                                  "mt-1 sm:mt-0 border-secondary-blue bg-secondary-blue",
                              }}
                              onHandleChangeCheckbox={() =>
                                setContactUsData({
                                  ...contactUsData,
                                  clientPosition:
                                    "Community Association Manager",
                                })
                              }
                              checked={
                                contactUsData.clientPosition ===
                                "Community Association Manager"
                              }
                            />
                            <p className="text-center text-gray-600 text-xs sm:text-sm lg:text-base">
                              Community Association Manager
                            </p>
                          </div>
                          <div className="flex items-center space-x-2 my-2">
                            <Checkbox
                              extraClass={{
                                unchecked:
                                  "mt-1 sm:mt-0 border border-secondary-blue bg-transparent",
                                checked:
                                  "mt-1 sm:mt-0 border-secondary-blue bg-secondary-blue",
                              }}
                              onHandleChangeCheckbox={() =>
                                setContactUsData({
                                  ...contactUsData,
                                  clientPosition: "Other",
                                })
                              }
                              checked={contactUsData.clientPosition === "Other"}
                            />
                            <p className="text-center text-gray-600 text-xs sm:text-sm lg:text-base">
                              Other
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="my-5">
                          <p className="mb-1 sm:mb-3 text-base sm:text-lg text-primary-blue leading-[27px] font-normal ">
                            Question
                          </p>
                          <textarea
                            style={{ resize: "none" }}
                            placeholder="Write down your question"
                            className={`w-full placeholder:text-[10px] sm:placeholder:text-base focus:outline-none h-[70px] xl:h-[96px] ${
                              error.type === "individual" && error.isError
                                ? "text-primary-blue border border-red-400"
                                : "border border-ternary-blue"
                            } rounded-lg px-2 sm:px-3 py-1.5`}
                            value={contactUsData.message}
                            onChange={(e) => {
                              if (e.target.value) {
                                setError({
                                  type: "",
                                  isError: false,
                                  message: "",
                                });
                                setShowIndividualError(false);
                              }
                              setContactUsData({
                                ...contactUsData,
                                message: e.target.value,
                              });
                            }}
                          />
                          <div>
                            {showIndividualError &&
                            error.type === "individual" &&
                            error.isError ? (
                              <span className="pl-1 text-xs sm:text-sm font-normal text-red-400">
                                {error.message}
                              </span>
                            ) : (
                              <p></p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="mt-7">
                        <Button
                          onClick={() => checkErrors()}
                          innerText="Submit"
                          type="submit"
                          extraClass={`text-center h-[45px] text-sm sm:text-base xl:text-lg text-white font-medium rounded-full bg-primary-green ${
                            isLoading && "opacity-20"
                          }`}
                          isLoading={isLoading}
                          disabled={isLoading}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;

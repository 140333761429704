export const isValidEmail = (email) => {
  var validEmail = false;
  if (email) {
    email = email.trim().toLowerCase();
    var pattern =
      /^[\w-']+(\.[\w-']+)*@([a-zA-Z0-9]+[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*?\.[a-zA-Z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/;
    validEmail = pattern.exec(email);
  }

  return validEmail;
};

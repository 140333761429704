const Input = ({
  placeholder,
  inputValue,
  handleIconClick,
  Icon,
  type,
  name,
  id,
  error,
  showIndividualError,
  extraClass,
  ...rest
}) => {
  return (
    <>
      <div
        className={`border border-ternary-blue flex items-center text-[10px] sm:text-base w-full h-[35px] xl:w-full lg:h-[38px] xl:h-[42px] px-2 sm:px-3 py-1.5 rounded-lg ${extraClass}`}
      >
        <input
          className={`w-full placeholder:text-[10px] sm:placeholder:text-sm xl:placeholder:text-base focus:outline-none`}
          type={type}
          placeholder={placeholder}
          name={name}
          id={id}
          {...rest}
        />
        {Icon && inputValue && (
          <Icon
            onClick={handleIconClick}
            className={
              error
                ? "w-4 h-4 sm:w-5 sm:h-5 xl:w-6 xl:h-6 text-red-400 cursor-pointer"
                : "w-4 h-4 sm:w-5 sm:h-5 xl:w-6 xl:h-6 text-secondary-blue cursor-pointer"
            }
          />
        )}
      </div>
      <div>
        {showIndividualError && error ? (
          <span className="pl-1 text-xs sm:text-sm font-normal text-red-400">
            {error}
          </span>
        ) : (
          <p></p>
        )}
      </div>
    </>
  );
};

export default Input;

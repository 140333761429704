import React, { useContext, useState } from "react";
import Header from "../../components/Header/Header";
import bannerImage from "../../assets/images/homepage/home.jpg";
import featureBannerImage from "../../assets/images/homepage/features-background.png";
import community from "../../assets/images/homepage/community.png";
import CheckCircle from "../../assets/SVG/check-circle";
import FeaturesCard from "./components/FeaturesCard/FeaturesCard";
import DocumentCard from "./components/DocumentCard/DocumentCard";
import TypewriterEffect from "../../components/TypewriterEffect/TypewriterEffect";
import SubscribeNow from "../../components/SubscribeNow/SubscribeNow";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../CustomHook/auth-hook";
import VideoPlayer from "../../components/VidePlayer/VideoPlayer";
import SampleRulesModal from "./components/SampleRulesModal/SampleRulesModal";
import axios from "../../util/helpers/axios";
import { AuthContext } from "../../Context/auth-context";
import cogoToast from "cogo-toast";

const HomePage = () => {
  const navigate = useNavigate();
  const { token, user } = useAuth();
  const auth = useContext(AuthContext);
  const [openModal, setOpenModal] = useState(false);

  console.log(user, "user homepage");

  const getUserInfo = async (token) => {
    try {
      const response = await axios.get(`userDetails/${token}`);
      console.log(response.data, "Response user info");
      if (response?.data) {
        return response.data;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleTryBeforeBuyButton = async () => {
    if (!token) {
      navigate("/login");
      return;
    }

    if (user?.plan === "NP") {
      try {
        const response = await axios.post("payment/purchasePlan", {
          token: token,
          planRequested: "TR",
          autoRenewal: true,
        });
        console.log(response, "purchase plan response");
        if (response?.data?.success) {
          const userData = await getUserInfo(token);
          //console.log(userData, "userData");

          auth.login(userData, token);
          navigate("/dashboard");
        }
      } catch (err) {
        console.log(err.response);
        cogoToast.error(
          err?.response?.data?.message
            ? err.response.data.message
            : "Something went wrong. Please try again.",
          { hideAfter: 10 }
        );
      }
    } else {
      navigate("/dashboard");
      return;
    }
  };

  return (
    <div className="max-w-screen relative">
      {openModal && <SampleRulesModal setOpen={setOpenModal} />}
      <div className="w-full">
        {/**********************CLEAR. CONCISE. ENFORCEABLE**************************************/}

        <section
          style={{
            background: `url(${bannerImage})`,
            backgroundSize: `cover`,
            backgroundPosition: `center`,
          }}
          className="relative w-full h-full overflow-x-hidden flex flex-col"
        >
          <Header user={user} token={token} />
          <div className="mt-24 mb-16 flex flex-col items-center space-y-10">
            <div className="mx-2 sm:mx-0 flex items-center justify-center xl:justify-start space-x-2 mt-2 xl:mt-5">
              <TypewriterEffect dataText={["CLEAR. CONCISE. ENFORCEABLE."]} />
            </div>
            <p className="mx-5 sm:mx-0 leading-[2rem] sm:leading-[3rem] lg:leading-[3.5rem] xl:leading-[4.5rem] text-center sm:w-[80%] text-base sm:text-3xl lg:text-[40px] text-white">
              A COMPLETE SET OF CUSTOMIZED RULES AND ARCHITECTURAL GUIDELINES
              FOR YOUR COMMUNITY IN JUST A FEW HOURS
            </p>
            <button
              onClick={() => setOpenModal(true)}
              className="transform transition duration-500 hover:scale-110 font-medium shadow-md lg:leading-[2rem] w-[90%] sm:w-[60%] lg:w-[60%] xl:w-[40%] px-4 py-3 text-xs sm:text-base lg:text-lg bg-[#3dbc93] rounded-full lg:py-6 xl:px-16 text-center text-white"
            >
              SAMPLE OF THE RULE TOPICS WE COVER
            </button>
            <button
              onClick={handleTryBeforeBuyButton}
              className="transform transition duration-500 hover:scale-110 font-medium shadow-md lg:leading-[2rem] w-[90%] sm:w-[60%] lg:w-[60%] xl:w-[40%] px-4 py-3 text-xs sm:text-base lg:text-lg bg-[#3dbc93] rounded-full lg:py-6 xl:px-16 text-center text-white"
            >
              GIVE IT A TRY BEFORE YOU BUY!
              <br />
              REGISTER AND CREATE A 4 RULE SAMPLE DOCUMENT
            </button>
          </div>
        </section>

        {/*******************Community Chaos -TO- Community Clarity**************************************/}

        <section
          style={{ backgroundImage: "linear-gradient(#0a285e, #3dbc93)" }}
          className="sm:px-5 py-7 xl:p-16"
        >
          <h1 className="text-base text-center sm:text-3xl lg:text-5xl text-white mb-10 sm:mb-20">
            Community Chaos -TO- Community Clarity
          </h1>
          <div className="px-5 sm:px-0 flex flex-col sm:flex-row items-center sm:items-start justify-between">
            <div className="sm:w-1/2 sm:mt-5">
              <div className="flex items-center justify-center sm:justify-start space-x-2 xl:space-x-5 text-white text-lg lg:text-2xl xl:text-3xl mb-3 lg:mb-5">
                <CheckCircle className="w-4 h-4 lg:w-6 lg:h-6" />
                <h3>Clear & Concise</h3>
              </div>
              <div className="text-center sm:text-left text-white text-base sm:text-sm lg:text-lg xl:text-2xl xl:leading-[2.2rem]">
                <p>
                  You need rules that are clear, concise, enforceable, and fair
                  for your community. Every community is different so those
                  rules won't always look the same. This is why the Board of
                  Directors spends months trying to create a document that works
                  for both the community as well as the individual residents.
                  Our HOA Rules Template gives you a hall pass out of those long
                  meetings and a shortcut to clarity.
                </p>
              </div>
            </div>
            <div className="sm:ml-10 sm:w-1/2 order-first sm:order-last mb-10 lg:mb-0">
              <img src={community} alt="" className="xl:w-[90%]" />
            </div>
          </div>
        </section>

        {/**********************Features Section**************************************/}

        <section
          style={{
            background: `url(${featureBannerImage})`,
            backgroundSize: `cover`,
            backgroundPosition: `center`,
          }}
          className="relative w-full h-screen overflow-x-hidden flex flex-col items-center"
        >
          <div className="mx-auto mt-14 flex items-center space-x-3 text-secondary-blue  text-xl text-center sm:text-left sm:text-3xl lg:text-5xl font-medium">
            <div className="bg-primary-blue w-20 h-[1.6px]"></div>
            <h1>Features</h1>
            <div className="bg-primary-blue w-20 h-[1.6px]"></div>
          </div>

          {/****************************Features Cards Component*************************************************/}
          <div
            id="scrollbar"
            className="relative w-[90%] xl:w-[70%] h-[60%] overflow-y-scroll scroll-smooth mt-20 rounded-md"
          >
            <div className="flex flex-col items-center justify-center space-y-10 sm:mr-5">
              <FeaturesCard
                heading="MASTER HOA RULE LIBRARY"
                description={
                  <>
                    <p>
                      Our experience with many communities has taught us what is
                      fair and what works. So let's make this easy. With access
                      to our Library, you can choose your rules from hundreds of
                      potential rules with the flexibility to customize each to
                      fit your community needs.
                    </p>
                    <p className="mt-2">
                      There is no need to reinvent the wheel. With our Library
                      you can take the advantage of the hard work invested by of
                      hundreds of committees over thousands of hours across the
                      country.
                    </p>
                  </>
                }
              />
              <FeaturesCard
                heading="FAIR PRICING MODEL"
                description={
                  <>
                    <p>
                      You will always have the access to your document to make
                      any adjustments needed. The opportunity to review new
                      rules and guidelines that have been added to the library
                      is available every time you log in. This way our document
                      can stay current with any changes in the community, the
                      law, or the industry.
                    </p>
                  </>
                }
              />
              <FeaturesCard
                heading="COMMITTEE FRIENDLY"
                description={
                  <>
                    <p>
                      The account owner can add up to five sub-users to work
                      independently and create their own documents. You can then
                      create a master document showing every users response to
                      each rule. This allows for immediate collaboration without
                      months of going back and forth in meetings.
                    </p>
                  </>
                }
              />
            </div>
          </div>
        </section>

        {/*********************Describing the Process*******************************************************************************************/}

        <section
          style={{ backgroundImage: "linear-gradient(#0a285e, #3dbc93)" }}
          className="sm:px-5 py-5 xl:p-16 lg:h-[60vh] xl:h-[70vh] w-full mt-10"
        >
          <div className="h-full px-5 sm:px-0 flex flex-col sm:flex-row items-center sm:items-start justify-between sm:space-x-5 xl:space-x-16">
            <div className="w-[100%] order-last sm:order-first sm:w-1/2 mt-5 h-full mb-10 lg:mb-0">
              <VideoPlayer url="https://youtu.be/8I3NTE4cn5s" />
            </div>
            <div className="sm:w-1/2 mt-5">
              <div className="text-center flex items-center justify-center sm:justify-start space-x-2 xl:space-x-5 text-white text-lg lg:text-2xl xl:text-3xl mb-3 lg:mb-5">
                <CheckCircle className="w-4 h-4 lg:w-6 lg:h-6" />
                <h3>How it works?</h3>
              </div>
              <div className="text-center sm:text-left text-white text-base sm:text-sm lg:text-lg xl:text-2xl xl:leading-[2.2rem]">
                <p>
                  You need rules that are clear, concise, enforceable, and fair
                  for your community. Every community is different so those
                  rules won't always look the same. This is why the Board of
                  Directors spends months trying to create a document that works
                  for both the community as well as the individual residents.
                  Our HOA Rules Template gives you a hall pass out of those long
                  meetings and a shortcut to clarity.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/*********************Three Simple Steps To Create Your Document*************************************************/}
        <section className="bg-ternary-blue px-5 py-10 lg:p-10 xl:p-20 mt-10">
          <h1 className="text-lg text-center sm:text-3xl lg:text-4xl xl:text-5xl text-white mb-10 sm:mb-16">
            Three Simple Steps To Create Your Document
          </h1>
          <div className="flex flex-col sm:flex-row items-center justify-center space-y-10 sm:space-y-0 sm:justify-between lg:justify-center lg:space-x-10 ">
            <DocumentCard
              index="1"
              description="First, register your association and then choose either the Single User plan or the Committee plan."
              isButton={true}
              buttonText={token ? "GIVE IT A TRY" : "REGISTER"}
              handleButtonClick={() => navigate("/signup")}
            />

            <DocumentCard
              index="2"
              description="Registration taps you right into our master HOA Rule Library and you will be walked through each category of rules you wish to cover for your community."
            />

            <DocumentCard
              index="3"
              description="When completed, simply click on Finished Document and the program will take all of the rules and guidelines you have customized and place them into a finished document for your review and final editing."
            />
          </div>
        </section>

        {/*********************** SUBSCRIBE NOW COMPONENT*********************************************************/}
        <SubscribeNow />
      </div>
    </div>
  );
};

export default HomePage;

import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./Pages/ResetPassword/ResetPassword";
import HomePage from "./Pages/HomePage/HomePage";
import SignIn from "./Pages/SignIn/SignIn";
import SignUp from "./Pages/SignUp/SignUp";
import Pricing from "./Pages/Pricing/Pricing";
import GetInTouch from "./Pages/Queries/components/GetInTouch/GetInTouch";
import Footer from "./components/Footer/Footer";
import RulesDashboard from "./Pages/Rules/RulesDashboard/RulesDashboard";
import ArchitecturalGuidelines from "./Pages/Rules/ArchitecturalGuidelines/ArchitecturalGuidelines";
import CommunityRules from "./Pages/Rules/CommunityRules/CommunityRules";
import SavedRules from "./Pages/Rules/SavedRules/SavedRules";
import { useAuth } from "./CustomHook/auth-hook";
import { AuthContext } from "./Context/auth-context";
import ProtectedRoute from "./util/helpers/protected-route";
import Header from "./components/Header/Header";
import Redirects from "./Pages/Redirects/Redirects";
import InvoiceMyAssociation from "./Pages/InvoiceMyAssociation/InvoiceMyAssociation";
import TechnicalQuestions from "./Pages/Queries/components/TechnicalQuestions/TechnicalQuestions";
import AboutUs from "./Pages/AboutUs/AboutUs";
import CreateIssueForm from "./Pages/CreateIssueForm/CreateIssueForm";
import MyAccount from "./Pages/MyAccount/MyAccount";
import CompareUserRule from "./Pages/Rules/CompareUserRule/CompareUserRule";
import PaymentOutcome from "./Pages/PaymentOutcome/PaymentOutcome";

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { token, user, login, logout } = useAuth();

  const location = useLocation();

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        user: user,
        login: login,
        logout: logout,
      }}
    >
      {isLoading && <p>Loading...</p>}
      {!isLoading && (
        <main>
          {location.pathname !== "/" && (
            <Header
              extraClass={
                location.pathname === "/"
                  ? "bg-[#1c223d66]"
                  : "bg-secondary-blue"
              }
              user={user}
              token={token}
            />
          )}
          <ScrollToTop>
            <Routes>
              <Route path="/" element={<HomePage />} />

              <Route
                path="/login"
                element={
                  !token ? <SignIn /> : <Navigate to="/dashboard" replace />
                }
              />
              <Route
                path="/signup"
                element={
                  !token ? <SignUp /> : <Navigate to="/dashboard" replace />
                }
              />
              <Route
                path="/forgot-password"
                element={
                  !token ? (
                    <ForgotPassword />
                  ) : (
                    <Navigate to="/dashboard" replace />
                  )
                }
              />
              <Route
                path="/reset-password"
                element={
                  !token ? (
                    localStorage.getItem("emailId") ? (
                      <ResetPassword />
                    ) : (
                      <Navigate to="/forgot-password" replace />
                    )
                  ) : (
                    <Navigate to="/dashboard" replace />
                  )
                }
              />
              <Route path="/SignUp/:token" element={<Redirects />} />

              <Route path="/pricing" element={<Pricing />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/question/get-in-touch" element={<GetInTouch />} />
              <Route path="/layouts/adminForm" element={<CreateIssueForm />} />

              <Route
                path="/question/technical"
                element={<TechnicalQuestions />}
              />
              <Route
                path="/invoice-my-association"
                element={<InvoiceMyAssociation />}
              />
              <Route path="/purchase/:result" element={<PaymentOutcome />} />
              <Route
                element={
                  <ProtectedRoute
                    token={user?.plan !== "NP" && token}
                    redirectPath="/pricing"
                  />
                }
              >
                <Route path="/dashboard">
                  <Route index element={<RulesDashboard />} />
                  <Route
                    path=":architectural-guidelines"
                    element={
                      <ArchitecturalGuidelines token={token} user={user} />
                    }
                  />
                  <Route
                    path=":community-rules"
                    element={<CommunityRules token={token} user={user} />}
                  />
                </Route>
                <Route path="/preview-selection" element={<SavedRules />} />
                <Route
                  path="/my-account"
                  element={<MyAccount token={token} />}
                />
                <Route
                  path="/compare-user-rule"
                  element={<CompareUserRule />}
                />
              </Route>
              {/* <Route path="/google-oauth" element={<GoogleOAuth />} />
              <Route path="/facebook-oauth" element={<FacebookOAuth />} /> */}
            </Routes>
          </ScrollToTop>
          <Footer />
        </main>
      )}
    </AuthContext.Provider>
  );
};

export default App;

import React, { useCallback, useEffect, useState } from "react";
//import { ArchitecturalSavedRules as response } from "../../../util/data/saved-rules/architectural-saved-rules";
import PaperAirplane from "../../../assets/SVG/paper-airplane";
import SubscribeNow from "../../../components/SubscribeNow/SubscribeNow";
import axios from "../../../util/helpers/axios";
import { useAuth } from "../../../CustomHook/auth-hook";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { api } from "../../../util/helpers/URL";
import ArrowLeft from "../../../assets/SVG/arrow-left";
import { useNavigate } from "react-router-dom";

const SavedRules = () => {
  const { token } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [savedData, setSavedData] = useState({ data: {}, mappingData: {} });

  const previewSelectionAPI = async (token) => {
    try {
      const response = await axios.get(
        `getSelectedRule/?hoarTemplatetoken=${token}`
      );
      if (response.statusText === "OK") {
        console.log(response, "preview selection response");
        setSavedData({
          data: { ...response?.data?.data },
          mappingData: { ...response?.data?.mappingData },
        });
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  console.log(savedData, "savedData");

  useEffect(() => {
    if (token) {
      previewSelectionAPI(token);
    }
  }, [token]);

  const [savedAR, setSavedAR] = useState([]);
  const [savedRR, setSavedRR] = useState([]);
  const [savedARR, setSaveARR] = useState([]);

  const mapping = useCallback(() => {
    //console.log(response, "response");
    let SAVED_AR = [];
    let SAVED_RR = [];
    let SAVED_ARR = [];

    let ruleIssueMapping = {};
    for (let category in savedData?.mappingData?.AR) {
      //console.log(category);
      for (let issue of savedData?.mappingData?.AR[category]) {
        //console.log(issue);
        ruleIssueMapping[issue] = "AR";
      }
    }

    for (let category in savedData?.mappingData?.RR) {
      //console.log(category);
      for (let issue of savedData?.mappingData?.RR[category]) {
        //console.log(issue);
        ruleIssueMapping[issue] = "RR";
      }
    }

    for (let category in savedData?.mappingData?.ARR) {
      //console.log(category);
      for (let issue of savedData?.mappingData?.ARR[category]) {
        //console.log(issue);
        ruleIssueMapping[issue] = "ARR";
      }
    }

    for (let ruleType in savedData?.data) {
      let ruleList = [];
      if (ruleIssueMapping[ruleType] === "AR") {
        for (let rules in savedData?.data[ruleType]) {
          ruleList.push(savedData?.data[ruleType][rules]);
        }
        if (ruleList.length > 0) {
          SAVED_AR.push(
            <div className="w-full shadow-lg bg-white rounded-md my-3 p-5">
              <h3 className="text-lg sm:text-xl lg:text-2xl text-secondary-blue font-semibold mb-3">
                {ruleType}
              </h3>
              {ruleList.map((rule) => (
                <div className="flex items-start space-x-3 ml-5 my-1.5">
                  <PaperAirplane className="h-3 w-3 text-gray-800 mt-1 sm:mt-1.5" />
                  <p
                    className="w-[90%] text-sm sm:text-base font-medium text-gray-800"
                    key={rule?.rule}
                  >
                    {rule?.rule}{" "}
                    <span className="text-red-500">
                      {!rule?.submittal_required && `(No Application Required)`}
                    </span>
                  </p>
                </div>
              ))}
            </div>
          );
        }
      } else if (ruleIssueMapping[ruleType] === "RR") {
        for (let rules in savedData?.data[ruleType]) {
          ruleList.push(savedData?.data[ruleType][rules]);
        }
        if (ruleList.length > 0) {
          SAVED_RR.push(
            <div className="w-full shadow-lg bg-white rounded-md my-3 p-5">
              <h3 className="text-lg sm:text-xl lg:text-2xl text-secondary-blue font-semibold mb-3">
                {ruleType}
              </h3>
              {ruleList.map((rule) => (
                <div className="flex items-start space-x-3 ml-5 my-1.5">
                  <PaperAirplane className="h-3 w-3 text-gray-800 mt-1 sm:mt-1.5" />
                  <p
                    className="w-[90%] text-sm sm:text-base font-medium text-gray-800"
                    key={rule?.rule}
                  >
                    {rule?.rule}{" "}
                    <span className="text-red-500">
                      {!rule?.submittal_required && `(No Submittal Required)`}
                    </span>
                  </p>
                </div>
              ))}
            </div>
          );
        }
      } else if (ruleIssueMapping[ruleType] === "ARR") {
        for (let rules in savedData?.data[ruleType]) {
          ruleList.push(savedData?.data[ruleType][rules]);
        }
        if (ruleList.length > 0) {
          SAVED_ARR.push(
            <div className="w-full shadow-lg bg-white rounded-md my-3 p-5">
              <h3 className="text-lg sm:text-xl lg:text-2xl text-secondary-blue font-semibold mb-3">
                {ruleType}
              </h3>
              {ruleList.map((rule) => (
                <div className="flex items-start space-x-3 ml-5 my-1.5">
                  <PaperAirplane className="h-3 w-3 text-gray-800 mt-1 sm:mt-1.5" />
                  <p
                    className="w-[90%] text-sm sm:text-base font-medium text-gray-800"
                    key={rule?.rule}
                  >
                    {rule?.rule}{" "}
                    <span className="text-red-500">
                      {!rule?.submittal_required && `(No Application Required)`}
                    </span>
                  </p>
                </div>
              ))}
            </div>
          );
        }
      }
    }

    setSavedAR([...SAVED_AR]);
    setSavedRR([...SAVED_RR]);
    setSaveARR([...SAVED_ARR]);
  }, [
    savedData?.data,
    savedData?.mappingData?.AR,
    savedData?.mappingData?.RR,
    savedData?.mappingData?.ARR,
  ]);

  useEffect(() => {
    mapping();
  }, [mapping]);

  return (
    <div className="max-w-screen min-h-screen overflow-y-hidden">
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <section
          style={{ backgroundImage: "linear-gradient(#0a285e, #3dbc93)" }}
          className="relative bg-primary-blue flex flex-col items-center pt-7 xl:pt-10 xl:pb-20"
        >
          <ArrowLeft
            onClick={() => navigate(-1)}
            className="text-white w-6 h-6 lg:h-10 lg:w-10 absolute left-5 lg:left-10 top-0 sm:top-7 cursor-pointer"
          />
          <h1 className="font-medium text-2xl text-center lg:text-3xl xl:text-4xl text-white">
            Saved Rules
          </h1>
          {savedAR.length === 0 && savedRR.length === 0 && (
            <h2 className="text-red-500 text-lg xl:text-3xl mt-5 xl:mt-10">
              No Saved Rules Found
            </h2>
          )}
          <div
            id="scrollbar"
            className="lg:mt-20 lg:w-[90%] xl:w-[60%] px-5 py-16 lg:py-0 flex flex-col items-center lg:h-[500px] lg:overflow-y-scroll lg:scroll-smooth"
          >
            <div>
              {savedAR.length > 0 && (
                <div>
                  <h2 className="text-xl text-left sm:text-2xl lg:text-3xl text-white">
                    Rules & Regulations
                  </h2>
                  {savedAR.length > 0 &&
                    savedAR.map((rule, index) => (
                      <div className="flex flex-col items-center" key={index}>
                        {rule}
                      </div>
                    ))}
                </div>
              )}
            </div>
            <div>
              {savedRR.length > 0 && (
                <div>
                  <h2 className="mt-16 text-xl text-left sm:text-2xl lg:text-3xl text-white">
                    Rules & Regulations
                  </h2>
                  {savedRR.length > 0 &&
                    savedRR.map((rule, index) => (
                      <div className="flex flex-col items-center" key={index}>
                        {rule}
                      </div>
                    ))}
                </div>
              )}
            </div>
            <div>
              {savedARR.length > 0 && (
                <div>
                  <h2 className="mt-16 text-xl text-left sm:text-2xl lg:text-3xl text-white">
                    Rules & Regulations
                  </h2>
                  {savedARR.length > 0 &&
                    savedARR.map((rule, index) => (
                      <div className="flex flex-col items-center" key={index}>
                        {rule}
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
          <div className="w-full lg:w-[90%] xl:w-[65%] flex flex-col space-y-5 lg:space-y-0 lg:flex-row items-center justify-center sm:justify-between p-5 px-10">
            <button
              onClick={() =>
                window.open(
                  `${api}/generatedocxMerged/?hoarTemplatetoken=${token}`,
                  "_blank"
                )
              }
              className="lg:transform lg:transition lg:duration-500 lg:hover:scale-110 w-[90%] sm:max-w-fit bg-secondary-blue rounded-md text-white font-medium px-10 py-4 text-sm lg:text-base xl:text-lg border-2 border-secondary-blue shadow-md"
            >
              See Your Draft In A Combined Word Document <br />{" "}
              <p className="text-[10px] xl:text-xs font-normal">
                (Don't Worry, You Can Come Back Here To Finish After)
              </p>
            </button>
            <button
              onClick={() =>
                window.open(
                  `${api}/generatedocx/?hoarTemplatetoken=${token}`,
                  "_blank"
                )
              }
              className="lg:transform lg:transition lg:duration-500 lg:hover:scale-110 w-[90%] sm:max-w-fit bg-secondary-blue rounded-md text-white font-medium px-10 py-4 text-sm lg:text-base xl:text-lg border-2 border-secondary-blue shadow-md"
            >
              See Your Draft In A Word Document <br />{" "}
              <p className="text-[10px] xl:text-xs font-normal">
                (Don't Worry, You Can Come Back Here To Finish After)
              </p>
            </button>
          </div>
        </section>
      )}
      {/*********************** SUBSCRIBE NOW COMPONENT*********************************************************/}
      <SubscribeNow />
    </div>
  );
};

export default SavedRules;

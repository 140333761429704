import React from "react";
import ChevronDown from "./chevron-down";

const ScrollDownButton = () => {
  return (
    <div className="shadow-lg bg-ternary-blue cursor-pointer flex items-center justify-center w-10 h-10 border border-ternary-blue rounded-full">
      <ChevronDown className="w-6 h-6 text-white" />
    </div>
  );
};

export default ScrollDownButton;

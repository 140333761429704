import React, { useCallback, useEffect, useState } from "react";
import axios from "../../../util/helpers/axios";
import { useAuth } from "../../../CustomHook/auth-hook";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";

export const CompareUserRule = () => {
  const { token, user } = useAuth();

  const [subCategories, setSubCategories] = useState([]);
  const [categoryRules, setCategoryRules] = useState([]);
  const [selectedRuleTopic, setSelectedRuleTopic] = useState({
    id: subCategories[0]?.id,
    title: "",
  });
  const [selectedIssue, setSelectedIssue] = useState({
    id: categoryRules[0]?.id,
    title: categoryRules[0]?.rule,
  });
  const [teamDetails, setTeamDetails] = useState();
  const [isLoading, setIsLoading] = useState("rule-topics");

  const getTeamStatusAPI = useCallback(async (ruleId, token) => {
    setIsLoading("rule-status");
    if (!token || !ruleId) {
      return;
    }
    try {
      const response = await axios.get(
        `subAou_rule/?ruleId=${ruleId}&hoarTemplatetoken=${token}`
      );
      if (response) {
        console.log(response?.data, "sub aou rule api response");
        setTeamDetails({ ...response?.data?.data });
      }
      setIsLoading("");
    } catch (err) {
      console.log(err);
      setIsLoading("");
    }
  }, []);

  const getSubCategoryRulesAPI = useCallback(
    async (issueId, token, title = null, callback = true) => {
      if (!issueId || !token) {
        return;
      }
      setIsLoading("issues");
      try {
        const response = await axios.get(
          `rules/?issue_id=${issueId}&hoarTemplatetoken=${token}`
        );
        console.log(response, "Sub Category Rules Response");
        if (response?.statusText === "OK") {
          setCategoryRules([...response?.data?.ruleList]);
          if (callback) {
            getTeamStatusAPI(response?.data?.ruleList[0]?.id, token);
            setSelectedIssue({
              id: response?.data?.ruleList[0]?.id,
              title: response?.data?.ruleList[0]?.rule,
            });
          }
        }
        setIsLoading("");
      } catch (err) {
        console.log(err);
        setIsLoading("");
      }
    },
    [getTeamStatusAPI]
  );

  const getSubCategoriesAPI = useCallback(
    async (issueId = null, title = null, callback = true) => {
      if (!token || !user) {
        return;
      }

      setIsLoading("rule-topics");

      try {
        const response = await axios.get(
          `issues_list/?category=AR&hoarTemplatetoken=${token}&plan=${user?.plan}`
        );
        console.log(response, "Response Sub Category");
        if (response?.statusText === "OK") {
          console.log(response.data.issueList, "issue list response api");
          setSubCategories([...response?.data?.issueList]);
          if (callback) {
            getSubCategoryRulesAPI(response?.data?.issueList[0]?.id, token);
            setSelectedRuleTopic({
              id: issueId ? issueId : response?.data?.issueList[0]?.id,
              title: title ? title : response?.data?.issueList[0]?.title,
            });
          }
        }
        setIsLoading("");
      } catch (err) {
        console.log(err);
        setIsLoading("");
      }
    },
    [token, user, getSubCategoryRulesAPI]
  );

  useEffect(() => {
    getSubCategoriesAPI();
  }, [getSubCategoriesAPI]);

  console.log(selectedRuleTopic, "selectedRuleTopic");
  console.log(selectedIssue, "selectedIssue");
  console.log(teamDetails, "teamDetails");

  return (
    <div className="max-w-screen p-10 bg-white">
      <h1 className="text-lg sm:text-3xl lg:text-5xl text-secondary-blue font-medium">
        Rules Overview
      </h1>
      <div className="my-10 w-full flex justify-between">
        <div className="w-[25%]">
          <h2 className="text-secondary-blue text-lg xl:text-2xl font-semibold ">
            Select The Rule Topic
          </h2>
          {(!subCategories || subCategories?.length === 0) &&
            isLoading !== "rule-topics" && <p>No rule topics available.</p>}
          {isLoading === "rule-topics" && (
            <div className="-mt-72 w-1/2">
              <LoadingSpinner />
            </div>
          )}
          {isLoading !== "rule-topics" &&
            subCategories &&
            subCategories.length > 0 && (
              <div className="my-5 h-[55vh] scrollbar overflow-y-auto">
                {subCategories.map((topic) => (
                  <p
                    key={topic?.id}
                    onClick={() => {
                      setSelectedRuleTopic({
                        id: topic?.id,
                        title: topic?.title,
                      });

                      getSubCategoryRulesAPI(topic?.id, token);
                    }}
                    className={`rounded-md w-full py-2 pl-3 pr-5 text-left text-xs xl:text-xl font-medium my-2 hover:text-white cursor-pointer hover:bg-secondary-blue ${
                      selectedRuleTopic?.id === topic?.id
                        ? "bg-secondary-blue text-white"
                        : "text-secondary-blue bg-white"
                    }`}
                  >
                    {topic?.title}
                  </p>
                ))}
              </div>
            )}
        </div>
        <div className="w-[25%]">
          <h2 className="text-secondary-blue text-lg xl:text-2xl font-semibold ">
            Select The Issue
          </h2>
          {(!categoryRules || categoryRules?.length === 0) &&
            isLoading !== "issues" &&
            isLoading !== "rule-topics" && (
              <p>No issue available for the selected topic.</p>
            )}
          {isLoading === "issues" && (
            <div className="-mt-72 w-1/2">
              <LoadingSpinner />
            </div>
          )}
          {isLoading !== "issues" &&
            categoryRules &&
            categoryRules.length > 0 && (
              <div className="my-5 h-[55vh] scrollbar overflow-y-auto">
                {categoryRules.map((rule) => (
                  <p
                    key={rule?.id}
                    className={`my-2 py-2 pl-3 pr-5 rounded-md text-xs lg:text-sm font-medium hover:text-white cursor-pointer hover:bg-secondary-blue  ${
                      selectedIssue?.id === rule?.id
                        ? "bg-secondary-blue text-white"
                        : "bg-white text-secondary-blue"
                    }`}
                    onClick={() => {
                      setSelectedIssue({
                        id: rule?.id,
                        title: rule?.rule,
                      });
                      getTeamStatusAPI(rule?.id, token);
                    }}
                  >
                    {rule?.rule}
                  </p>
                ))}
              </div>
            )}
        </div>
        <div className="w-[25%]">
          <h2 className="text-secondary-blue text-lg xl:text-2xl font-semibold ">
            Rule Status
          </h2>
          {isLoading === "rule-status" && (
            <div className="-mt-72 w-1/2">
              <LoadingSpinner />
            </div>
          )}
          {isLoading !== "rule-status" && teamDetails && (
            <div className="my-5 text-secondary-blue font-semibold">
              <div className="flex items-center space-x-3">
                <p>AOU</p>
                <p className="text-primary-green">
                  {teamDetails?.Aou ? "(Subscribed)" : "(Not Subscribed)"}
                </p>
              </div>
              {teamDetails?.subAouRuleStatus &&
                teamDetails?.subAouRuleStatus?.length > 0 &&
                teamDetails?.subAouRuleStatus?.map((member) => (
                  <div
                    key={member?.subAouId}
                    className="flex items-center space-x-3"
                  >
                    <p>{member?.subAouName}</p>
                    <p className="text-primary-green">
                      {member?.ruleStatus ? "(Subscribed)" : "(Not Subscribed)"}
                    </p>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompareUserRule;

import React from "react";
import classes from "./modal.module.css";
import Cross from "../../../../assets/SVG/cross";
import { allRuleTopics } from "../../../../util/data/all-rule-topics/all-rule-topics";

const SampleRulesModal = ({ setOpen }) => {
  return (
    <div className={classes.modalWrapper}>
      <div className={`${classes.modal} scrollbar`}>
        <div className="relative px-7 py-10">
          <Cross
            onClick={() => setOpen(false)}
            className="h-6 w-6 text-ternary-blue hover:text-red-500 cursor-pointer absolute right-4 top-3"
          />
          <div className="flex flex-col items-center">
            <h1 className="text-4xl font-semibold text-primary-green">
              Here’s a sample of the rule topics that we cover for you
            </h1>
            <div className="grid grid-cols-2 mt-7 mb-5">
              {allRuleTopics.map((topic, index) => (
                <ul key={index} className="list-disc">
                  <li className="text-xl text-primary-blue font-medium mb-1">
                    {topic}
                  </li>
                </ul>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SampleRulesModal;

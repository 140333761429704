import React from "react";

const ShoppingBag = ({ className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 700 700"
      className={className ? className : "h-5 w-5"}
      {...rest}
    >
      <path d="M576.8 193.2c-2.8-14-14-25.199-28-25.199h-58.801v-28c0-78.398-61.602-140-140-140s-140 61.602-140 140v28h-58.801c-14 0-25.199 11.199-28 25.199l-53.199 336c-2.8 16.801 11.199 30.801 28 30.801h504c16.801 0 30.801-14 28-30.801zM266 140.001c0-47.602 36.398-84 84-84s84 36.398 84 84v28H266zm215.6 159.6l-168 168c-11.199 11.199-28 11.199-39.199 0l-84-84c-11.199-11.199-11.199-28 0-39.199 11.199-11.199 28-11.199 39.199 0l64.398 64.398 148.4-148.4c11.199-11.199 28-11.199 39.199 0 11.203 11.199 11.203 27.996.004 39.199z"></path>
    </svg>
  );
};

export default ShoppingBag;

import React from "react";

const RulesOptionsCard = ({ Icon, description, ruleNo }) => {
  return (
    <div className="w-full sm:w-[450px] h-[100px] flex items-center space-x-2 bg-white p-2 sm:p-5 shadow-xl rounded-md">
      <span className="text-primary-blue text-base font-semibold">
        {ruleNo}.
      </span>
      <div className="bg-blue-50 p-2 rounded-full shadow-sm">
        <Icon className="h-7 w-7 sm:h-10 sm:w-10 text-secondary-blue" />
      </div>
      <p className="font-semibold text-sm sm:text-base text-primary-blue">
        {description}
      </p>
    </div>
  );
};

export default RulesOptionsCard;

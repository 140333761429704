import React, { useState, useRef } from "react";
import Select from "react-select";
import cogoToast from "cogo-toast";
import axios from "../../util/helpers/axios";
import Pencil from "../../assets/SVG/pencil";
import Cross from "../../assets/SVG/cross";
import { useNavigate } from "react-router-dom";
import SubscribeNow from "../../components/SubscribeNow/SubscribeNow";

const categoryOptions = [
  {
    label: "Architectural Guidelines",
    value: "AR",
  },
  {
    label: "Rules & Regulations",
    value: "RR",
  },
];

const customStyle = {
  option: (provided, state) => {
    return {
      ...provided,
      color: state.isSelected ? "white" : "#28a745",
    };
  },
};
const CreateIssueForm = () => {
  const ruleNameRef = useRef(null);
  const [issueData, setIssueData] = useState({
    issueName: "",
    category: "",
    categoryAlias: "",
    rules: [],
    showEditableView: false,
    modifyRuleMode: { active: false, index: null },
  });
  const navigate = useNavigate();

  const handleCategoryChange = (data) => {
    setIssueData((prev) => {
      return { ...prev, category: data.value, categoryAlias: data.label };
    });
  };

  const handleIssueNameChange = (e) => {
    const issueName = e.target.value;
    let showEditableView = false;
    if (issueName.trim() !== "") {
      showEditableView = true;
    }
    setIssueData((prev) => {
      return {
        ...prev,
        issueName: issueName,
        showEditableView: showEditableView,
      };
    });
  };
  const addRules = () => {
    const rule = ruleNameRef.current.value.trim();
    if (issueData.issueName === "") {
      cogoToast.error("Please add an Issue Name to continue", {
        hideAfter: 10,
      });
      return;
    }
    if (rule === "") {
      cogoToast.error("Please Enter a Rule to add it", {
        hideAfter: 10,
      });
      return;
    }
    setIssueData({
      ...issueData,
      rules: [...issueData.rules, rule],
    });
    ruleNameRef.current.value = "";
  };

  const editSavedRule = () => {
    const rule = ruleNameRef.current.value.trim();
    if (issueData.issueName === "") {
      cogoToast.error("Please add an Issue Name to continue", {
        hideAfter: 10,
      });
      return;
    }
    if (rule === "") {
      cogoToast.error("Please Enter a Rule to add it", {
        hideAfter: 10,
      });
      return;
    }
    setIssueData((prev) => {
      prev.rules[prev.modifyRuleMode.index] = rule;
      prev.modifyRuleMode = { active: false, index: null };
      return { ...prev };
    });
    ruleNameRef.current.value = "";
  };

  const removeRule = (e, name) => {
    setIssueData((prev) => {
      const rules = prev.rules.filter((rule) => rule !== name);
      return {
        ...prev,
        rules: rules,
        modifyRuleMode: { active: false, index: null },
      };
    });
    ruleNameRef.current.value = "";
  };

  const editRule = (e, prevRule) => {
    setIssueData((prev) => {
      const ruleIndex = prev.rules.findIndex((val) => val === prevRule);
      ruleNameRef.current.value = prev.rules[ruleIndex];
      return { ...prev, modifyRuleMode: { active: true, index: ruleIndex } };
    });
  };

  const submitCustomIssue = async () => {
    if (issueData.issueName === "") {
      cogoToast.error("Please Enter an Issue Name", {
        hideAfter: 10,
      });
      return;
    }
    if (issueData.category === "") {
      cogoToast.error("Please choose a category", {
        hideAfter: 10,
      });
      return;
    }
    if (issueData.rules.length === 0) {
      cogoToast.error("Please add atleast one Rule", {
        hideAfter: 10,
      });
      return;
    }
    const formData = new FormData();
    formData.append("title", issueData.issueName);
    formData.append("category", issueData.category);
    issueData.rules.forEach((rule) => {
      formData.append("rules", rule);
      formData.append("state", 0); // illogical but req. at backend
    });
    formData.append("hoarTemplatetoken", localStorage.getItem("token"));

    try {
      const { data } = await axios.post("issues_list/", formData);
      if (data.status === 1) {
        cogoToast.success(data.message, {
          hideAfter: 10,
        });
        setTimeout(() => {
          navigate("/dashboard");
        }, 1000);
      } else {
        cogoToast.error(data.message, {
          hideAfter: 10,
        });
      }
    } catch {
      cogoToast.error("Unknown Error Occured", {
        hideAfter: 10,
      });
    }
  };

  return (
    <div>
      <div className="px-10 pt-10 pb-20">
        <h1 className="text-5xl text-primary-green font-semibold mb-10">
          Create New Issue
        </h1>
        <div className="flex">
          <div className="w-1/2 flex flex-col space-y-5">
            <input
              type="text"
              placeholder="Enter the Rule Topic"
              onChange={handleIssueNameChange}
              className="w-[80%] focus:ring-2  focus:ring-blue-500 focus:outline-none text-primary-blue p-2 border-[1.5px] border-gray-300 rounded-md"
            />
            <div className="w-[80%]">
              <Select
                options={categoryOptions}
                isSearchable={true}
                styles={customStyle}
                placeholder="Select Category"
                onChange={handleCategoryChange}
              />
            </div>
            <div className="flex items-center space-x-5">
              <input
                type="text"
                placeholder="Enter Your First Rule"
                ref={ruleNameRef}
                className="w-[80%] focus:ring-2 focus:ring-blue-500 focus:outline-none text-primary-blue p-2 border-[1.5px] border-gray-300 rounded-md"
              />
              {issueData.modifyRuleMode.active ? (
                <button
                  onClick={editSavedRule}
                  className="bg-primary-green p-2.5 rounded-md text-white text-sm font-medium"
                >
                  Save Rule
                </button>
              ) : (
                <button
                  className="bg-ternary-blue p-1.5 rounded-md text-white text-sm font-medium"
                  onClick={addRules}
                >
                  Add Rule
                </button>
              )}
            </div>
          </div>
          <div className="w-1/2">
            {!issueData.showEditableView && (
              <div className="flex flex-col space-y-3 text-xl font-medium text-ternary-blue">
                <p className="text-3xl font-semibold text-primary-green">
                  Create Your Customized Rules:
                </p>
                <span>1. Enter the rule topic</span>
                <span>2. Select a Category</span>
                <span>
                  3. Enter your first rule in that Category and click Add Rule+
                  to save
                </span>
                <span>
                  4. Enter as many rules as are needed under this Rule Topic
                </span>
                <span>5. Click on Submit My Custom Rule!</span>
              </div>
            )}
            {issueData.showEditableView && (
              <div className="text-xl text-ternary-blue font-medium">
                <p>
                  You are creating Rules for Issue:&nbsp;
                  <span style={{ color: "#28a745" }}>
                    {issueData.issueName}
                  </span>
                </p>
                <p>
                  Rules Created:&nbsp;{" "}
                  <span style={{ color: "#28a745" }}>
                    {issueData.rules.length}
                  </span>
                </p>
                <div className="my-3 flex items-center flex-wrap">
                  {issueData.rules.map((rule) => {
                    return (
                      <div
                        className="mr-5 relative text-white text-sm font-medium flex items-center bg-green-600 max-w-fit px-5 py-1 rounded-md"
                        key={rule}
                      >
                        <Cross
                          className="cursor-pointer absolute -right-1 -top-1 h-4 w-4 bg-secondary-blue text-white rounded-full p-1"
                          onClick={(e) => removeRule(e, rule)}
                        />
                        <Pencil
                          className="cursor-pointer bg-secondary-blue p-1 rounded-full text-white absolute -left-0.5 -top-1 h-4 w-4"
                          onClick={(e) => editRule(e, rule)}
                        />
                        {rule}
                      </div>
                    );
                  })}
                </div>
                {issueData.rules.length !== 0 && (
                  <p className="flex items-center">
                    If you want to delete any Rule click on the&nbsp;{" "}
                    <Cross className="cursor-pointer  h-5 w-5 bg-secondary-blue text-white rounded-full p-1 mr-1" />
                    Icon on top-right corner of Rule Tag.
                  </p>
                )}
                {issueData.rules.length !== 0 && (
                  <p>
                    To edit one of your rules, simply hover over the top left
                    corner.
                  </p>
                )}
                <p>
                  Click &nbsp;
                  <span style={{ color: "#28a745" }}>
                    Submit My Custom Rule
                  </span>
                  &nbsp; to save your Rule Topic and Rules.
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="text-center mt-16">
          <button
            className="bg-primary-green text-white px-3 py-1.5 font-medium text-lg rounded-lg"
            onClick={submitCustomIssue}
          >
            Submit My Custom Rule
          </button>
        </div>
      </div>
      {/*********************** SUBSCRIBE NOW COMPONENT*********************************************************/}
      <SubscribeNow />
    </div>
  );
};

export default CreateIssueForm;

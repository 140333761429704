import React, { useState } from "react";
import classes from "./modal.module.css";
import Cross from "../../assets/SVG/cross";
import Dropdown from "../Dropdown/Dropdown";
import Button from "../Button/Button";
import cogoToast from "cogo-toast";
import { useAuth } from "../../CustomHook/auth-hook";
import axios from "../../util/helpers/axios";

const ChangeAouModal = ({ teamMembers = [], setOpen }) => {
  const [subAou, setSubAou] = useState();
  const { token } = useAuth();

  const changeSubAouAPI = async () => {
    setOpen(false);
    if (!subAou || !token) {
      return;
    }
    const formData = new FormData();
    formData.append("subAouSelect", subAou);
    formData.append("hoarTemplatetoken", token);
    try {
      const response = await axios.post("changeSubAou", formData);
      if (response) {
        cogoToast.success(
          response?.data?.message
            ? response.data.message
            : "Something went wrong. Please try again.",
          { hideAfter: 10 }
        );
      }
    } catch (err) {
      console.log(err);
      cogoToast.error(
        err?.response?.data?.message
          ? err.response.data.message
          : "Something went wrong. Please try again.",
        { hideAfter: 10 }
      );
    }
  };

  return (
    <div className={classes.modalWrapper}>
      <div className={classes.modal}>
        <div className="px-10 py-5 relative">
          <h1 className="text-3xl text-center font-semibold text-secondary-blue">
            Update AOU
          </h1>
          <Cross
            onClick={() => setOpen(false)}
            className="absolute right-5 top-6 h-7 w-7 text-secondary-blue cursor-pointer"
          />
          <div className="mt-10">
            <p className="mb-1 sm:mb-3 text-base sm:text-lg text-primary-blue  leading-[27px] font-normal">
              Select Sub Aou
            </p>
            <Dropdown
              dropdownList={teamMembers}
              placeholder="Select Sub AOU"
              dropdownCallback={(value) => {
                setSubAou(value);
              }}
              defaultValue={""}
            />
          </div>
          <Button
            onClick={changeSubAouAPI}
            innerText="Update Sub Aou"
            type="submit"
            extraClass="w-1/2 mt-10 mx-auto text-center h-[45px] text-sm sm:text-base xl:text-lg text-white font-medium rounded-full bg-secondary-blue"
          />
        </div>
      </div>
    </div>
  );
};

export default ChangeAouModal;

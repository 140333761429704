import React, { useContext, useState } from "react";
import Rocket from "../../assets/SVG/rocket";
import Fire from "../../assets/SVG/fire";
import Trophy from "../../assets/SVG/trophy";
import PricingCard from "./components/PricingCard/PricingCard";
import ConfirmPurchaseModal from "./components/ConfirmPurchaseModal/ConfirmPurchaseModal";
import axios from "../../util/helpers/axios";
import cogoToast from "cogo-toast";
import { useAuth } from "../../CustomHook/auth-hook";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../Context/auth-context";
import UpgradePlanModal from "../../components/UpgradePlanModal/UpgradePlanModal";

const Pricing = () => {
  const [isConfirmPurchaseModalOpen, setIsConfirmPurchaseModalOpen] =
    useState(false);
  const [selectedPricingPlan, setSelectedPricingPlan] = useState({
    planTypeId: "",
    planType: "",
    amountPayable: 0,
    autoRenewCharges: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [openUpgradePlanModal, setOpenUpgradePlanModal] = useState(false);

  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const { token, user } = useAuth();

  const selectPlanCallback = (
    planTypeId,
    planType,
    amountPayable,
    autoRenewCharges
  ) => {
    if (!token) {
      navigate("/login");
      return;
    }
    setIsConfirmPurchaseModalOpen(true);
    setSelectedPricingPlan({
      planTypeId: planTypeId,
      planType: planType,
      amountPayable: amountPayable,
      autoRenewCharges: autoRenewCharges,
    });
  };

  const getUserInfo = async (token) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`userDetails/${token}`);
      console.log(response.data, "Response user info");
      setIsLoading(false);
      if (response?.data) {
        return response.data;
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const purchasePlanAPI = async (planTypeId, autoRenewal = false) => {
    setIsLoading(true);
    if (!token) {
      navigate("/login");
      return;
    }
    try {
      const response = await axios.post("payment/purchasePlan", {
        token: token,
        planRequested: planTypeId,
        autoRenewal: autoRenewal,
      });
      console.log(response, "purchase plan response");
      if (response?.data?.success) {
        setIsConfirmPurchaseModalOpen(false);
        //console.log(response?.data, "redirect link");
        if (response?.data?.redirect) {
          window.location.replace(response?.data?.redirect);
        } else {
          const userData = await getUserInfo(token);
          console.log(userData, "userData");

          auth.login(userData, token);
          navigate("/dashboard");
        }
      } else if (response?.data?.success === false) {
        cogoToast.error(
          response?.data?.message
            ? response.data.message
            : "Something went wrong. Please try again.",
          { hideAfter: 10 }
        );
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err.response);
      cogoToast.error(
        err?.response?.data?.message
          ? err.response.data.message
          : "Something went wrong. Please try again.",
        { hideAfter: 10 }
      );
      setIsLoading(false);
    }
  };

  console.log(user, "user");

  return (
    <div
      style={{ backgroundImage: "linear-gradient(#0a285e, #3dbc93)" }}
      className="max-w-screen min-h-screen relative"
    >
      {isConfirmPurchaseModalOpen && (
        <ConfirmPurchaseModal
          planTypeId={selectedPricingPlan.planTypeId}
          planType={selectedPricingPlan.planType}
          amountPayable={selectedPricingPlan.amountPayable}
          autoRenewCharges={selectedPricingPlan.autoRenewCharges}
          setOpenModal={setIsConfirmPurchaseModalOpen}
          purchasePlanAPI={purchasePlanAPI}
          isLoading={isLoading}
          disabled={isLoading}
        />
      )}
      {openUpgradePlanModal && (
        <UpgradePlanModal
          extraPrice={user?.availedRenewal ? 50 : 30}
          upgradeToPlan="Community User"
          setOpen={setOpenUpgradePlanModal}
        />
      )}
      <div className="flex flex-col items-center pt-16 pb-20">
        <h1 className="text-xl text-center lg:text-left sm:text-3xl lg:text-4xl xl:text-5xl text-white">
          Select A Plan That Works For You!
        </h1>
        <div className="h-full flex flex-col lg:flex-row items-center space-y-10 lg:space-y-0 lg:space-x-5 xl:space-x-7 2xl:space-x-10 mt-10 lg:mt-20">
          <PricingCard
            Icon={
              <Rocket className="w-10 h-10 sm:w-12 sm:h-12 text-secondary-blue" />
            }
            planTypeId="TR"
            planType="FREE USER"
            heading="Take Us For A Test Spin"
            description={
              <p className="my-3 sm:my-5 text-center text-base sm:text-xl lg:text-base 2xl:text-lg text-gray-600">
                Create your own{" "}
                <span className="text-primary-green font-medium">4-Rule </span>
                document with two{" "}
                <span className="text-primary-green font-medium">
                  Architectural Guidelines & Standards Plus Two Rules &
                  Regulations
                </span>{" "}
                from our master library and see just how quick and easy it can
                be.
              </p>
            }
            price={0}
            priceUpdateType="Monthly"
            purchaseButtonText={
              user?.trialRedeemed
                ? "TRIAL REDEEMED"
                : user?.plan === "NP"
                ? "PURCHASE PLAN"
                : user?.plan === "TR"
                ? "CURRENT ACTIVE PLAN"
                : user?.plan === "SU"
                ? "PLAN REDEEMED"
                : "FREE TRIAL"
            }
            showInvoiceMyAssociationButton={false}
            purchasePlanAPI={purchasePlanAPI}
            isLoading={isLoading}
            disabled={
              isLoading ||
              user?.plan === "TR" ||
              user?.plan === "SU" ||
              user?.plan === "CU"
            }
            currentActivePlan={user?.plan === "TR"}
          />
          <PricingCard
            Icon={
              <Fire className="w-10 h-10 sm:w-12 sm:h-12 text-secondary-blue" />
            }
            planTypeId="SU"
            planType="SINGLE USER"
            heading="Annual Membership"
            description={
              <p className="my-3 sm:my-5 text-center text-base sm:text-xl lg:text-base 2xl:text-lg text-gray-600">
                A single user can{" "}
                <span className="text-primary-green font-medium">
                  Create, Modify and Amend{" "}
                </span>
                your{" "}
                <span className="text-primary-green font-medium">
                  Architectural Guidelines & Standards Plus Your Rules &
                  Regulations
                </span>{" "}
                keeping them updated and current for the next twelve months
              </p>
            }
            price={319}
            priceUpdateType="First Year"
            renewalPrice={79.0}
            handleSelectPlanCallback={selectPlanCallback}
            purchaseButtonText={
              user?.plan === "NP" || user?.plan === "TR"
                ? "PURCHASE PLAN"
                : user?.plan === "SU"
                ? "CURRENT ACTIVE PLAN"
                : user?.plan === "CU"
                ? "PLAN REDEEMED"
                : "PURCHASE PLAN"
            }
            currentActivePlan={user?.plan === "SU"}
            disabled={user?.plan === "SU" || user?.plan === "CU"}
            showInvoiceMyAssociationButton={
              user === null || user?.plan === "NP" || user?.plan === "TR"
            }
          />
          <PricingCard
            Icon={
              <Trophy className="w-10 h-10 sm:w-12 sm:h-12 text-secondary-blue" />
            }
            planTypeId="CU"
            planType="COMMITTEE USER"
            heading="Up To Six Users"
            description={
              <p className="my-3 sm:my-5 text-center text-base sm:text-xl lg:text-base 2xl:text-lg text-gray-600">
                Up to{" "}
                <span className="text-primary-green font-medium">Six</span>{" "}
                users can{" "}
                <span className="text-primary-green font-medium">
                  Create, Modify and Amend{" "}
                </span>
                your{" "}
                <span className="text-primary-green font-medium">
                  Architectural Guidelines & Standards Plus Your Rules &
                  Regulations
                </span>{" "}
                keeping them updated and current for the next twelve months
              </p>
            }
            price={349}
            priceUpdateType="First Year"
            renewalPrice={99.0}
            handleSelectPlanCallback={
              user?.plan === "SU"
                ? () => setOpenUpgradePlanModal(true)
                : selectPlanCallback
            }
            purchaseButtonText={
              user?.plan === "CU"
                ? "CURRENT ACTIVE PLAN"
                : user?.plan === "NP" || user?.plan === "TR"
                ? "PURCHASE PLAN"
                : user?.plan === "SU"
                ? "UPGRADE PLAN"
                : "PURCHASE PLAN"
            }
            currentActivePlan={user?.plan === "CU"}
            disabled={user?.plan === "CU"}
            showInvoiceMyAssociationButton={
              user === null || user?.plan !== "CU"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Pricing;

import React from "react";
import Check from "../../assets/SVG/check";

const Checkbox = ({ extraClass, checked = false, onHandleChangeCheckbox }) => {
  //const [isChecked, setIsChecked] = useState(checked);
  //console.log("checked", checked);
  return (
    <div
      className={`${
        checked ? extraClass.checked : extraClass.unchecked
      } cursor-pointer flex flex-col items-center justify-center rounded-sm h-4 w-4`}
      onClick={() => {
        //setIsChecked(checked);
        if (onHandleChangeCheckbox) {
          onHandleChangeCheckbox();
        }
      }}
    >
      {checked && (
        <div>
          <Check
            className="h-3 w-3 sm:h-4 sm:w-4 text-white"
            strokeWidth={2.5}
          />
        </div>
      )}
    </div>
  );
};

export default Checkbox;

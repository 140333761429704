import React from "react";

const HeaderPopupTag = ({ banner }) => {
  return (
    <div className="bg-yellow-500 w-full text-center px-4 py-3 xl:py-1.5">
      <p className="text-primary-blue font-medium text-xs xl:text-base cursor-pointer">
        {banner?.bannerMessage}
        <a
          className="text-red-600 font-semibold hover:underline"
          href={banner.link}
        >
          {" "}
          {banner.linkAlias}
        </a>
      </p>
    </div>
  );
};

export default HeaderPopupTag;

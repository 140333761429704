import React from "react";

const FeaturesCard = ({ heading, description }) => {
  return (
    <div className="w-full rounded-md bg-gray-50 p-5 lg:p-10 shadow-md">
      <h3 className="text-secondary-blue text-center sm:text-left text-lg lg:text-2xl font-semibold mb-3">
        {heading ? heading : ""}
      </h3>
      <div className="text-center sm:text-left base lg:text-xl lg:leading-[2rem] font-medium text-gray-700">
        {description}
      </div>
    </div>
  );
};

export default FeaturesCard;

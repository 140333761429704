import React, { useState } from "react";
import ShoppingBag from "../../../../assets/SVG/shopping-bag";
import Button from "../../../../components/Button/Button";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import ArrowLeftCircle from "../../../../assets/SVG/arrow-left-circle";
import classes from "./modal.module.css";
import CurrencyDollar from "../../../../assets/SVG/currency-dollar";

const ConfirmPurchaseModal = ({
  planTypeId,
  planType,
  amountPayable,
  autoRenewCharges,
  setOpenModal,
  purchasePlanAPI,
  isLoading,
  disabled,
}) => {
  const [autoRenew, setAutoRenew] = useState(false);

  return (
    <div className={classes.modalWrapper}>
      <div className={classes.modal}>
        <div className=" bg-white z-20 flex flex-col items-center justify-center">
          <h2 className="text-xl lg:text-2xl font-semibold text-secondary-blue mb-5 mt-10">
            Confirm Purchase
          </h2>
          <ShoppingBag className="w-10 h-10 lg:w-14 lg:h-14 text-primary-green" />
          <div className="mt-5 flex flex-col space-y-1">
            <div className="flex items-center space-x-2">
              <p className="text-base lg:text-lg font-semibold">Plan Type:</p>
              <p className="text-primary-green text-lg lg:text-xl font-medium">
                {planType ? planType : ""}
              </p>
            </div>
            <div className="flex items-center space-x-2">
              <p className="text-base lg:text-lg font-semibold">
                Amount Payable:
              </p>
              <p className="text-primary-green text-lg lg:text-xl font-medium">
                ${amountPayable ? amountPayable : ""}
              </p>
            </div>
          </div>
          <div className="flex items-center space-x-2 my-5 lg:my-7">
            <Checkbox
              extraClass={{
                unchecked:
                  "mt-0.5 sm:mt-0 border border-secondary-blue bg-transparent",
                checked:
                  "mt-0.5 sm:mt-0 border-secondary-blue bg-secondary-blue",
              }}
              checked={autoRenew}
              onHandleChangeCheckbox={() =>
                setAutoRenew((prevState) => !prevState)
              }
            />
            <p className="text-sm lg:text-base font-medium text-secondary-blue">
              Subscribe For Auto Renewal
            </p>
          </div>
          <div className="w-[85%]">
            <p className="text-sm lg:text-base text-gray-400">
              <span className="text-secondary-blue font-medium">Note:</span> If
              you subscribe to{" "}
              <span className="text-primary-green font-medium">
                Auto Renewal
              </span>{" "}
              of{" "}
              <span className="text-primary-green font-medium">
                {planType ? planType : ""}
              </span>{" "}
              plan, you will be charged{" "}
              <span className="text-primary-green font-medium">
                ${autoRenewCharges ? autoRenewCharges : ""}
              </span>{" "}
              per year after the current plan expires. In order to{" "}
              <span className="text-primary-green font-medium">Auto Renew</span>{" "}
              we need to save your card information.
            </p>
          </div>
          <div className="flex items-center justify-evenly w-full lg:space-x-0 lg:w-[80%] my-5 lg:mt-10">
            <Button
              onClick={() => setOpenModal(false)}
              Icon={ArrowLeftCircle}
              innerText="Go Back"
              innerTextStyle="text-sm lg:text-lg font-medium text-white"
              extraClass="border-2 border-red-600 bg-red-600 rounded-md"
            />
            <Button
              Icon={CurrencyDollar}
              innerText="Proceed"
              innerTextStyle="text-sm lg:text-lg font-medium text-white"
              extraClass="border-2 border-secondary-blue bg-secondary-blue rounded-md"
              isLoading={isLoading}
              disabled={isLoading}
              onClick={() => purchasePlanAPI(planTypeId, autoRenew)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPurchaseModal;

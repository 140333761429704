import { Formik } from "formik";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import InvoiceBackground from "../../assets/images/invoice.jpg";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import { useAuth } from "../../CustomHook/auth-hook";
import axios from "../../util/helpers/axios";
import cogoToast from "cogo-toast";
import PhoneNumberInput from "../../components/PhoneNumberInput/PhoneNumberInput";

const InvoiceMyAssociation = () => {
  const [showIndividualError, setShowIndividualError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // const [error, setError] = useState({
  //   type: "",
  //   isError: false,
  //   message: "",
  // });
  // const [invoiceData, setInvoiceData] = useState({
  //   associationLegalName: "",
  //   name: "",
  //   email: "",
  //   secondPersonName: "",
  //   secondPersonEmail: "",
  //   streetAddress: "",
  //   streetAddress2: "",
  //   city: "",
  //   state: "",
  //   zipCode: "",
  // });

  const { token } = useAuth();
  const location = useLocation();
  const planTypeId = location?.state?.planTypeId;
  const planType = location?.state?.planType;

  let errorLength;
  function checkErrors() {
    if (errorLength > 0) {
      setShowIndividualError(true);
    }
    if (phoneNumber === "") {
      setShowIndividualError(true);
      setErrorMessage("Phone number is required");
      return;
    }
    if (phoneNumber.length < 7) {
      setShowIndividualError(true);
      setErrorMessage("Phone number must have at least 7 characters");
      return;
    }
    if (phoneNumber.length > 15) {
      setShowIndividualError(true);
      setErrorMessage("Phone number must have maximum of 15 characters");
      return;
    }
  }

  const invoiceMyAssociationAPI = async (
    name,
    associationName,
    email,
    secondPersonName,
    secondPersonEmail,
    streetAddress,
    streetAddress2,
    city,
    state,
    zipCode,
    phoneNumber
  ) => {
    const formData = new FormData();
    if (!token) {
      cogoToast.error(
        "You are not logged in. Please login into your registered account.",
        { hideAfter: 10 }
      );
      return;
    }

    formData.append("plan", planTypeId);
    formData.append("hoarTemplatetoken", token);
    formData.append("name", name);
    formData.append("association_name", associationName);
    formData.append("email", email);
    formData.append("second_contact_name", secondPersonName);
    formData.append("second_contact_email", secondPersonEmail);
    formData.append("street_address", streetAddress);
    formData.append("street_address2", streetAddress2);
    formData.append("city", city);
    formData.append("state", state);
    formData.append("zip_code", zipCode);
    formData.append("phone", `+${phoneNumber}`);
    try {
      setIsLoading(true);
      const response = await axios.post("/payment/offline_payment/", formData, {
        responseType: "blob",
      });
      if (response?.statusText === "OK") {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(response?.data);
        link.download = "invoice.docx";
        link.click();
        cogoToast.success(
          response?.data?.message
            ? response.data.message
            : "Your invoice has been successfully created.",
          { hideAfter: 10 }
        );
        setIsLoading(false);
        setPhoneNumber("");
      }
      console.log(response, "invoice-my-association-response");
    } catch (err) {
      setIsLoading(true);
      console.log(err.response);
      cogoToast.error(
        err?.response?.data?.message
          ? err.response.data.message
          : "Something went wrong. Please try again. Make sure you haven't created a request before.",
        { hideAfter: 10 }
      );
      setIsLoading(false);
      setPhoneNumber("");
    }
  };
  return (
    <div className="max-w-screen overflow-hidden py-5">
      <div className="w-full flex flex-col lg:flex-row">
        <div className="order-2 lg:order-none rounded-xl hidden lg:inline-flex flex-col items-center w-full xl:w-1/2">
          <img src={InvoiceBackground} alt="" className="xl:w-[80%]" />
        </div>

        <div className="relative w-full xl:w-1/2">
          <div className="flex flex-col items-center justify-center">
            <h1 className="text-2xl xl:text-4xl font-extrabold text-primary-green xl:leading-[62px]">
              Invoice Details
            </h1>
            <div className="w-full px-5 text-xs mt-2 xl:mt-0 sm:px-0 sm:text-sm xl:text-sm sm:max-w-lg text-center text-gray-600">
              <p>
                You have selected the{" "}
                <span className="text-secondary-blue font-semibold">
                  {planType ? planType : ""}
                </span>{" "}
                plan
              </p>
              <p>
                Please complete the information below and we will forward the
                invoice to the email address mentioned below. Once the payment
                is received, the account will be activated for you in order to
                begin building your documents.
              </p>
            </div>
          </div>

          <Formik
            initialValues={{
              associationLegalName: "",
              name: "",
              email: "",
              secondPersonName: "",
              secondPersonEmail: "",
              streetAddress: "",
              streetAddress2: "",
              city: "",
              state: "",
              zipCode: "",
            }}
            validationSchema={Yup.object({
              associationLegalName: Yup.string().required(
                "Association Legal Name Is Required"
              ),
              name: Yup.string().required("Full Name Is Required"),
              email: Yup.string()
                .email("Invalid email address")
                .required("Email Is Required"),
              secondPersonName: Yup.string().required(
                "Second Person Name Is Required"
              ),
              secondPersonEmail: Yup.string()
                .email("Invalid email address")
                .required("Second Person Email Is Required"),
              streetAddress: Yup.string().required(
                "Street Address Is Required"
              ),
              streetAddress2: Yup.string(),
              city: Yup.string().required("City Is Required"),
              state: Yup.string().required("State Is Required"),
              zipCode: Yup.string().required("Zip Code Is Required"),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              const {
                associationLegalName,
                email,
                name,
                secondPersonName,
                secondPersonEmail,
                streetAddress,
                streetAddress2,
                city,
                state,
                zipCode,
              } = values;
              //console.log(associationLegalName, email, name);

              invoiceMyAssociationAPI(
                name,
                associationLegalName,
                email,
                secondPersonName,
                secondPersonEmail,
                streetAddress,
                streetAddress2,
                city,
                state,
                zipCode,
                phoneNumber
              );
              resetForm({
                associationLegalName: "",
                name: "",
                email: "",
                secondPersonName: "",
                secondPersonEmail: "",
                streetAddress: "",
                streetAddress2: "",
                city: "",
                state: "",
                zipCode: "",
              });
              // setInvoiceData({
              //   associationLegalName: "",
              //   name: "",
              //   email: "",
              //   secondPersonName: "",
              //   secondPersonEmail: "",
              //   streetAddress: "",
              //   streetAddress2: "",
              //   city: "",
              //   state: "",
              //   zipCode: "",
              // });
            }}
          >
            {(formik) => {
              errorLength = Object.keys(formik.errors).length;
              return (
                <form onSubmit={formik.handleSubmit}>
                  <div className="mx-auto mt-5 relative overflow-y-scroll scrollbar w-[90%] sm:w-[80%]">
                    <div className="mb-3">
                      <p className="mb-1 text-sm sm:text-base text-primary-blue sm:leading-[27px] font-normal">
                        Association Full Legal Name{" "}
                        <span className="text-red-500 font-medium">*</span>
                      </p>
                      <Input
                        type="text"
                        name="associationLegalName"
                        id="associationLegalName"
                        placeholder="Enter your association's full legal name"
                        error={formik.errors.associationLegalName}
                        showIndividualError={showIndividualError}
                        extraClass={
                          formik.touched.associationLegalName &&
                          formik.errors.associationLegalName
                            ? "text-primary-blue  border border-red-400"
                            : "text-primary-blue  border border-ternary-blue"
                        }
                        {...formik.getFieldProps("associationLegalName")}
                      />
                    </div>
                    <div className="grid items-center grid-cols-1 sm:grid-cols-2 sm:gap-x-5">
                      <div className="col-span-2 sm:col-span-1 mb-3">
                        <p className="mb-1 text-sm xl:text-base text-primary-blue sm:leading-[27px] font-normal">
                          Full Name{" "}
                          <span className="text-red-500 font-medium">*</span>
                        </p>
                        <Input
                          type="text"
                          name="name"
                          id="name"
                          placeholder="Enter your name"
                          error={formik.errors.name}
                          showIndividualError={showIndividualError}
                          extraClass={
                            formik.touched.name && formik.errors.name
                              ? "text-primary-blue  border border-red-400"
                              : "text-primary-blue  border border-ternary-blue"
                          }
                          {...formik.getFieldProps("name")}
                        />
                      </div>

                      <div className="col-span-2 sm:col-span-1 mb-3">
                        <p className="mb-1 text-sm xl:text-base text-primary-blue leading-[27px] font-normal">
                          Email Address{" "}
                          <span className="text-red-500 font-medium">*</span>
                        </p>
                        <Input
                          type="text"
                          name="email"
                          id="email"
                          placeholder="Enter your email address"
                          autoComplete="email"
                          error={formik.errors.email}
                          value={formik.values.email}
                          showIndividualError={showIndividualError}
                          extraClass={
                            formik.touched.email && formik.errors.email
                              ? "text-primary-blue  border border-red-400"
                              : "text-primary-blue  border border-ternary-blue"
                          }
                          {...formik.getFieldProps("email")}
                        />
                      </div>

                      <div className="col-span-2 sm:col-span-1 mb-3">
                        <p className="mb-1 text-sm xl:text-base text-primary-blue sm:leading-[27px] font-normal">
                          Second Person's Full Name
                        </p>
                        <Input
                          type="text"
                          name="secondPersonName"
                          id="secondPersonName"
                          placeholder="Enter the second person's name"
                          error={formik.errors.secondPersonName}
                          showIndividualError={showIndividualError}
                          extraClass={
                            formik.touched.secondPersonName &&
                            formik.errors.secondPersonName
                              ? "text-primary-blue  border border-red-400"
                              : "text-primary-blue  border border-ternary-blue"
                          }
                          {...formik.getFieldProps("secondPersonName")}
                        />
                      </div>

                      <div className="col-span-2 sm:col-span-1 mb-3">
                        <p className="mb-1 text-sm xl:text-base text-primary-blue leading-[27px] font-normal">
                          Second Person's Email address
                        </p>
                        <Input
                          type="text"
                          name="secondPersonEmail"
                          id="secondPersonEmail"
                          placeholder="Enter second person's email address"
                          autoComplete="email"
                          error={formik.errors.secondPersonEmail}
                          value={formik.values.secondPersonEmail}
                          showIndividualError={showIndividualError}
                          extraClass={
                            formik.touched.secondPersonEmail &&
                            formik.errors.secondPersonEmail
                              ? "text-primary-blue  border border-red-400"
                              : "text-primary-blue  border border-ternary-blue"
                          }
                          {...formik.getFieldProps("secondPersonEmail")}
                        />
                      </div>

                      <div className="mb-3 col-span-2">
                        <p className="mb-1 text-sm xl:text-base text-primary-blue leading-[27px] font-normal">
                          Street Address{" "}
                          <span className="text-red-500 font-medium">*</span>
                        </p>
                        <Input
                          type="text"
                          name="streetAddress"
                          id="streetAddress"
                          placeholder="Enter the street address"
                          error={formik.errors.streetAddress}
                          value={formik.values.streetAddress}
                          showIndividualError={showIndividualError}
                          extraClass={
                            formik.touched.streetAddress &&
                            formik.errors.streetAddress
                              ? "text-primary-blue  border border-red-400"
                              : "text-primary-blue  border border-ternary-blue"
                          }
                          {...formik.getFieldProps("streetAddress")}
                        />
                      </div>

                      <div className="mb-3 col-span-2">
                        <p className="mb-1 text-sm xl:text-base text-primary-blue leading-[27px] font-normal">
                          Street Address 2 (Optional)
                        </p>
                        <Input
                          type="text"
                          name="streetAddress2"
                          id="streetAddress2"
                          placeholder="Enter the street address 2"
                          error={formik.errors.streetAddress2}
                          value={formik.values.streetAddress2}
                          showIndividualError={showIndividualError}
                          extraClass={
                            formik.touched.streetAddress2 &&
                            formik.errors.streetAddress2
                              ? "text-primary-blue  border border-red-400"
                              : "text-primary-blue  border border-ternary-blue"
                          }
                          {...formik.getFieldProps("streetAddress2")}
                        />
                      </div>

                      <div className="col-span-2 sm:col-span-1 mb-3">
                        <p className="mb-1 text-sm xl:text-base text-primary-blue leading-[27px] font-normal">
                          City{" "}
                          <span className="text-red-500 font-medium">*</span>
                        </p>
                        <Input
                          type="text"
                          name="city"
                          id="city"
                          placeholder="Enter the city"
                          error={formik.errors.city}
                          value={formik.values.city}
                          showIndividualError={showIndividualError}
                          extraClass={
                            formik.touched.city && formik.errors.city
                              ? "text-primary-blue  border border-red-400"
                              : "text-primary-blue  border border-ternary-blue"
                          }
                          {...formik.getFieldProps("city")}
                        />
                      </div>

                      <div className="col-span-2 sm:col-span-1 mb-3">
                        <p className="mb-1 text-sm xl:text-base text-primary-blue leading-[27px] font-normal">
                          State{" "}
                          <span className="text-red-500 font-medium">*</span>
                        </p>
                        <Input
                          type="text"
                          name="state"
                          id="state"
                          placeholder="Enter the state"
                          error={formik.errors.state}
                          value={formik.values.state}
                          showIndividualError={showIndividualError}
                          extraClass={
                            formik.touched.state && formik.errors.state
                              ? "text-primary-blue  border border-red-400"
                              : "text-primary-blue  border border-ternary-blue"
                          }
                          {...formik.getFieldProps("state")}
                        />
                      </div>

                      <div className="mb-3 col-span-2 sm:col-span-1">
                        <p className="mb-1 text-sm xl:text-base text-primary-blue leading-[27px] font-normal">
                          Zip Code{" "}
                          <span className="text-red-500 font-medium">*</span>
                        </p>
                        <Input
                          type="text"
                          name="zipCode"
                          id="zipCode"
                          placeholder="Enter the zip code"
                          error={formik.errors.zipCode}
                          value={formik.values.zipCode}
                          showIndividualError={showIndividualError}
                          extraClass={
                            formik.touched.zipCode && formik.errors.zipCode
                              ? "text-primary-blue  border border-red-400"
                              : "text-primary-blue  border border-ternary-blue"
                          }
                          {...formik.getFieldProps("zipCode")}
                        />
                      </div>

                      <div className="col-span-2 sm:col-span-1 mb-3">
                        <p className="mb-1 text-sm xl:text-base text-primary-blue  leading-[27px] font-normal">
                          Phone Number{" "}
                          <span className="text-red-500 font-medium">*</span>
                        </p>
                        <PhoneNumberInput
                          value={phoneNumber}
                          setValue={(value) => {
                            setPhoneNumber(value);
                            // if (value) {
                            //   resetCheckValidation("phone");
                            // }
                          }}
                        />
                        <div>
                          {showIndividualError && errorMessage ? (
                            <span className="pl-1 text-xs sm:text-sm font-normal text-red-400">
                              {errorMessage}
                            </span>
                          ) : (
                            <p></p>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* <div>
                      <div className="my-5">
                        <p className="mb-1 sm:mb-3 text-base sm:text-lg text-primary-blue leading-[27px] font-normal ">
                          Mention your query
                        </p>
                        <textarea
                          style={{ resize: "none" }}
                          placeholder="Write down your query"
                          className={`w-full placeholder:text-[10px] sm:placeholder:text-base focus:outline-none h-[70px] xl:h-[96px] ${
                            error.type === "individual" && error.isError
                              ? "text-primary-blue border border-red-400"
                              : "border border-ternary-blue"
                          } rounded-lg px-2 sm:px-3 py-1.5`}
                          value={invoiceData.message}
                          onChange={(e) => {
                            if (e.target.value) {
                              setError({
                                type: "",
                                isError: false,
                                message: "",
                              });
                              setShowIndividualError(false);
                            }
                            setInvoiceData({
                              ...invoiceData,
                              message: e.target.value,
                            });
                          }}
                        />
                        <div>
                          {showIndividualError &&
                          error.type === "individual" &&
                          error.isError ? (
                            <span className="pl-1 text-xs sm:text-sm font-normal text-red-400">
                              {error.message}
                            </span>
                          ) : (
                            <p></p>
                          )}
                        </div>
                      </div>
                    </div> */}

                    <div className="sticky bottom-0 w-full mt-3 xl:my-5">
                      <Button
                        onClick={() => checkErrors()}
                        innerText="Submit & Download Invoice"
                        type="submit"
                        extraClass={`text-center h-[45px] text-sm sm:text-base xl:text-lg text-white font-medium rounded-full bg-primary-green ${
                          isLoading && "opacity-20"
                        }`}
                        isLoading={isLoading}
                        disabled={isLoading}
                      />
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default InvoiceMyAssociation;

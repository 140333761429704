import React, { useCallback, useEffect, useState } from "react";

const CountdownTimer = ({
  hoursMinSecs,
  onClickCallback,
  countDownMessage,
}) => {
  const { hours = 0, minutes = 0, seconds = 0 } = hoursMinSecs;
  const [[hrs, mins, secs], setTime] = useState([hours, minutes, seconds]);

  const reset = useCallback(
    (hr, min, sec) => setTime([parseInt(hr), parseInt(min), parseInt(sec)]),
    []
  );

  const tick = useCallback(() => {
    if (hrs === 0 && mins === 0 && secs === 0) reset(0, 0, 0);
    else if (mins === 0 && secs === 0) {
      setTime([hrs - 1, 59, 59]);
    } else if (secs === 0) {
      setTime([hrs, mins - 1, 59]);
    } else {
      setTime([hrs, mins, secs - 1]);
    }
  }, [hrs, mins, secs, reset]);

  useEffect(() => {
    const timerId = setInterval(() => tick(), 1000);
    return () => clearInterval(timerId);
  }, [tick]);

  return (
    <div
      className={`flex items-center justify-end space-x-1 text-sm font-medium ${
        secs === 0 ? "text-blue-600" : "text-blue-400"
      }`}
    >
      <span
        onClick={() => {
          if (secs === 0) {
            onClickCallback();
          }
        }}
        className={`font-medium text-sm ${secs === 0 && " cursor-pointer"}`}
      >
        {countDownMessage}
      </span>
      {/* <p>{`${hrs.toString().padStart(2, "0")}:${mins
        .toString()
        .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`}</p> */}
      {secs !== 0 && <p>{secs.toString()} in seconds</p>}
    </div>
  );
};

export default CountdownTimer;

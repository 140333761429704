import React, { useState } from "react";
import Save from "../../assets/SVG/save";
import { useAuth } from "../../CustomHook/auth-hook";
import Button from "../Button/Button";
import classes from "./modal.module.css";

const RulesModal = ({
  defaultRule,
  ruleId,
  handleCloseEditModal,
  onUpdateCallback,
  isLoading,
  title,
  buttonText,
}) => {
  const [value, setValue] = useState();
  const { token } = useAuth();

  return (
    <div className={classes.modalWrapper}>
      <div className={classes.modal}>
        <div className="flex items-center space-x-3 bg-secondary-blue p-5 rounded-t-sm">
          <Save className="text-white w-5 h-5 sm:w-6 sm:h-6" />
          <h3 className="sm:text-xl text-white">{title}</h3>
        </div>
        <div className="mx-5 mt-5 sm:mt-10 mb-5">
          <textarea
            value={value ? value : defaultRule}
            onChange={(e) => setValue(e.target.value)}
            style={{ resize: "none" }}
            placeholder="Add your rule"
            className="scrollbar text-sm lg:text-base w-full placeholder:text-[10px] sm:placeholder:text-base focus:outline-none h-[70px] xl:h-[120px] border border-ternary-blue rounded-md px-2 sm:px-3 py-2"
          />
        </div>
        <div className="flex items-center space-x-5 mx-5 group">
          <Button
            onClick={handleCloseEditModal}
            innerText="Cancel"
            innerTextStyle="text-red-600 text-sm lg:text-lg font-medium group-hover:text-white"
            extraClass="border-2 border-red-600 group-hover:bg-red-600 rounded-md"
          />

          <Button
            onClick={() => onUpdateCallback(ruleId, token, value)}
            innerText={buttonText}
            innerTextStyle="text-sm lg:text-lg font-medium text-white"
            extraClass="border-2 border-secondary-blue bg-secondary-blue rounded-md"
            isLoading={isLoading}
            disabled={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default RulesModal;

import React, { useCallback, useEffect, useState } from "react";
import classes from "./modal.module.css";
import ChevronLeft from "../../assets/SVG/chevron-left";
import ChevronRight from "../../assets/SVG/chevron-right";
import Checkbox from "../Checkbox/Checkbox";
import Cross from "../../assets/SVG/cross";
import axios from "../../util/helpers/axios";
import { useAuth } from "../../CustomHook/auth-hook";
import Button from "../Button/Button";

const ImageSlideModal = ({
  handleCloseImageModal,
  issueId,
  image,
  setImage,
}) => {
  const [index, setIndex] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState({ loading: false, type: "" });
  const [selectedImage, setSelectedImage] = useState(null);
  const [allImages, setAllImages] = useState([]);
  const { token } = useAuth();

  const getAllImagesAPI = useCallback(async (issueId, token) => {
    setIsLoading({ type: "get-image", loading: true });
    try {
      const response = await axios.get(
        `images/?issue_id=${issueId}&hoarTemplatetoken=${token}`
      );
      if (response?.status === 200) {
        setAllImages([...response?.data?.data]);
        setIsLoading({ type: "", loading: false });
      }
    } catch (err) {
      console.log(err);
      setIsLoading({ type: "", loading: false });
    }
  }, []);

  const saveImageAPI = async () => {
    setIsLoading({ type: "save", loading: true });
    try {
      const response = await axios.post("images/", {
        issue_id: issueId,
        image_id: selectedImage,
        is_selected: selectedImage ? true : false,
        hoarTemplatetoken: token,
      });

      if (response?.status === 200) {
        console.log(response, "save image response");
        setIsLoading({ type: "", loading: false });
        handleCloseImageModal();
        setImage({
          imageId: response?.data?.id,
          imageUrl: response?.data?.image,
          imageDescription: response?.data?.title,
        });
        //window.location.reload();
      }
    } catch (err) {
      console.log(err);
      setIsLoading({ type: "", loading: false });
      handleCloseImageModal();
    }
  };

  useEffect(() => {
    if (issueId && token) {
      getAllImagesAPI(issueId, token);
    }
  }, [issueId, token, getAllImagesAPI]);

  return (
    <div className={classes.modalWrapper}>
      <div className={classes.modal}>
        {isLoading?.type !== "get-image" && (
          <div className="relative p-5 flex flex-col items-center h-full">
            {allImages && allImages.length > 0 && (
              <h2 className="text-lg text-gray-800 font-medium">
                Select the image you want to upload
              </h2>
            )}
            {(!allImages || allImages.length === 0) && (
              <p className="text-gray-500 p-5 text-center flex flex-col items-center justify-center h-full">
                No image available. Please contact the admin for more
                information :)
              </p>
            )}
            <Cross
              onClick={handleCloseImageModal}
              className="h-5 w-5 absolute right-5 top-6 hover:text-red-500 cursor-pointer"
            />
            {allImages && allImages.length > 0 && (
              <div className="w-full">
                <div className="flex items-center justify-between w-full mt-5">
                  <div
                    onClick={() => {
                      if (index < 1) {
                        setIndex(allImages.length - 1);
                      } else if (index >= 1) {
                        setIndex((prev) => prev - 1);
                      }
                      setIsChecked(false);
                    }}
                    className="bg-blue-400 rounded-full p-1 cursor-pointer"
                  >
                    <ChevronLeft className="h-5 w-5 text-white" />
                  </div>
                  <div className="bg-blue-50 w-[80%] h-full p-3 border border-blue-50 flex items-center justify-center rounded-md shadow-sm">
                    {allImages &&
                      allImages.length > 0 &&
                      allImages.map(
                        (img, idx) =>
                          idx === index && (
                            <div key={img?.image}>
                              <img
                                src={img?.image}
                                alt=""
                                className="h-[220px] w-full object-cover"
                              />
                              <div className="flex items-center justify-center space-x-3 mt-3">
                                <Checkbox
                                  extraClass={{
                                    unchecked:
                                      "border border-blue-400 bg-transparent",
                                    checked: "border-blue-400 bg-blue-400",
                                  }}
                                  checked={isChecked}
                                  onHandleChangeCheckbox={() => {
                                    if (isChecked === false) {
                                      setSelectedImage(img?.id);
                                    } else {
                                      setSelectedImage(null);
                                    }
                                    setIsChecked((prev) => !prev);
                                  }}
                                />
                                <p className="font-medium text-primary-blue">
                                  Select this image
                                </p>
                              </div>
                            </div>
                          )
                      )}
                  </div>
                  <div
                    onClick={() => {
                      if (index < allImages.length - 1) {
                        setIndex((prev) => prev + 1);
                      } else if (index >= allImages.length - 1) {
                        setIndex(0);
                      }
                      setIsChecked(false);
                    }}
                    className="bg-blue-400 rounded-full p-1 cursor-pointer"
                  >
                    <ChevronRight className="h-5 w-5 text-white" />
                  </div>
                </div>

                <Button
                  onClick={saveImageAPI}
                  innerText="Save"
                  innerTextStyle="text-sm lg:text-lg font-medium text-white"
                  extraClass="w-full border-2 border-secondary-blue bg-secondary-blue rounded-md mt-5"
                  isLoading={isLoading?.loading && isLoading?.type === "save"}
                  disabled={isLoading?.loading && isLoading?.type === "save"}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageSlideModal;

import React from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../../components/Spinner/Spinner";

const PricingCard = ({
  Icon,
  planTypeId,
  planType,
  heading,
  description,
  price,
  priceUpdateType,
  renewalPrice,
  handleSelectPlanCallback,
  purchaseButtonText,
  purchasePlanAPI,
  isLoading,
  disabled,
  currentActivePlan,
  showInvoiceMyAssociationButton,
}) => {
  const navigate = useNavigate();

  return (
    <div className="bg-white h-full w-[90%] sm:w-[450px] lg:w-[310px] xl:w-[380px] 2xl:w-[425px] flex flex-col items-center shadow-md rounded-md py-7 px-5 sm:p-10 lg:px-3 xl:px-7 2xl:px-10 border-2 border-secondary-blue cursor-pointer">
      <div className="w-full h-[20%]">
        <div className="w-full flex flex-col items-center">
          {Icon}
          <h2 className="text-secondary-blue text-2xl sm:text-3xl lg:text-xl 2xl:text-3xl font-medium mt-5">
            {planType}
          </h2>
        </div>
        <div className="w-full h-[1.5px] bg-gray-300 mt-3 sm:mt-5 lg:mt-3 2xl:mt-5 mb-5 sm:mb-10 lg:mb-2 xl:mb-10"></div>
      </div>
      <div className="w-full h-[35%] text-center">
        <h3 className="text-xl sm:text-2xl lg:text-lg xl:text-xl 2xl:text-2xl font-medium text-secondary-blue">
          {heading}
        </h3>
        {description}
      </div>
      <div className="h-[15%] w-full mb-4 py-2 text-center">
        <p className="mb-2 text-secondary-blue text-3xl sm:text-5xl lg:text-2xl xl:text-5xl font-semibold">
          ${price}{" "}
          <span className="text-lg lg:text-sm xl:text-lg xl:-ml-2">
            /{priceUpdateType}
          </span>
        </p>

        <span
          className={`${
            renewalPrice ? "visible" : "invisible"
          } font-normal text-black text-sm`}
        >
          *Renewals are just ${renewalPrice} per year
        </span>
      </div>
      <div className="h-[30%] w-full flex flex-col items-center space-y-5">
        <button
          disabled={disabled}
          onClick={() => {
            if (handleSelectPlanCallback) {
              handleSelectPlanCallback(
                planTypeId,
                planType,
                price,
                renewalPrice
              );
            }
            if (purchasePlanAPI) {
              purchasePlanAPI(planTypeId);
            }
          }}
          className={`${
            currentActivePlan
              ? "bg-primary-green border border-primary-green"
              : "bg-secondary-blue border border-secondary-blue"
          } disabled:opacity-80 flex items-center justify-center w-full rounded-full text-center py-2 text-white text-base sm:text-lg font-medium shadow-md`}
        >
          {purchaseButtonText}
          {isLoading && planTypeId === "TR" && (
            <span className="self-center ml-3">
              <Spinner />
            </span>
          )}
        </button>

        <button
          onClick={() =>
            navigate("/invoice-my-association", {
              state: { planTypeId: planTypeId, planType: planType },
            })
          }
          className={`${
            showInvoiceMyAssociationButton ? "visible" : "invisible"
          } w-full  border border-secondary-blue rounded-full text-center py-2 bg-secondary-blue text-white text-base sm:text-lg font-medium shadow-md`}
        >
          INVOICE MY ASSOCIATION
        </button>
      </div>
    </div>
  );
};

export default PricingCard;

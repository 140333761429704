import React from "react";
import Logo from "../../assets/images/logo.png";
import Facebook from "../../assets/SVG/facebook";
import Instagram from "../../assets/SVG/instagram";
import Linkedin from "../../assets/SVG/linkedin";
import Email from "../../assets/SVG/email";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="w-full bg-primary-blue px-5 py-10 lg:px-10 lg:pt-10 lg:pb-5 shadow-sm">
      <div className="grid grid-cols-2 gap-y-10 lg:gap-y-0 place-items-center lg:flex lg:flex-row lg:items-start lg:justify-between">
        <div className="col-span-2 flex flex-col space-y-3 lg:space-y-0 lg:flex-row items-center lg:space-x-7">
          <div className="text-white flex flex-col items-center lg:items-start">
            <img src={Logo} alt="HOA-RULES" className="w-64" />
            <p className="mt-5 text-center lg:text-left text-sm xl:text-base text-white md:max-w-xs">
              HOARulesTemplates.com is a new way to create, update and maintain
              a community association's governing rules and architectural
              guidelines.
            </p>
          </div>
        </div>

        <div className="flex flex-col space-y-1 text-sm xl:text-lg text-white">
          <Link to="/pricing">Pricing</Link>
          <Link to="/about-us">About Us</Link>
          <Link to="/question/get-in-touch">Contact Us</Link>
        </div>

        <div className="flex flex-col space-y-1 text-sm xl:text-lg text-white">
          <Link to="/question/get-in-touch">FAQs</Link>
          <Link to="">Terms & Conditions</Link>
          <Link to="">Privacy Policy</Link>
        </div>

        <div className="flex flex-col items-center lg:items-start col-span-2 text-sm  xl:text-lg text-white">
          <p>Follow us:</p>
          <div className="my-3 flex items-center space-x-3">
            {/* <a href="/" target="_blank" rel="noreferrer">
              <Facebook fill="#ffffff" />
            </a>
            <a href="/" target="_blank" rel="noreferrer">
              <Instagram />
            </a>
            <a href="/" target="_blank" rel="noreferrer">
              <Linkedin />
            </a> */}
            <Link to="">
              <Facebook fill="#ffffff" />
            </Link>
            <Link to="">
              <Instagram />
            </Link>
            <Link to="">
              <Linkedin />
            </Link>
          </div>
          <div className="flex items-center space-x-3">
            <p>Write us at:</p>
            <a href="mailto:info@hoarulestemplate.com">
              <Email />
            </a>
          </div>
        </div>
      </div>
      <div className="my-10 text-center text-sm text-white font-normal ">
        <p>2021 All Rights Reserved</p>
        <div className="flex items-center justify-center space-x-2">
          <span>Terms Of Use</span>
          <span>|</span>
          <span>Privacy Policy</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import React, { useState } from "react";
import Dropdown from "../Dropdown/Dropdown";
import PhoneNumberInput from "../PhoneNumberInput/PhoneNumberInput";
import Input from "../Input/Input";
import classes from "./modal.module.css";
import Button from "../Button/Button";
import Cross from "../../assets/SVG/cross";
import { useAuth } from "../../CustomHook/auth-hook";
import axios from "../../util/helpers/axios";
import cogoToast from "cogo-toast";

const UpdateUserInfoModal = ({ userDetails, setOpen }) => {
  const [name, setName] = useState(userDetails?.name ? userDetails?.name : "");
  const [phoneNumber, setPhoneNumber] = useState(
    userDetails?.contact_no ? userDetails?.contact_no : ""
  );
  const [associationName, setAssociationName] = useState(
    userDetails?.association_name ? userDetails?.association_name : ""
  );
  const [state, setState] = useState(
    userDetails?.state ? userDetails?.state : ""
  );
  const [home, setHome] = useState(
    userDetails?.family ? userDetails?.family : ""
  );
  const [isLoading, setIsLoading] = useState(false);
  const [checkValidation, setCheckValidation] = useState([]); //used to handle the validation of phone number, state and home
  const { token } = useAuth();
  const resetCheckValidation = (type) => {
    let resetValidation = checkValidation.filter((value) => value !== type);
    setCheckValidation([...resetValidation]);
  };

  const updateUserDetailsAPI = async () => {
    if (!token) {
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("association_name", associationName);
    formData.append("state", state);
    formData.append("contact_no", phoneNumber);

    try {
      const response = await axios.post(`userDetails/${token}`, formData);
      if (response?.data?.status === 1) {
        console.log(response, "update user details api response");
        window.location.reload();
        setOpen(false);
      } else {
        cogoToast.error(
          response?.data?.message
            ? response.data.message
            : "Something went wrong. Please try again. Make sure you haven't created a request before.",
          { hideAfter: 10 }
        );
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      cogoToast.error(
        err?.response?.data?.message
          ? err.response.data.message
          : "Something went wrong. Please try again. Make sure you haven't created a request before.",
        { hideAfter: 10 }
      );
      setIsLoading(false);
    }
  };

  return (
    <div className={classes.modalWrapper}>
      <div className={classes.modal}>
        <div className="px-10 py-5 relative">
          <h1 className="text-3xl text-center font-semibold text-secondary-blue">
            Update Account Details
          </h1>
          <Cross
            onClick={() => setOpen(false)}
            className="absolute right-5 top-6 h-7 w-7 text-secondary-blue cursor-pointer"
          />
          <div className="mt-10 flex flex-col space-y-5">
            <div className="col-span-2 sm:col-span-1">
              <p className="mb-1 sm:mb-3 text-sm sm:text-base text-primary-blue sm:leading-[27px] font-normal">
                Full Name
              </p>
              <Input
                type="text"
                name="name"
                id="name"
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="grid grid-cols-2 gap-x-10 gap-y-5">
              <div>
                <p className="mb-1 sm:mb-3 text-base sm:text-base text-primary-blue  leading-[27px] font-normal">
                  Phone Number
                </p>
                <PhoneNumberInput
                  value={phoneNumber}
                  setValue={(value) => {
                    setPhoneNumber(value);
                    if (value) {
                      resetCheckValidation("phone");
                    }
                  }}
                />
                {checkValidation.includes("phone") && (
                  <span className="pl-1 text-xs sm:text-sm font-normal text-red-400">
                    Phone Number is Required
                  </span>
                )}
              </div>
              <div className="col-span-2 sm:col-span-1">
                <p className="mb-1 sm:mb-3 text-sm sm:text-base text-primary-blue sm:leading-[27px] font-normal">
                  Association Full Name
                </p>
                <Input
                  type="text"
                  name="associationName"
                  id="associationName"
                  placeholder="Enter your association name"
                  value={associationName}
                  onChange={(e) => setAssociationName(e.target.value)}
                />
              </div>
              <div>
                <p className="mb-1 sm:mb-3 text-base sm:text-base text-primary-blue leading-[27px] font-normal">
                  State
                </p>
                <Dropdown
                  dropdownList={[
                    { title: "All", id: "All" },
                    { title: "Florida", id: "Florida" },
                    { title: "Georgia", id: "Georgia" },
                    { title: "Texas", id: "Texas" },
                  ]}
                  placeholder="State"
                  dropdownCallback={(value) => {
                    setState(value);
                    if (value) {
                      resetCheckValidation("state");
                    }
                  }}
                  defaultValue={state}
                />
                {checkValidation.includes("state") && (
                  <span className="pl-1 text-xs sm:text-sm font-normal text-red-400">
                    State is Required
                  </span>
                )}
              </div>

              <div>
                <p className="mb-1 sm:mb-3 text-base sm:text-base text-primary-blue  leading-[27px] font-normal">
                  Home
                </p>
                <Dropdown
                  dropdownList={[
                    { title: "Single Family", id: "SF" },
                    { title: "Town House", id: "TH" },
                    { title: "Both", id: "B" },
                  ]}
                  placeholder="Home"
                  dropdownCallback={(value) => {
                    setHome(value);
                    if (value) {
                      resetCheckValidation("home");
                    }
                  }}
                  defaultValue={
                    home === "SF"
                      ? "Single Family"
                      : home === "TH"
                      ? "Town House"
                      : "Both"
                  }
                />
                {checkValidation.includes("home") && (
                  <span className="pl-1 text-xs sm:text-sm font-normal text-red-400">
                    Home is Required
                  </span>
                )}
              </div>
            </div>
          </div>
          <Button
            isLoading={isLoading}
            disabled={isLoading}
            onClick={updateUserDetailsAPI}
            innerText="Update Details"
            type="submit"
            extraClass="w-[80%] mx-auto mt-12 text-center h-[45px] text-sm sm:text-base xl:text-lg text-white font-medium rounded-full bg-primary-green"
          />
        </div>
      </div>
    </div>
  );
};

export default UpdateUserInfoModal;

import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./phone-input.css";

const PhoneNumberInput = ({ value, setValue }) => {
  return (
    <PhoneInput
      placeholder="(000) 000-0000"
      country={"us"}
      value={value}
      onChange={(value) => setValue(value)}
      countryCodeEditable={false}
      onlyCountries={["us"]}
      disableDropdown={true}
    />
  );
};

export default PhoneNumberInput;

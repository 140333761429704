import React from "react";

const DocumentCard = ({
  index,
  description,
  isButton,
  buttonText,
  handleButtonClick,
}) => {
  return (
    <div className="cursor-pointer bg-white rounded-md p-7 w-[260px] h-[300px] sm:w-[230px] sm:h-[270px] lg:w-[260px] lg:h-[300px] flex flex-col items-center space-y-5 transform transition duration-500 hover:scale-110">
      <span className="text-white bg-secondary-blue lg:text-lg font-medium rounded-full px-4 py-1.5">
        {index}
      </span>
      <p className="text-gray-600 text-center text-base sm:text-sm lg:text-base">
        {description}
      </p>
      {isButton && (
        <button
          onClick={handleButtonClick}
          className="text-sm font-medium bg-secondary-blue  text-white rounded-full px-5 py-2"
        >
          {buttonText}
        </button>
      )}
    </div>
  );
};

export default DocumentCard;

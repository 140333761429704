import React from "react";

const DropdownArrow = ({ className, fill, onClick, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : "h-5 w-5"}
      fill="none"
      viewBox="0 0 19 12"
      onClick={onClick}
      {...rest}
    >
      <path
        fill={fill ? fill : "#3A1BA3"}
        d="M.12.208a.363.363 0 00.027.4l8.566 11.225c.08.104.211.167.352.167s.273-.063.353-.167L17.998.608c.09-.12.1-.275.023-.403A.44.44 0 0017.637 0H.501C.34 0 .195.08.12.208z"
      ></path>
    </svg>
  );
};

export default DropdownArrow;
